
import React from 'react';
import { withRouter } from 'react-router';
import { useObserver } from "mobx-react";

import { storeContext } from '../context';

import CustomTable from './CustomTable';

const Status = (props) => {
  const store = React.useContext(storeContext);

  return useObserver(() => (
    <div>
      <CustomTable
        size="small"
        style={{ minHeight: 500, overflow: 'scroll' }}
        onRow={(record) => {
          return {
            onClick: () => {
              if(store.url.split('/')[1] === 'as_device_mapping'){
                store.currentItem = record;
                store.currentItemBkp = record;
                props.history.push(`${store.url}`);  
              }else if(store.url.split('/')[1] === 'equipment') {
                props.history.push(`${store.url}/${record.deploy_id}/${record.client_sn}`);
              } else {
                props.history.push(`${store.url}/${record.id}`);
              }
            },
          }}}
        filterDefault={store.filterDefault}
        columns={store.columns}
        dataSource={store.data}
        loading={store.listLoading}
        path={store.url}
        filter={store.searchValue}
        onSearch={store.onSearch}
        customFilters={store.customFilters}
        exportUrl={store.exportUrl}
        sorter={store.sorter}
      />
    </div>
  ));
}

export default withRouter(Status);
