import PT_BR from './json/pt_BR.json';
import EN from './json/en.json';

function format(fmt, args) {
    return fmt.split("%%").reduce((aggregate, chunk, i) => aggregate + chunk + (args[i] || ""), "");
}

function getValue(json, param) {
    const array = param.split('.');
    let value = json;
    array.forEach((e) => {
        value = value[e];
    });
    return value || param;
}

export default function translate(param = '') {
    try {
        const currLang = window.navigator.language || 'en';
        if (['pt-br', 'pt'].includes(currLang.toLowerCase())) {
            return getValue(PT_BR, param);
        } else {
            return getValue(EN, param);
        }
    } catch (err) {
        return param;
    }
}

export function translateFormat(param, ...args) {
    const value =  translate(param);
    return format(value, args);
}