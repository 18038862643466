
import React from 'react';
import { useObserver } from "mobx-react";
import { storeContext } from '../../context';

import {
  Form,
  Input,
  Row,
  Col, 
  Icon, 
  Tooltip
} from 'antd';

import api from '../../helpers/api';
import RegistryForm from '../RegistryForm';
import configuration from '../../config';
import CustomFilesUpload from '../CustomFilesUpload';
import translate from '../../i18n/translate';
import { copyToClipboard } from '../../helpers/utils';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
];

const labelCol = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 10,
};

let ContentForm = () => {
  const store = React.useContext(storeContext);
  setImage();
  
  const handleChange = (field, event) => {
    store.currentItem[field] = event.target.value
  }

  function setImage(){
    store.imagePath = `https://${configuration.s3_buckets.IMAG_UPLOAD}.s3.amazonaws.com/options/${store.currentItem.id}_client.jpg?${new Date().getTime()}`;
  }

  function saveLogo({ fileUrl = '' }) {
    console.log('fileUrl', fileUrl);
    api.put(`client/${store.currentItem.id}`, { logo: fileUrl }).finally(() => {
      if (fileUrl) {
        store.currentItem.logo = fileUrl;
        store.imagePath = fileUrl;
      }
    });
  }

  return useObserver(() => (
    <Form layout="vertical">
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="Id">
            <Input value={store.currentItem.id} disabled={true} />
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label="Name">
            <Input value={store.currentItem.name} onChange={handleChange.bind(this, 'name')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="Logo">
            <CustomFilesUpload
              uploadUrl={`/client/${store.currentItem.id}/photo`}
              imagePath={store.imagePath}
              disabled={!store.currentItem.id}
              func={saveLogo}
            />
          </Form.Item>
        </Col>
        <Col  {...labelCol}>
          <Form.Item label='Logo URL'>
              <Input value={store.currentItem.logo} disabled addonAfter={
                  <Tooltip placement="topLeft" title={translate('default.copy')}>
                      <Icon type="copy" onClick={() => copyToClipboard(store.currentItem.logo)} />
                  </Tooltip>
              } />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  ));
}

const ClientList = () => {
  const store = React.useContext(storeContext);

  ContentForm = Form.create({})(ContentForm);

  store.editForm = ContentForm;
  store.columns = columns;
  store.url = '/client';

  return(
    <div>
      <RegistryForm />

    </div>
    )
  }

export default ClientList;
