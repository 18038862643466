/* eslint-disable no-throw-literal */
import api from '../helpers/api';

export async function deactivation(item) {
    let result = {};
    const body = { sensor_number: item.sensor_number, reason: item.reason };
    try {
        result = await api.post(`/device_activation/deactivate`, body);
        console.log(result);
    } catch (error) {
        throw error;
    }
}
