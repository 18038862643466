import React, { useState, useEffect } from 'react';
import { storeContext } from '../../context';
import api from '../../helpers/api';

import CustomFilesUpload from '../CustomFilesUpload';
import { Layout, Button, Form, Alert, Row, Col } from 'antd';
import Confirm from '../Confirm/Confirm';
import { formatDate } from '../../helpers/date';
import translate from '../../i18n/translate';
const { Content } = Layout;
const labelCol = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 12,
};
export default function ProductPhotos() {
    const store = React.useContext(storeContext);
    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingGenerate, setLoadingGenerate] = useState(false);
    const [alertMsg, setAlertMsg] = useState();
    const [permission, setPermission] = useState({});

    function save({ fileUrl = '' }) {
        const list = fileUrl.split('/');
        const newFileName = `${list[list.length - 1]}`;
        api.post('product/photo',
            { product_id: store.currentItem.id, file_url: fileUrl, file_name: newFileName })
            .then(() => setLoading(true));
    }

    function renderAlert() {
        setTimeout(() => setAlertMsg(undefined), 3000);
        return <Alert closable message={alertMsg.message} type={alertMsg.type} />
    }

    function alertMessage() {
        return alertMsg && renderAlert();
    }

    async function generateAlert() {
        setLoadingGenerate(true);
        try {
            const model = {
                title: 'Imagem com erro',
                content: 'Imagem com erro',
                type: 'Alert',
                problem_id: 4,
            };
            await api.post(`/alert/${store.currentItem.id}`, model);
        } catch (error) {
            let message = 'Error when generating an alert';
            setAlertMsg({ type: 'error', message });
        }
        setLoadingGenerate(false);
    }

    useEffect(() => {
        const showButtonUpload = store.permissions.some(p => p.type === 'c' && p.permission === 'product_photo');
        const showRemoveIcon = store.permissions.some(p => p.type === 'd' && p.permission === 'product_photo');
        setPermission({ showButtonUpload, showRemoveIcon });
        api.get(`/product/${store.currentItem.id}/photos`).then(data => {
            const values = data.data.result.map(item => ({
                ...item,
                uid: item.id,
                url: item.path,
                thumbUrl: item.path,
                name: item.file_name,
            }));
            setFileList(values);
        }).finally(() => setLoading(false));
    }, [loading, store.currentItem.id, store.permissions]);

    return (
        <>
            <Content className="padding-20">
                {alertMessage()}
                <Row gutter={16}>
                    <Col {...labelCol} >
                        <Form.Item>
                            <Button htmlType="button" type="primary" icon="alert"
                                loading={loadingGenerate}
                                onClick={() => Confirm({ func: generateAlert, content: translate('alert.generateAlert') })}>{translate('alert.generate')}</Button>
                        </Form.Item>
                    </Col>
                    {store.currentItem.installation_date &&
                        <Col {...labelCol}>
                            <Form.Item>
                                <strong>Installation Date:{formatDate(store.currentItem.installation_date)}</strong>
                            </Form.Item>
                        </Col>
                    }
                </Row>
                <CustomFilesUpload fileList={fileList} loading={loading} isTypeCard
                    deleteUrl="product/photo" uploadUrl={`/product/photo/${store.currentItem.id}`} func={save}
                    showRemoveIcon={permission.showRemoveIcon} showButtonUpload={permission.showButtonUpload} />
            </Content>
        </>
    )
}