import React from 'react'
import { Alert } from 'antd';
import translate from '../i18n/translate';
export default function AlertNotAllow() {
    return (
        <Alert
            message={translate('default.warning')}
            description={translate('information.notAllow')}
            type="warning"
        />
    )
}
