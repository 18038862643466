import React, { useState } from 'react';
import CustomAutoComplete from '../CustomAutoComplete';
import { Button, Row, Col, Form, Upload, Icon, notification } from 'antd';
import api from '../../helpers/api';
import translate from '../../i18n/translate';

const labelCol = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 10,
};
const ACCEPT = 'text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

function EquipmentImport() {
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({});

    async function onSubmit(event) {
        setLoading(true);
        event.preventDefault();
        try {
            console.log(state.selectedFile);
            const headers = { 'Content-Type': ACCEPT };
            await api.post(`equipment/import/${state.deploy_id}?equipment_type=${state.equipment_type_id}`, state.selectedFile, { headers });
            notification.success({ message: 'Uploaded CSV Successfully' });
            setState({});
        } catch (error) {
            let message = 'CSV upload error';
            if (error.response && error.response.data && error.response.data.message) {
                message = error.response.data.message
            }
            notification.error({ message });
        } finally {
            setLoading(false);
        }
    }

    function onChange(info) {
        if (info.file && info.file.originFileObj) {
            setState(old => ({
                ...old,
                selectedFile: info.file.originFileObj,
                selectedFileList: [info.file]
            }));
        }
    }

    function isDisabled() {
        return !state || !state.deploy_id || !state.selectedFile;
    }

    return (
        <>
            <div style={{ background: '#ECECEC', padding: '30px', marginTop: 3 }}>
                <Form layout="vertical" onSubmit={onSubmit}>
                    <Row gutter={24}>
                        <Col {...labelCol}>
                          <Form.Item label="Deploy">
                            <CustomAutoComplete
                              searchValue={'id'}
                              searchKey={'name'}
                              path={'/deploy'}
                              onSelect={(value) => setState(old => ({ ...old, deploy_id: value }))}
                              value={state.deploy_id} />
                          </Form.Item>
                        </Col>
                        <Col {...labelCol}>
                            <Form.Item label="Tipo Equipamento">
                              <CustomAutoComplete
                                searchValue={'id'}
                                searchKey={'name'}
                                path={'/equipment_type'}
                                onSelect={(value) => setState(old => ({ ...old, equipment_type_id: value }))}
                                value={state.equipment_type_id} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col {...labelCol}>
                          <Form.Item label={translate('file.name')}>
                            <Upload
                              accept={ACCEPT}
                              onChange={onChange}
                              customRequest={({ onSuccess }) => setTimeout(() => onSuccess("ok"), 0)}
                              fileList={state.selectedFileList}>
                              <Button><Icon type="upload" />{translate('file.select.upload')}</Button>
                            </Upload>
                          </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col {...labelCol}>
                            <Button
                                disabled={isDisabled()}
                                htmlType="submit"
                                loading={loading}>
                                    {translate('file.start')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    )
}

export default EquipmentImport;