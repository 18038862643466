
import React, { useEffect } from 'react';
import { useObserver } from "mobx-react";
import { storeContext } from '../../context';

import {
  Form,
  Input,
  Row,
  Col
} from 'antd';

import RegistryForm from '../RegistryForm';
import CustomTable from '../CustomTable';
import ActiveOnlyFilter from '../ActiveOnlyFilter';
import FirmwareTypeTags from './FirmwareTypeTags';


const firmwareVersionColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Firmware Type',
    dataIndex: 'firmware_type_id',
    key: 'firmware_type_id',
  },
  {
    title: 'Version Number',
    dataIndex: 'version_number',
    key: 'version_number',
  },
];

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
];

const labelCol = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 10,
};

let ContentForm = () => {
  const store = React.useContext(storeContext);
  const handleChange = (field, event) => {
    store.currentItem[field] = event.target.value
  }

  return useObserver(() => (
    <div>
      <Form layout="vertical">
        <Row gutter={16}>
          <Col {...labelCol}>
            <Form.Item label="Id">
              <Input value={store.currentItem.id} disabled={true} />
            </Form.Item>
          </Col>
          <Col {...labelCol}>
            <Form.Item label="Name">
              <Input value={store.currentItem.name} onChange={handleChange.bind(this, 'name')} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col {...labelCol} >
            <Form.Item label="Description">
              <Input value={store.currentItem.description} onChange={handleChange.bind(this, 'description')} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <h3>
        Firmware Versions
      </h3>
      <CustomTable
        size="small"
        columns={firmwareVersionColumns}
        path={`/firmware_type/${store.currentItem.id}/version`}
      />
    </div>
  ));
}

const FirmwareTypeList = () => {
  const store = React.useContext(storeContext);

  ContentForm = Form.create({})(ContentForm);

  store.editForm = undefined;
  store.columns = columns;
  store.filterDefault = { active: 1 };
  store.url = '/firmware_type';

  ContentForm.tab = 'Info';
  FirmwareTypeTags.tab = "Tags";

  store.tabs = [ContentForm, FirmwareTypeTags];

  useEffect(() => {
    store.customFilters = ActiveOnlyFilter;
    return () => {
      store.filterDefault = {};
      store.customFilters = null;
    }
  }, [store.customFilters, store.filterDefault]);

  return (
    <div>
      <RegistryForm />

    </div>
  )
}

export default FirmwareTypeList;
