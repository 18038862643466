
import React, { useEffect, useState } from 'react';
import { useObserver } from "mobx-react";
import { storeContext } from '../../context';
import { formatDate } from '../../helpers/date';
import ProductPhotos from './ProductPhotos';
import {
  Form,
  Input,
  Row,
  Col,
  Icon,
  Select,
  Checkbox
} from 'antd';
import RegistryForm from '../RegistryForm';
import api from '../../helpers/api';
import ProductInstallation from './ProductInstallation';
import AlertList from './alert/Alert';
import CustomAutoComplete from '../CustomAutoComplete';
import translate from '../../i18n/translate';

const { Option } = Select;
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
  },
  {
    title: 'Serial',
    dataIndex: 'serial',
    key: 'serial',
    sorter: true,
  },
  {
    title: translate('product.clientSN'),
    dataIndex: 'client_sn',
    key: 'client_sn',
    sorter: true,
  },
  {
    title: translate('alert.qty'),
    dataIndex: 'alert_qty',
    key: 'alert_qty',
    render: (alert_qty) => alert_qty || 0,
  },
];

const labelCol = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 10,
};

const labelColFilter = {
  xs: 24,
  sm: 24,
  md: 4,
  lg: 4,
  xl: 4,
};

function ProductFilters(props) {
  const store = React.useContext(storeContext);

  function onChange(checkedValues) {
    const product_state = checkedValues.join(',');
    store.filterProductClientSn = { ...store.filterProductClientSn, product_state };
    props.onSearchValue(store.filterProductClientSn);
  }

  function setFilterAndListAll(params) {
    store.filterProductClientSn = { ...store.filterProductClientSn, ...params };
    props.onSearchValue(store.filterProductClientSn);
  }

  return (
    <>
      <Row gutter={8}>
        <Col span={3}>
          <Form.Item >
            <Checkbox.Group onChange={onChange} value={store.filterProductClientSn.product_state}>
              <Checkbox value="missing_info">{translate('product.missingInfo')}</Checkbox>
            </Checkbox.Group>
          </Form.Item>
        </Col>
        <Row gutter={16}>
          <Col  {...labelColFilter} >
            <Form.Item>
              <CustomAutoComplete
                searchValue={'id'}
                searchKey={'name'}
                path={'/alert/problem'}
                placeholder={translate('alert.select.problem')}
                onSelect={(problem) => {
                  setFilterAndListAll({ problem });
                }}
                value={store.filterProductClientSn.problem}
              />
            </Form.Item>
          </Col>
          <Col  {...labelColFilter} >
            <Form.Item>
              <CustomAutoComplete
                searchValue='id'
                searchKey={'name'}
                path={'/account/alert'}
                placeholder={translate('alert.select.owner')}
                onSelect={(owner_user) => {
                  setFilterAndListAll({ owner_user });
                }}
                value={store.filterProductClientSn.owner_user}
              />
            </Form.Item>
          </Col>
          <Col {...labelColFilter}>
            <Form.Item>
              <CustomAutoComplete
                searchValue={'type'}
                searchKey={'type'}
                path={'/alert/type'}
                placeholder={translate('default.select.type')}
                onSelect={(type) => {
                  setFilterAndListAll({ type });
                }}
                value={store.filterProductClientSn.type}
              />
            </Form.Item>
          </Col>
          <Col {...labelColFilter} >
            <Form.Item>
              <Select
                value={store.filterProductClientSn.status} style={{ width: '100%' }}
                onChange={(status) => setFilterAndListAll({ status })} >
                <Option key={-1} value={null}>{translate('default.select.status')}</Option>
                <Option value="open">{translate('alert.open')}</Option>
                <Option value="in progress">{translate('alert.inProgress')}</Option>
                <Option value="suspended">{translate('alert.suspended')}</Option>
                <Option value="closed">{translate('alert.closed')}</Option>
                <Option value="closed-no action">{translate('alert.closedNoAction')}</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Row>
    </>
  )
}

let ContentForm = () => {
  const [loading, setLoading] = useState(false);
  const store = React.useContext(storeContext);
  const handleInputChange = (field, event) => {
    store.currentItem[field] = event.target.value
  }

  function handleSelecttChange(field, value) {
    store.currentItem[field] = value
  }

  useEffect(() => {
    setLoading(true);
    api.get(`/product/param_server/${store.currentItem.id}`)
      .then(data => {
        const result = data.data.result;
        if (result) {
          store.currentItem.firstcounter = result.firstcounter || '';
          store.currentItem.countermultiply = result.countermultiply || '';
          store.currentItemBkp.countermultiply = result.countermultiply || '';
          store.currentItemBkp.firstcounter = result.firstcounter || '';
        }
      }).finally(() => setLoading(false));

  }, [store.currentItem, store.currentItemBkp]);


  return useObserver(() => (
    <Form layout="vertical">
      <Row gutter={16}>
        <Col {...labelCol} >
          <Form.Item label="Id">
            <Input value={store.currentItem.id} disabled={true} />
          </Form.Item>
        </Col>
        <Col {...labelCol} >
          <Form.Item label={translate("product.installationDate")}>
            <Input value={formatDate(store.currentItem.installation_date)} disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol} >
          <Form.Item label={translate("product.firstCounter")}>
            <Input value={store.currentItem.firstcounter} onChange={handleInputChange.bind(this, 'firstcounter')}
              suffix={loading && <Icon type="loading" />} />
          </Form.Item>
        </Col>
        <Col {...labelCol} >
          <Form.Item label={translate("product.counterMultiply")}>
            <Select onChange={handleSelecttChange.bind(this, 'countermultiply')}
              value={store.currentItem.countermultiply} loading={loading}>
              <Option value="0.01">0.01</Option>
              <Option value="0.1">0.1</Option>
              <Option value="1">1</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol} >
          <Form.Item label={translate("product.clientSN")}>
            <Input value={store.currentItem.client_sn} onChange={handleInputChange.bind(this, 'client_sn')} />
          </Form.Item>
        </Col>
        <Col {...labelCol} >
          <Form.Item label="Serial">
            <Input value={store.currentItem.serial} onChange={handleInputChange.bind(this, 'serial')} disabled />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  ));
}

const ProductClientSnList = () => {
  const store = React.useContext(storeContext);

  ContentForm = Form.create({})(ContentForm);

  store.hideNewButton = true;
  store.hideDeleteButton = true;
  store.editForm = undefined;;
  ContentForm.tab = 'Info';

  ProductPhotos.tab = 'default.photos';
  ProductPhotos.disabled = true;

  ProductInstallation.tab = 'product.installation';
  ProductInstallation.disabled = true;
  AlertList.tab = 'alert.name';
  AlertList.disabled = true;

  store.tabs = [
    ContentForm,
    ProductPhotos,
    ProductInstallation,
    AlertList
  ];

  store.columns = columns;
  store.url = '/product/client_sn';
  store.exportUrl = '/product/client_sn/csv/export';
  store.filterDefault = store.filterProductClientSn;

  useEffect(() => {
    store.customFilters = ProductFilters;
    return () => {
      ProductPhotos.params = null;
      store.hideNewButton = false;
      store.hideDeleteButton = false;
      store.customFilters = null;
      store.filterDefault = {};
    }
  }, [
    store.customFilters,
    store.hideNewButton,
    store.hideDeleteButton,
    store.filterDefault
  ]);

  return (
    <div>
      <RegistryForm />
    </div>
  )
}

export default ProductClientSnList;
