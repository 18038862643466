import React, { useEffect, useState } from 'react';
import { Button, Col, Input, Row, Table, notification} from 'antd';
import api from '../../helpers/api';
import { formatDate } from "../../helpers/date";
import translate from '../../i18n/translate';

const columns = [
  {
    title: 'SN',
    dataIndex: 'serial',
    key: 'serial',
  },
  {
    title: translate('quickTest.dateTx1'),
    dataIndex: 'first_tx_date',
    key: 'first_tx_date',
    render: (first_tx_date) => formatDate(first_tx_date ? first_tx_date : null),
  },
  {
    title: translate('quickTest.counterTx1'),
    dataIndex: 'ddf_cc',
    key: 'ddf_cc',
  },
  {
    title: translate('quickTest.dateTx2'),
    dataIndex: 'last_tx_date',
    key: 'last_tx_date',
    render: (last_tx_date) => formatDate(last_tx_date ? last_tx_date : null),
  },
  {
    title: translate('quickTest.counterTx2'),
    dataIndex: 'ddl_cc',
    key: 'ddl_cc',
  },
  {
    title: translate('quickTest.diff'),
    key: 'diff',
    render: (text, record) => {
      if (record.ddl_cc && record.ddf_cc) {
        return (
          <span>
            {record.ddl_cc - record.ddf_cc}
          </span>
        )
      }else {
        return <span></span>
      }
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
];

function QuickTest() {
  const [inputData, setInputData] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  async function fetch() {
    setLoading(true);
    try {
      const response = await api.get('quick_test');
      const result = response.data.result;
      console.log(response);
      setData(result);
    } catch (error) {
      let message = 'Ocorreu um erro.';
      if (error.response && error.response.data && error.response.data.message) {
          message = error.response.data.message
      }
      notification.error({ message });
    }
    setLoading(false);
  }
  
  useEffect(() => {
    fetch();
  }, []);

  async function onEnter() {
    if(data.length >= 10) {
      const message = 'Já existem 10 devices sendo testados.'
      notification.error({ message });
      return;
    }
    setLoading(true);
    try {
      await api.post('quick_test', {serial: inputData});
      await fetch();
    } catch (error) {
      let message = 'Ocorreu um erro.';
      if (error.response && error.response.data && error.response.data.message) {
          message = error.response.data.message
      }
      notification.error({ message });
    }
    setInputData('');
    setLoading(false);
  }

  async function onCreate() {
    setLoading(true);
    try {
      await api.post('quick_test/create_ns');
      await fetch();
    } catch (error) {
      let message = 'Ocorreu um erro.';
      if (error.response && error.response.data && error.response.data.message) {
          message = error.response.data.message
      }
      notification.error({ message });
    }
    setLoading(false);
  }

  async function onRefresh() {
    fetch();
  }

  async function onDeleteAll() {
    setLoading(true);
    try {
      await api.delete('quick_test');
      await fetch();
    } catch (error) {
      let message = 'Ocorreu um erro.';
      if (error.response && error.response.data && error.response.data.message) {
          message = error.response.data.message
      }
      notification.error({ message });
    }
    setLoading(false);
  }

  return (
    <>
      <div style={{ background: '#ECECEC', padding: '20px', marginTop: 3 }}>
        <Row type="flex" justify="space-around">
          <Col span={22}>
            <Input
              autoFocus='true'
              value={inputData}
              onChange={e => setInputData(e.target.value)}
              onPressEnter={onEnter}
              style={{ flex: 1, marginBottom: 10 }}
            />
          </Col>
          <Col span={2}>
            <Button type="primary" onClick={onEnter} disabled={loading}>
              {translate('quickTest.add')}
            </Button>
          </Col>
        </Row>
        <Row type="flex" justify="end" gutter={5}>
          <Col>
            <Button type="primary" onClick={onCreate} disabled={loading}>
              {translate('quickTest.create')}
            </Button>
          </Col>
          <Col>
            <Button onClick={onDeleteAll} disabled={loading}>
              {translate('quickTest.delete')}
            </Button>
          </Col>
          <Col>
            <Button onClick={onRefresh} disabled={loading} icon="reload">
              Refresh
            </Button>
          </Col>
        </Row>
        <Row>
          <Table
            size="small"
            columns={columns}
            dataSource={data}
            rowKey={(record, index) => index}
            loading={loading}
          />
        </Row>
      </div>
    </>
  )
}

export default QuickTest;