import EventEmitter from "events";

export function randomString(size = 8) {
    const value = Math.random().toString(36).slice(-size);
    return value.toUpperCase();
}

export function diffValueJSON(local, remote) {
    if (!remote && local) {
        const { value1, value2, valuesArray } = diffValueJSON({}, local);
        return { value1, value2, valuesArray: { values: valuesArray.values.filter(v => v.type === 'remote'), keys: [] } };
    }
    const newLocal = local || {};
    const newRemote = remote || {};
    const value1 = {};
    const value2 = {};
    const valuesArray = { values: [], keys: [] };
    let rett;
    for (const i in newRemote) {
        rett = {};
        if (typeof newRemote[i] === 'object') {
            rett = diffValueJSON(newLocal[i], newRemote[i]);
            if (!rett) {
                value1[i] = rett.value1;
                value2[i] = rett.value2;
                const key = randomString();
                valuesArray.values.push({ key: randomString(), attr: i, value: newLocal[i], type: 'local' }, { key, attr: i, value: newRemote[i], type: 'remote' });
                valuesArray.keys.push(key);
            }
        } else {
            if (!newLocal || !newLocal.hasOwnProperty(i) || newRemote[i] !== newLocal[i]) {
                value1[i] = newLocal[i];
                value2[i] = newRemote[i];
                const key = randomString();
                valuesArray.values.push({ key: randomString(), attr: i, value: newLocal[i], type: 'local' }, { key, attr: i, value: newRemote[i], type: 'remote' });
                valuesArray.keys.push(key);
            }
        }
    }
    return { value1, value2, valuesArray };
};

export const Bus = new EventEmitter();

export function copyToClipboard(text) {
    const ta = document.createElement("textarea");
    ta.innerText = text;
    document.body.appendChild(ta);
    ta.select();
    document.execCommand("copy");
    ta.remove();
}