import React from 'react';
import { Button, TreeSelect, Col, Row, Form, Input, Select, Icon } from 'antd';
import translate from '../../i18n/translate';


const fields_enum = [
    { description: 'Tag', value: 'tag' },
    { description: 'Tag Match', value: 'tag_match' },
    { description: 'Any', value: 'any' },
];

const resource_enum = [
    { description: 'Dashboard', value: 'dashboard' },
    { description: 'Device', value: 'device' },
];

const rule_enum = {
    dashboard: [
        { title: translate('asAccess.access'), value: 'access', key: '0-0' },
        { title: translate('default.arrangement'), value: 'arrangement', key: '0-01' },
    ],
    device: [
        { title: translate('asAccess.blueprint'), value: 'access', key: '0-0' },
    ],
};

const labelColList = {
    xs: 24,
    sm: 24,
    md: 10,
    lg: 6,
    xl: 4,
};

const labelColList2 = {
    xs: 24,
    sm: 24,
    md: 10,
    lg: 6,
    xl: 4,
};

export default function TargetsPermissionsComponent({ values, setValues, isPemission, valueDefault }) {

    function setChangeValue(index, value, field) {
        if (field === 'resource' && value !== values[index][field]) {
            values[index].rule = null;
        }
        values[index][field] = value;
        setValues([...values]);
    }

    function removeValue(index) {
        values.splice(index, 1);
        setValues([...values]);
    }

    function buttonsAddOrRemove(index) {
        return (
            <>
                <Icon className={`dynamic-delete-button ${!index ? 'margin-icon-remove-item' : ''}`}
                    type="minus-circle-o" onClick={() => removeValue(index)} />
                {(values.length === index + 1) && <Icon style={{ marginLeft: 5 }}
                    className={`dynamic-delete-button ${!index ? 'margin-icon-remove-item' : ''}`}
                    type="plus-circle-o" onClick={() => setValues([...values, valueDefault])} />}
            </>
        )
    }

    return <>
        {values.length === 0 && <Button onClick={() => setValues([valueDefault])} style={{ marginBottom: 20 }} type="dashed">{translate(`asAccess.${isPemission ? 'addPermissions' : 'addTargets'}`)}</Button>}
        {values.map((k, index) =>
            <Row key={index} gutter={16}>
                {isPemission &&
                    <>
                        <Col {...labelColList2}>
                            <Form.Item label={!index ? translate('default.permissions') : ''} required>
                                <Select defaultValue={null} value={k.resource} style={{ width: '100%' }} onChange={(value) => setChangeValue(index, value, 'resource')}>
                                    <Select.Option key={-1} value={null}>{translate('default.select.name')}</Select.Option>
                                    {resource_enum.map(x => <Select.Option key={x.value} value={x.value}>{x.description}</Select.Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col {...labelColList2}>
                            <Form.Item label={!index ? translate('default.rule') : ''} required>
                                <TreeSelect value={k.rule} treeData={k.resource ? rule_enum[k.resource] : []} treeCheckable
                                    onChange={(value) => setChangeValue(index, value, 'rule')} />
                            </Form.Item>
                        </Col>
                    </>
                }
                <Col {...labelColList}>
                    <Form.Item label={!index ? translate('default.targets') : ''} required>
                        <Select defaultValue='any'
                            value={k.target} style={{ width: '100%' }}
                            onChange={(value) => setChangeValue(index, value, 'target')}>
                            {fields_enum.map(x => <Select.Option key={x.value} value={x.value}>{x.description}</Select.Option>)}
                        </Select>
                    </Form.Item>
                </Col>
                {k.target !== 'any' && <Col {...labelColList}>
                    <Form.Item label={!index ? `Tag ${translate('default.key')}` : ''} required>
                        <Input value={k.tag_key}
                            onChange={(e) => {
                                const tag_key = e.target.value;
                                setChangeValue(index, tag_key, 'tag_key')
                            }} />
                    </Form.Item>
                </Col>}
                {k.target === 'tag' && <Col {...labelColList}>
                    <Form.Item label={!index ? translate('default.value') : ''} required>
                        <Input value={k.value}
                            onChange={(e) => {
                                const value = e.target.value;
                                setChangeValue(index, value, 'value')
                            }} />
                    </Form.Item>
                </Col>}
                {buttonsAddOrRemove(index)}
            </Row>
        )}
    </>
}
