import React, { useState } from 'react'
import { Tabs } from 'antd';
import AsDeviceMapping from './AsDeviceMapping';
import MigrationDevice from './MigrationDevice';
import MigrationBucket from './MigrationBucket';
import MigrationDashboards from './MigrationDashboards';
import MigrationUsers from './MigrationUsers';
import MigrationAccesses from './MigrationAccesses';
import MigrationAnalysis from './MigrationAnalysis';
import MigrationActions from './MigrationActions';
import translate from '../../i18n/translate';
const { TabPane } = Tabs;

export default function TabsAsDeviceMapping() {
    const [id, setId] = useState();

    return (
        <Tabs defaultActiveKey="0">
            <TabPane tab="Info" key="0">
                <AsDeviceMapping setId={setId} />
            </TabPane>
            <TabPane tab={translate('asDeviceMapping.migrationsDevice')} key="1" disabled={!id}>
                <MigrationDevice key={id} />
            </TabPane>
            <TabPane tab={translate('asDeviceMapping.migrationsBucket')} key="2" disabled={!id}>
                <MigrationBucket key={id} />
            </TabPane>
            <TabPane tab={translate('asDeviceMapping.migrationDashboards')} key="3" disabled={!id}>
                <MigrationDashboards key={id} />
            </TabPane>
            <TabPane tab={translate('asDeviceMapping.migrationUsers')} key="4" disabled={!id}>
                <MigrationUsers key={id} />
            </TabPane>
            <TabPane tab={translate('asDeviceMapping.migrationAccesses')} key="5" disabled={!id}>
                <MigrationAccesses key={id} />
            </TabPane>
            <TabPane tab={translate('asDeviceMapping.migrationAnalysis')} key="6" disabled={!id}>
                <MigrationAnalysis key={id} />
            </TabPane>
            <TabPane tab={translate('asDeviceMapping.migrationActions')} key="7" disabled={!id}>
                <MigrationActions key={id} />
            </TabPane>
        </Tabs>
    )
}