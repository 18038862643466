import React, { useState, useEffect } from 'react'
import { Form, Row, Col, Input, Select, Icon, Layout, Button } from 'antd'
import { formatDate } from '../../helpers/date';
import { storeContext } from '../../context';
import api from '../../helpers/api';
import SpinInfo from '../SpinInfo';
import { isAuthorized } from '../../helpers/verifyStatusErrorResponse';
import AlertNotAllow from '../AlertNotAllow';

const { Option } = Select;
const { Content } = Layout;

const labelCol = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 10,
};

export default function ProductApplicInfo() {
    const store = React.useContext(storeContext);
    const [state, setState] = useState();
    const [loading, setLoading] = useState(false);
    const [permission, setPermission] = useState(true);

    async function onSubmit(event) {
        event.preventDefault();
        setState(old => ({ ...old, loadingButton: true }));
        try {
            await api.put(`/product/client_sn/${store.currentItem.id}`, state);
            store.success = { message: 'Successfully updated' };
            setState(old => ({ ...old, loadingButton: false }));
        } catch (error) {
            let message = 'An error has occurred';
            if (error && error.error) {
                message = error.error
            }
            store.error = { message };
            setState(old => ({ ...old, loadingButton: false }));
        }
    }

    useEffect(() => {
        setLoading(true);
        api.get(`/product/client_sn/info/${store.currentItem.id}`)
            .then(data => {
                const result = data.data.result;
                if (result) {
                    setState(result);
                }
            })
            .catch(error => setPermission(isAuthorized(error)))
            .finally(() => setLoading(false));

    }, [store.currentItem]);

    return (
        <Content className="padding-20">
            {permission ?
                state ? (
                    <>
                        <Form layout="vertical" onSubmit={onSubmit}>
                            <Row gutter={16}>
                                <Col {...labelCol} >
                                    <Form.Item label="Id">
                                        <Input value={state.id} disabled={true} />
                                    </Form.Item>
                                </Col>
                                <Col {...labelCol} >
                                    <Form.Item label="Installation date">
                                        <Input value={formatDate(state.installation_date)} disabled />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col {...labelCol} >
                                    <Form.Item label="First Counter">
                                        <Input
                                            value={state.firstcounter}
                                            suffix={loading && <Icon type="loading" />}
                                            onChange={(e) => {
                                                const firstcounter = e.target.value;
                                                setState(prevState => ({ ...prevState, firstcounter }))
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col {...labelCol} >
                                    <Form.Item label="Counter Multiply">
                                        <Select
                                            value={state.countermultiply}
                                            loading={loading}
                                            onChange={(e) => {
                                                const countermultiply = e;
                                                setState(prevState => ({ ...prevState, countermultiply }))
                                            }}
                                        >
                                            <Option value="0.01">0.01</Option>
                                            <Option value="0.1">0.1</Option>
                                            <Option value="1">1</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col {...labelCol} >
                                    <Form.Item label="Client SN">
                                        <Input
                                            value={state.client_sn}
                                            onChange={(e) => {
                                                const client_sn = e.target.value;
                                                setState(prevState => ({ ...prevState, client_sn }))
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col {...labelCol} >
                                    <Form.Item label="Serial">
                                        <Input value={state.serial} disabled />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col  {...labelCol}>
                                    <Form.Item >
                                        < Button
                                            htmlType="submit"
                                            type="primary"
                                            loading={state.loadingButton}>Update</Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </>
                ) : (
                    <SpinInfo spinning={loading} delay={100} />
                ) :
                (
                    <AlertNotAllow />
                )}
        </Content>
    )
}
