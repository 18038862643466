import React from 'react'
import { Layout } from 'antd';
import AsTagComponent from './AsTagComponent';

const { Content } = Layout; 

export default function AsTag() {
  return (
    <Content className="padding-20">
       <AsTagComponent />
    </Content>
  )
}
