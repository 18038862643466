
import React from 'react';
import { useObserver } from "mobx-react";
import { storeContext } from '../../context';

import {
    Form,
    Input,
    Row,
    Col
} from 'antd';

import RegistryForm from '../RegistryForm';
import translate from '../../i18n/translate';
import CustomAutoComplete from '../CustomAutoComplete';
import ExternalServicesSettings from './ExternalServicesSettings';
import ExternalServiceTags from './ExternalServiceTags';

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: translate('default.name'),
        dataIndex: 'name',
        key: 'name',
        sorter: true,
    },
    {
        title: translate('externalServicesType.name'),
        dataIndex: 'external_services_type',
        key: 'external_services_type',
    },
];

const labelCol = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 10,
};

let ContentForm = () => {
    const store = React.useContext(storeContext);

    const handleChange = (field, event) => {
        store.currentItem[field] = event.target.value
    }

    const handleComboSelect = (field, value) => {
        console.log(field, value);
        store.currentItem[field] = value
    }

    return useObserver(() => (
        <Form layout="vertical">
            <Row gutter={16}>
                <Col {...labelCol}>
                    <Form.Item label="Id">
                        <Input value={store.currentItem.id} disabled={true} />
                    </Form.Item>
                </Col>
                <Col {...labelCol}>
                    <Form.Item label={translate('default.name')}>
                        <Input value={store.currentItem.name} onChange={handleChange.bind(this, 'name')} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col {...labelCol}>
                    <Form.Item label={translate('externalServices.api_address')}>
                        <Input value={store.currentItem.api_address} onChange={handleChange.bind(this, 'api_address')} />
                    </Form.Item>
                </Col>
                <Col {...labelCol}>
                    <Form.Item label={translate('externalServicesType.name')}>
                        <CustomAutoComplete
                            searchValue={'id'}
                            searchKey={'name'}
                            path={'/external_services_type'}
                            placeholder={translate('application.select.name')}
                            onSelect={handleComboSelect.bind(this, 'external_services_type_id')}
                            value={store.currentItem.external_services_type_id} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    ));
}

const ExternalServicesList = () => {
    const store = React.useContext(storeContext);

    ContentForm = Form.create({})(ContentForm);

    store.columns = columns;
    store.url = '/external_services';

    store.editForm = undefined;
    ContentForm.tab = 'Info';

    ExternalServiceTags.tab = "Tags";

    ExternalServicesSettings.tab = 'externalServicesSettings.name'
    ExternalServicesSettings.disabled = true;

    store.tabs = [ContentForm, ExternalServiceTags, ExternalServicesSettings];

    return <RegistryForm />
}

export default ExternalServicesList;