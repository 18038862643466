
import React, { useState } from 'react';
import { useObserver } from "mobx-react";
import { storeContext } from '../../context';
import CustomAutoComplete from '../CustomAutoComplete';
import CustomFilesUpload from '../CustomFilesUpload';
import FirmwareVersionTags from './FirmwareVersionTags';

import {
  Form,
  Input,
  Row,
  Col
} from 'antd';

import RegistryForm from '../RegistryForm';
import api from '../../helpers/api';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Firmware Type',
    dataIndex: 'firmware_type_name',
    key: 'firmware_type_name',
  },
  {
    title: 'Version Number',
    dataIndex: 'version_number',
    key: 'version_number',
  },
];

const labelCol = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 10,
};

let ContentForm = () => {
  const store = React.useContext(storeContext);
  const [loading, setLoading] = useState(false);
  const handleChange = (field, event) => {
    store.currentItem[field] = event.target.value
  }

  const handleComboSelect = (field, value) => {
    console.log(field, value);
    store.currentItem[field] = value
  }

  function savePath({ fileUrl = '' }) {
    setLoading(true);
    api.put(`firmware_version/photo/${store.currentItem.id}`, { file_url: fileUrl }).finally(() => {
      if (fileUrl) {
        store.currentItem.path = fileUrl;
      }
      setLoading(false);
    });
  }

  async function downloadSignedUrl() {
    setLoading(true);
    try {
      const { data } = await api.get(`firmware_version/${store.currentItem.id}/download`);
      if(data && data.result){
        window.open(data.result);
      }
    } finally {
      setLoading(false);
    }
  }

  return useObserver(() => (
    <Form layout="vertical">
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="Id">
            <Input value={store.currentItem.id} disabled={true} />
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label="Firmware Type">
            <CustomAutoComplete
              searchValue={'id'}
              searchKey={'name'}
              path={'/firmware_type'}
              placeholder={'Select the Firmware Type'}
              onSelect={handleComboSelect.bind(this, 'firmware_type_id')}
              value={store.currentItem.firmware_type_id} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="Version Number">
            <Input value={store.currentItem.version_number} onChange={handleChange.bind(this, 'version_number')} />
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label="Firmware">
            <CustomFilesUpload
              fileSingle={store.currentItem.path}
              disabled={!store.currentItem.id}
              func={savePath}
              fileName="Download"
              showRemoveIcon={false}
              loading={loading}
              funcDownload={downloadSignedUrl}
              uploadUrl={`/firmware_type/${store.currentItem.id}/firmware`}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  ));
}

const FirmwareVersionList = () => {
  const store = React.useContext(storeContext);

  ContentForm = Form.create({})(ContentForm);

  store.editForm = undefined;
  store.columns = columns;
  store.url = '/firmware_version';

  ContentForm.tab = 'Info';
  FirmwareVersionTags.tab = "Tags";

  store.tabs = [ContentForm, FirmwareVersionTags];

  return (
    <div>
      <RegistryForm />

    </div>
  )
}

export default FirmwareVersionList;
