import React, { useState } from 'react';
import { Tabs } from 'antd';

import translate from '../../i18n/translate';
import QuickTest from './QuickTest';
import QuickTestExport from './QuickTestExport';

const { TabPane } = Tabs;

function QuickTestPage() {
  const [activeTab, setActiveTab] = useState('1');

  return (
    <Tabs activeKey={activeTab} onTabClick={(key) => {setActiveTab(key);}} >
      <TabPane tab={translate('quickTest.test')} key="1">
        <QuickTest />
      </TabPane>
      <TabPane tab={translate('quickTest.export')} key="2" style={{overflow: 'scroll'}}>
        <QuickTestExport />
      </TabPane>
    </Tabs>
  )
}

export default QuickTestPage;