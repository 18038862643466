import React, { useState, useEffect } from 'react'
import api from '../../helpers/api'
import JSONFormatColor from '../JSONFormatColor';
import { storeContext } from '../../context';
import { Layout, Collapse, Form, Row, Col, Button } from 'antd';
import SpinInfo from '../SpinInfo';
import AlertNotAllow from '../AlertNotAllow';
import CustomAutoComplete from '../CustomAutoComplete';
import translate from '../../i18n/translate';
const { Content } = Layout;
const { Panel } = Collapse;

export default function ProductNsServer() {
    const store = React.useContext(storeContext);
    const [network, setNetwork] = useState();
    const [permission, setPermission] = useState(true);
    const [loading, setLoading] = useState(true);
    const [targetNs, setTargetNs] = useState();

    useEffect(() => {
      api.get(`/external_services/network_service/${store.currentItem.id}`)
        .then(data => {
          if (data.data.result) {
            setNetwork(data.data.result);
          }
        })
        .catch(() => setPermission(false))
        .finally(() => setLoading(false));
    }, [store.currentItem.id])

    const handleComboSelect = (field, value) => {
        console.log(field, value);
        setTargetNs(value);
    }
    
    const handleSubmit = async () => {
      const url = `external_services/network_service/${store.currentItem.id}`;
      console.log(targetNs)
      const data = {as_id: targetNs};
      const options = {
        method: 'POST',
        data,
        url,
      };

      await api(options)
        .then(res => {
          console.log(res.data);
        })
        .catch((error) => console.log(error));
    }

    const handleRemove = async () => {
      const url = `external_services/network_service/${store.currentItem.id}`;
      const options = {
        method: 'DELETE',
        url,
      };

      await api(options)
        .then(res => {
          console.log(res.data);
          
        })
        .catch((error) => console.log(error));
    }

    return (
      <Content className="padding-20"  >
        {permission ?
          network ? 
            (<>
              <h3>{network.ns}</h3>
              <Button onClick={handleRemove} style={{ marginBottom: 8 }}>Remover Device</Button>
              <Collapse>
                <Panel header="JSON Application Server" key="1">
                  <JSONFormatColor value={network} />
                </Panel>
              </Collapse>
            </>) :
            loading ?
              <SpinInfo spinning={loading} delay={100} /> :
              (
                <>
                  <h3>Selecione um NS</h3>
                  <Form layout="vertical">
                    <Row gutter={16}>
                      <Col lg={6}>
                        <Form.Item>
                          <CustomAutoComplete
                            searchValue={'id'}
                            searchKey={'name'}
                            path={'/external_services?service_type=NS'}
                            placeholder={translate('default.select.name')}
                            onSelect={handleComboSelect.bind(this, 'network_service_id')}
                            value={targetNs}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Button onClick={handleSubmit}>Criar Device</Button>
                      </Col>
                    </Row>
                  </Form>
                </>
              )
          :<AlertNotAllow />
        }
      </Content>
    )
}
