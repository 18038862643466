
import React from 'react';
import { useObserver } from "mobx-react";
import { storeContext } from '../../context';

import {
  Form,
  Input,
  Row,
  Col
} from 'antd';

import RegistryForm from '../RegistryForm';
import CustomTable from '../CustomTable';

const swVersionColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Version Number',
    dataIndex: 'version_number',
    key: 'version_number',
  },
  {
    title: 'Active',
    dataIndex: 'active',
    key: 'active',
  },
  {
    title: 'Latest',
    dataIndex: 'latest',
    key: 'latest',
  },
];

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Executable Name',
    dataIndex: 'executable_name',
    key: 'executable_name',
  },
];

const labelCol = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 10,
};

let ContentForm = () => {
  const store = React.useContext(storeContext);
  const handleChange = (field, event) => {
    store.currentItem[field] = event.target.value
  }

  return useObserver(() => (
    <Form layout="vertical">
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="Id">
            <Input value={store.currentItem.id} disabled={true} />
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label="Name">
            <Input value={store.currentItem.name} onChange={handleChange.bind(this, 'name')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="Executable Name">
            <Input value={store.currentItem.executable_name} onChange={handleChange.bind(this, 'executable_name')} />
          </Form.Item>
        </Col>
      </Row>
      <h3>
        Executable Versions
      </h3>
      <CustomTable
        size="small"
        columns={swVersionColumns}
        path={`/sw_executable/${store.currentItem.id}/version`}
      />
    </Form>
  ));
}

const SWExecutableList = () => {
  const store = React.useContext(storeContext);

  ContentForm = Form.create({})(ContentForm);

  store.editForm = ContentForm;
  store.columns = columns;
  store.url = '/sw_executable';

  return (
    <div>
      <RegistryForm />
    </div>
  )
}

export default SWExecutableList;
