import { Modal } from 'antd';
import translate from '../../i18n/translate';
const { confirm } = Modal;

export default function Confirm({
    title,
    content,
    func = () => {},
    value,
    okCancel = true,
    width,
    okText = null,
    onCancel = () => {}
}) {
    return confirm({
        width: width || 450,
        title: title || translate('default.warning'),
        content: content ||  translate('default.confirm.delete'),
        onOk() {
            func(value);
        },
        okCancel,
        onCancel,
        okText
    });
}
