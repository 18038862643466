import React, { useState, useEffect, useCallback } from 'react'
import { storeContext } from '../../context';
import { Button, Table, Col, Row, Form, Input } from 'antd';
import translate from '../../i18n/translate';
import api from '../../helpers/api';
import { getError } from '../../helpers/verifyStatusErrorResponse';
import AlertNotAllow from '../AlertNotAllow';
import Confirm from '../Confirm/Confirm';

const labelCol = {
  xs: 24,
  sm: 24,
  md: 10,
  lg: 10,
  xl: 8,
};

export default function AsTag({ type = 'AS_TAG' }) {
  const store = React.useContext(storeContext);
  const [data, setData] = useState([]);
  const [state, setState] = useState({});
  const [permission, setPermission] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const columns = [
    {
      title: translate('default.key'),
      dataIndex: 'key',
      key: 'key'
    },
    {
      title: translate('default.value'),
      dataIndex: 'value',
      key: 'value'
    },
    {
      title: '',
      align: 'right',
      key: 'action',
      width: 200,
      render: (text, record, index) => (
        <>
          <Button icon="edit" type="default" onClick={() => setState({ ...record, index })} htmlType="button" style={{ marginRight: 10 }} />
          <Button icon="delete" type="danger" onClick={() => Confirm({ func: () => remove(index) })} htmlType="button" />
        </>
      ),
    }
  ];

  async function remove(index) {
    try {
      setLoading(true);
      await api.delete(`/as_tag/${data[index].id}`);
      const temp = [...data];
      temp.splice(index, 1);
      setData(temp);
    } catch (e) {
      setPermission(getError(e, store));
    }
    setLoading(false);
  }

  async function onAdd() {
    const model = {
      ...state,
      as_type_id: store.currentItem.asTypeId,
      type
    }
    const result = await api.post('/as_tag', model);
    state.id = result.data.result.id;
    const temp = [...data, state];
    setData(temp);
    setState({});
  }

  async function onUpdate() {
    const index = state.index;
    delete state.index;
    const model = {
      ...state,
      as_type_id: store.currentItem.asTypeId,
      type
    }
    await api.put(`/as_tag/${state.id}`, model);
    data[index] = state;
    setData(data);
    setState({});
  }

  async function onSubmit(event) {
    event.preventDefault();
    try {
      const asTag = data.find(v => v.key.toUpperCase() === state.key.toUpperCase());
      if (asTag && asTag.id !== state.id) {
        store.error = { message: translate('asTag.keyAlreadyAdded') }
        return;
      }
      setLoadingButton(true);
      setLoading(true);
      if (state.id) {
        await onUpdate();
      } else {
        await onAdd();
      }
      store.success = { message: translate('asTag.save') }
    } catch (e) {
      setPermission(getError(e, store));
    }
    setLoadingButton(false);
    setLoading(false);
  }

  function isDisabled() {
    return !state || !state.value || !state.key;
  }

  const initialize = useCallback(async () => {
    setLoading(true);
    try {
      const data = await api.get(`/as_tag/as_type/${type}/${store.currentItem.asTypeId}`);
      if (data.data && data.data.result) {
        return setData(data.data.result);
      }
      setData([]);
    } catch (e) {
      setPermission(getError(e, store));
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [store.currentItem.asTypeId]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <>
      {permission ?
        <>
          <Form layout="vertical" onSubmit={onSubmit}>
            <Row gutter={16}>
              <Col {...labelCol}>
                <Form.Item label={translate('default.key')} required>
                  <Input value={state.key}
                    onChange={(e) => {
                      const key = e.target.value;
                      setState(prevState => ({ ...prevState, key }));
                    }} />
                </Form.Item>
              </Col>
              <Col {...labelCol}>
                <Form.Item label={translate('default.value')} required>
                  <Input value={state.value}
                    onChange={(e) => {
                      const value = e.target.value;
                      setState(prevState => ({ ...prevState, value }));
                    }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col>
                <Form.Item>
                  <Button loading={loadingButton} type="primary" disabled={isDisabled()} htmlType="submit" >
                    {state.id ? translate('default.save') : translate('default.add')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Col>
            <Table
              size="small"
              rowKey={record => record.key}
              columns={columns}
              dataSource={data}
              pagination={false}
              loading={loading}
            />
          </Col>
        </>
        : (
          <AlertNotAllow />
        )}
    </>
  )
}
