import React, { useEffect, useCallback, useState, useContext } from 'react';
import api from '../../helpers/api';
import { getError } from '../../helpers/verifyStatusErrorResponse';
import { Alert, Layout } from 'antd';
import AlertNotAllow from '../AlertNotAllow';
import { storeContext } from '../../context';
import SpinInfo from '../SpinInfo';
import translate from '../../i18n/translate';
import { diffValueJSON } from '../../helpers/utils';
import TableTransfer from '../TableTransfer';
const { Content } = Layout;


const columns = [
    {
        dataIndex: 'attr',
        title: translate('default.key'),
    },
    {
        dataIndex: 'value',
        title: translate('default.value'),
        render: (text) => {
            return (
                <>
                    {typeof text === 'boolean' || text ? String(text) : ""}
                </>
            )
        },
    },
];

export default function Migration({ url, keyLabel }) {
    const store = useContext(storeContext);
    const [loading, setLoading] = useState(true);
    const [permission, setPermission] = useState(true);
    const [permissionTable, setPermissionTable] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [isCreate, setCreate] = useState(false);

    const getData = useCallback(async () => {
        try {
            const { data: { result } } = await api.get(`as_device_mapping/migration/${url}/${store.currentItem.asDeviceMappingId}`);
            if (!result || result.isDeviceIdNull || result.deviceName) {
                return setCreate(result || true);
            }
            const aux = [];
            result.forEach(v => {
                const { valuesArray } = diffValueJSON(v.enablers, v.tago);
                aux.push({ values: valuesArray.values, keys: valuesArray.keys, ...v });
            });
            setDataSource(aux);
        } catch (e) {
            setPermission(getError(e, store));
        }
        // eslint-disable-next-line
    }, [store.currentItem.asDeviceMappingId]);

    async function save(data) {
        try {
            setPermissionTable(true);
            await api.put(`as_device_mapping/migration/${url}/${store.currentItem.asDeviceMappingId}`, data);
            await getData();
        } catch (e) {
            setPermission(getError(e, store));
        } finally {
            setPermissionTable(false);
        }
    }

    function onChange(value, direction, keys = [], index) {
        const data = dataSource[index].values.filter(v => keys.includes(v.key));
        if (data.length) {
            const obj = {};
            data.forEach(v => obj[v.attr] = v.value);
            save({
                data: { data: obj, id: dataSource[index].tago.id || dataSource[index].enablers.id },
                type: data[0].type === 'local' ? 'tago' : 'enablers'
            });
        }
    }

    const initialize = useCallback(async () => {
        setLoading(true);
        setCreate(false);
        await getData();
        setLoading(false);
    }, [getData]);

    function getLabel(value) {
        return value && keyLabel ? ` - ${value[keyLabel]}` : '';
    }

    useEffect(() => {
        initialize();
    }, [initialize]);

    function component() {
        if (!permission) {
            return <AlertNotAllow />
        }
        if (loading) {
            return <SpinInfo spinning={loading} delay={100} />
        }
        if (isCreate || Object.assign(isCreate).isDeviceIdNull) {
            return <Alert
                message={translate('default.info')}
                description={translate('information.empty')}
                type="info"
            />
        }
        return dataSource.map((data, index) => <TableTransfer
            key={index}
            loading={permissionTable}
            dataSource={data.values}
            targetKeys={data.keys}
            onChange={(value, direction, keys) => onChange(value, direction, keys, index)}
            filterOption={(inputValue, item) =>
                item.title.indexOf(inputValue) !== -1 || item.tag.indexOf(inputValue) !== -1
            }
            leftColumns={columns}
            rightColumns={columns}
            titles={[`Enablers ${getLabel(data.enablers)}`, `Tago ${getLabel(data.tago)}`]}
            style={{ marginBottom: 30 }}
        />
        );
    }

    return (
        <>
            <Content className="padding-20">
                {component()}
            </Content>
        </>
    )
}
