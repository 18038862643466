
import React from 'react';
import { useObserver } from "mobx-react";
import { storeContext } from '../../context';

import {
    Form,
    Input,
    Row,
    Col,
    Select
} from 'antd';

import RegistryForm from '../RegistryForm';
import translate from '../../i18n/translate';
const { Option } = Select;

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: translate('default.name'),
        dataIndex: 'name',
        key: 'name',
        sorter: true,
    },
    {
        title: translate('default.type'),
        dataIndex: 'service_type',
        key: 'service_type',
    },
];

const labelCol = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 10,
};

let ContentForm = () => {
    const store = React.useContext(storeContext);

    const handleChange = (field, event) => {
        store.currentItem[field] = event.target.value
    }

    function handleSelectChange(field, value) {
        store.currentItem[field] = value
    }

    return useObserver(() => (
        <Form layout="vertical">
            <Row gutter={16}>
                <Col {...labelCol}>
                    <Form.Item label="Id">
                        <Input value={store.currentItem.id} disabled={true} />
                    </Form.Item>
                </Col>
                <Col {...labelCol}>
                    <Form.Item label={translate('default.name')}>
                        <Input value={store.currentItem.name} onChange={handleChange.bind(this, 'name')} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col {...labelCol} >
                    <Form.Item>
                        <Select defaultValue={null} value={store.currentItem.service_type || null} style={{ width: '100%' }} onChange={handleSelectChange.bind(this, 'service_type')} >
                            <Option key={-1} value={null}>{translate('default.select.type')}</Option>
                            <Option key={1} value="AS">AS</Option>
                            <Option key={2} value="NS">NS</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    ));
}

const ExternalServicesTypeList = () => {
    const store = React.useContext(storeContext);
    ContentForm = Form.create({})(ContentForm);

    store.editForm = ContentForm;
    store.columns = columns;
    store.url = '/external_services_type';

    return (
        <div>
            <RegistryForm />
        </div>
    )
}

export default ExternalServicesTypeList;
