
import React, { useCallback, useEffect, useState } from 'react';
import { useObserver } from "mobx-react";
import { storeContext } from '../../context';
import {
    Form,
    Input,
    Row,
    Col,
} from 'antd';

import RegistryForm from '../RegistryForm';
import translate from '../../i18n/translate';
import api from '../../helpers/api';
import CustomTree from '../CustomTree';

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 300
    },
    {
        title: translate('default.name'),
        dataIndex: 'name',
        key: 'name',
    },
];

const labelCol = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 10,
};
const result = { data: { data: { result: [] } } };

let ContentForm = () => {
    const store = React.useContext(storeContext);
    const [items, setItems] = useState([]);
    const [checkedKeys, setCheckedKeys] = useState([]);

    const handleChange = (field, event) => {
        store.currentItem[field] = event.target.value
    }

    const setFilteredTree = (value) => {
        store.currentItem.items = value;
    }

    const listItems = useCallback(() => {
        Promise.all([
            api.get('/sys_permissions'),
            api.get('/permission/keys'),
            store.currentItem.id ? api.get(`/group_profile/item/${store.currentItem.id}`) : result,
        ]).then(data => {
            if (data[1].data.result) {
                buildItems(data[0].data.result, data[1].data.result, data[2].data.result);
            }
        });
    }, [store.currentItem.id]);

    function buildItems(sysPermissions = [], permissionKeys = [], items = [],) {
        const checked = [];
        const halfCheckedKeys = ['0-0'];
        sysPermissions = sysPermissions.filter(el => el.name !== '*');
        sysPermissions.forEach((element, index) => {
            let flag = false;
            element.key = `${index}-1`;
            element.children = [];
            permissionKeys.forEach((key, idx) => {
                const find = items.find(p => p.permission === element.name && p.type === key.type);
                if (find) {
                    flag = true;
                    checked.push(`${index}-1-${idx + 1}`);
                }
                element.children.push({
                    key: `${index}-1-${idx + 1}`,
                    name: key.info,
                    type: key.type,
                    id: find ? find.id : null,
                });
            });
            if (flag) {
                halfCheckedKeys.push(`${index}-1`)
            }
        });
        setCheckedKeys({ checkedKeys: checked, halfCheckedKeys });
        setItems(sysPermissions);
    }

    useEffect(() => {
        listItems();
    }, [listItems]);

    return useObserver(() => (
        <Form layout="vertical">
            <Row gutter={16}>
                <Col {...labelCol}>
                    <Form.Item label="Id">
                        <Input value={store.currentItem.id} disabled={true} />
                    </Form.Item>
                </Col>
                <Col {...labelCol}>
                    <Form.Item label={translate('default.name')}>
                        <Input value={store.currentItem.name} onChange={handleChange.bind(this, 'name')} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col {...labelCol}>
                    <CustomTree
                        data={items}
                        setFilteredTree={setFilteredTree}
                        checkedKeys={checkedKeys}
                    />
                </Col>
            </Row>
        </Form>
    ));
}

const GroupProfileList = () => {
    const store = React.useContext(storeContext);

    ContentForm = Form.create({})(ContentForm);

    store.editForm = ContentForm;
    store.columns = columns;
    store.url = '/group_profile';

    return (
        <div>
            <RegistryForm />
        </div>
    )
}

export default GroupProfileList;
