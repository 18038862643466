import React from 'react'
import { Alert, Spin } from 'antd';
import translate from '../i18n/translate';
export default function SpinInfo({ ...rest }) {
    return (
        <Spin {...rest}>
            <Alert
                message={translate('default.info')}
                description={rest.spinning ? translate('information.loading') : translate('information.empty')}
                type="info"
            />
        </Spin>
    )
}
