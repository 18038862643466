import React from 'react';
import { Checkbox, Form } from 'antd';

const options = [
    { label: 'Testing', value: 'testing' },
    { label: 'Problem', value: 'problem' },
    { label: 'Finished', value: 'finished' },
    { label: 'Second Test', value: 'second_test' },
    { label: 'Ready to install', value: 'ready_to_install' },
    { label: 'Installed', value: 'installed' },
    { label: 'Deactivated', value: 'deactivated' },
];
function ProductFilters(props) {
   
    function onChange(checkedValues) {
        const data = checkedValues.join(',')
        props.onSearchValue({ product_state: data });
    }

    return (
        <>
            <Form.Item >
                <Checkbox.Group options={options} onChange={onChange} />
            </Form.Item>
        </>
    )
}
export default ProductFilters