
import React from 'react';
import { useObserver } from "mobx-react";
import { storeContext } from '../context';
import { Button, Layout, Modal } from 'antd';
import axios from 'axios';
import configuration from '../config'
import CustomTabs from './CustomTabs';
import translate from '../i18n/translate';
import { Bus } from '../helpers/utils';

const { Footer, Content } = Layout;

const Status = ({ eventDelete }) => {
  const store = React.useContext(storeContext);

  if (!store) throw Error("Store shouldn't be null");

  const promptDelete = async () => {
    store.modalAlertVisible = true;
  }

  const handleOk = async (e) => {
    store.modalAlertVisible = false;
    await deleteItem();
  };

  const handleCancel = async (e) => {
    store.modalAlertVisible = false;
  };

  const deleteItem = async () => {
    store.deleteLoading = true;
    try {
      const url = `${configuration.URL}${store.url}/${store.currentItem.id}`;
      const options = {
        method: 'DELETE',
        headers: { token: global.token },
        url,
      };
      await axios(options).then(r => r.data.result);
      if (eventDelete) {
        Bus.emit(eventDelete, store.currentItem);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        return store.error = { message: error.response.data.message };
      }
      store.error = { message: `unable to delete, ${error.message}` };
    } finally {
      store.deleteLoading = false;
      store.currentItem = undefined;
    }
    if (store.data) {
      store.data = store.data.filter(x => x.id !== store.currentItem.id)
    }
  }

  function component() {
    const Comp = store.editForm;
    const tabs = store.tabs;
    if (!Comp && tabs && tabs.length) {
      return <CustomTabs>
        {tabs.map((Tab, index) => <Tab hideReload={Tab.hideReload} key={index} tab={Tab.tab} disabled={Tab.disabled} {...Tab.params} />)}
      </CustomTabs>
    }
    return <Comp />
  }

  return useObserver(() => (
    <div>
      <Modal
        title="Delete"
        visible={store.modalAlertVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>{translate('default.confirm.delete')}</p>
      </Modal>
      <Content style={{ minHeight: 550 }}>
        {component()}
      </Content>
      <Footer style={{ padding: 15, textAlign: 'end' }}>
        {store.buttonFooter && store.buttonFooter}
        {!store.hideDeleteButton && <Button
          style={{ marginLeft: 10 }}
          type="danger"
          onClick={promptDelete}
          loading={store.deleteLoading}
          disabled={!store.currentItem.id}
        >{translate('default.delete')}</Button>}
      </Footer>
    </div>
  ));
};

export default Status;
