import React, { useEffect, useState } from 'react';
import { useObserver } from "mobx-react";
import { storeContext } from '../../context';
import { formatDate } from '../../helpers/date';
import ProductPhotos from './ProductPhotos';
import {
  Form,
  Input,
  Row,
  Col,
  Icon,
  Select,
  Button,
} from 'antd';
import RegistryForm from '../RegistryForm';
import api from '../../helpers/api';
import ProductInstallation from './ProductInstallation';
import CustomAutoComplete from '../CustomAutoComplete';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import translate from '../../i18n/translate';

const { Option } = Select;
const columns = [
  {
    title: 'Serial',
    dataIndex: 'serial',
    key: 'serial',
    sorter: true,
  },
  {
    title: 'Client Serial',
    dataIndex: 'client_sn',
    key: 'client_sn',
    sorter: true,
  },
  {
    title: 'Status',
    dataIndex: 'installation_status',
    key: 'installation_status',
    sorter: true,
  },
  {
    title: 'Account Name',
    dataIndex: 'account_name',
    key: 'account_name',
    sorter: true,
  },
  {
    title: 'Client',
    dataIndex: 'client_name',
    key: 'client_name',
    sorter: true,
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
    render: created_at => (created_at ? moment(created_at).format("DD/MM/YYYY HH:mm:ss") : ''),
    sorter: true,
  },
];

const labelCol = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 10,
};

const labelColFilter = {
  xs: 24,
  sm: 24,
  md: 4,
  lg: 4,
  xl: 4,
};

function InstallationValidationFilters(props) {
  const store = React.useContext(storeContext);

  function setFilterAndListAll(params) {
    console.log(params);
    store.filterInstallationValidation = { ...store.filterInstallationValidation, ...params };
    console.log(store.filterInstallationValidation);
    props.onSearchValue(store.filterInstallationValidation);
  }

  return (
    <>
      <Row gutter={8}>
        <Row gutter={16}>
          <Col  {...labelColFilter} >
            <Form.Item>
              <CustomAutoComplete
                searchValue={'id'}
                searchKey={'name'}
                path={'/client'}
                placeholder={'Selecione o cliente'}
                onSelect={(client_id) => {
                  setFilterAndListAll({ client_id });
                }}
                value={store.filterInstallationValidation ? store.filterInstallationValidation.client_id : null}
              />
            </Form.Item>
          </Col>
          <Col {...labelColFilter} >
            <Form.Item>
              <Select
                value={store.filterInstallationValidation && store.filterInstallationValidation.installation_status ? store.filterInstallationValidation.installation_status : null} style={{ width: '100%' }}
                onChange={(installation_status) => setFilterAndListAll({ installation_status })} >
                <Option key={-1} value={null}>{'Selecione o Status'}</Option>
                <Option value="ok">OK</Option>
                <Option value="not_ok">Not OK</Option>
                <Option value="pendent">Pendent</Option>
                <Option value="not_checked">Not Checked</Option>
              </Select>
            </Form.Item>
          </Col>
          <Button onClick={() => setFilterAndListAll()} icon="reload" style={{ margin: 3 }}>
            {translate('default.reload')}
          </Button>
        </Row>
      </Row>
    </>
  )
}

let ContentForm = (props) => {
  const [loadingButton, setLoadingButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const store = React.useContext(storeContext);

  console.log('Content form Props -->', props);
  
  useEffect(() => {
    // console.log('Form History',store.Installationhistory);
    setLoading(true);
    api.get(`/product/param_server/${store.currentItem.id}`)
      .then(data => {
        const result = data.data.result;
        if (result) {
          store.currentItem.firstcounter = result.firstcounter || '';
          store.currentItem.countermultiply = result.countermultiply || '';
          store.currentItemBkp.countermultiply = result.countermultiply || '';
          store.currentItemBkp.firstcounter = result.firstcounter || '';
        }
      }).finally(() => setLoading(false));

  }, [store.currentItem, store.currentItemBkp]);

  async function handleclick(validationStatus){
    setLoadingButton(true);
    const model = {
      installation_status: validationStatus ? 'ok' : 'not_ok'
    }
    await api.post(`/product_installation/validation/${store.currentItem.installation_id}`, model);
    setLoadingButton(false);
    store.currentItem = undefined;
    props.history.push(`${store.url}`);
  }

  function isDisabled() {
    return store.currentItem.installation_status !== 'pendent'
  }


  return useObserver(() => (
    <Form layout="vertical">
      <Row gutter={16}>
        <Col {...labelCol} >
          <Form.Item label="Product Id">
            <Input value={store.currentItem.id} disabled={true} style={{ color: 'black' }}/>
          </Form.Item>
        </Col>
        <Col {...labelCol} >
          <Form.Item label={'Installation Date'}>
            <Input value={formatDate(store.currentItem.installation_date)} disabled style={{ color: 'black' }}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol} >
          <Form.Item label={'First Counter'}>
            <Input value={store.currentItem.firstcounter} disabled
              suffix={loading && <Icon type="loading" />} style={{ color: 'black' }}/>
          </Form.Item>
        </Col>
        <Col {...labelCol} >
          <Form.Item label={'Counter Multiply'}>
            <Input value={store.currentItem.countermultiply} disabled
              suffix={loading && <Icon type="loading" />} style={{ color: 'black' }}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol} >
          <Form.Item label={'Client SN'}>
            <Input value={store.currentItem.client_sn} disabled style={{ color: 'black' }}/>
          </Form.Item>
        </Col>
        <Col {...labelCol} >
          <Form.Item label="Serial">
            <Input value={store.currentItem.serial} disabled style={{ color: 'black' }}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={4}>
          <Form.Item>
            <Button loading={loadingButton} type="primary" disabled={isDisabled()} onClick={() => handleclick(true)} >
              Approved
            </Button>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item>
            <Button loading={loadingButton} type="secondary" disabled={isDisabled()} onClick={() => handleclick(false)} >
              Not Approved
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  ));
}

const InstallationValidation = () => {
  const store = React.useContext(storeContext);

  ContentForm = Form.create({})(withRouter(ContentForm));

  store.hideNewButton = true;
  store.hideDeleteButton = true;
  store.editForm = undefined;
  ContentForm.tab = 'Info';

  ProductPhotos.tab = 'default.photos';
  ProductPhotos.disabled = true;

  ProductInstallation.tab = 'product.installation';
  ProductInstallation.disabled = true;

  store.tabs = [
    ContentForm,
    ProductPhotos,
    ProductInstallation,
  ];

  store.columns = columns;
  store.url = '/product_installation/validation';
  store.filterDefault = {installation_status: 'pendent'};
  store.filterInstallationValidation = {installation_status: 'pendent'};
  store.sorter = {field: 'created_at', order: 'descend'}

  useEffect(() => {
    store.customFilters = InstallationValidationFilters;
    return () => {
      console.log('clean up')
      ProductPhotos.params = null;
      store.hideNewButton = false;
      store.hideDeleteButton = false;
      store.customFilters = null;
      store.filterDefault = {};
      store.sorter = {};
    }
  }, [
    store.customFilters,
    store.hideNewButton,
    store.hideDeleteButton,
    store.filterDefault,
    store.sorter
  ]);

  return (
    <div>
      <RegistryForm />
    </div>
  )
}

export default InstallationValidation;
