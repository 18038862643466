import React, { useState, useEffect, useCallback } from 'react'
import api from '../../helpers/api'
import { storeContext } from '../../context';
import { Layout, Table,  Checkbox, Form } from 'antd';
import { formatDate } from "../../helpers/date";
import JSONFormatColor from '../JSONFormatColor';
import { isAuthorized } from '../../helpers/verifyStatusErrorResponse';
import AlertNotAllow from '../AlertNotAllow';
const { Content } = Layout;

const columns = [
    {
        title: 'Variable',
        dataIndex: 'variable',
        key: 'variable',
        sorter: true,
    },
    {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
    },
    {
        title: 'Time',
        dataIndex: 'time',
        key: 'time',
        sorter: true,
        defaultSortOrder: 'descend',
        render: (time) => formatDate(time),
    },
];

export default function DataProduct() {
    const store = React.useContext(storeContext);
    const [data, setData] = useState([]);
    const [itemsFilter, setItemsFilter] = useState(null);
    const [filter, setFilter] = useState(null);
    const [order, setOrder] = useState(undefined);
    const [permission, setPermission] = useState(true);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(true);
    const limit = 15;

    function Filters() {
        function onChange(checkedList) {
            setFilter(checkedList);
            getDataTableName(0, checkedList, order);
        }
        return (
            <>
                <Form.Item>
                    <Checkbox.Group options={itemsFilter} onChange={onChange} value={filter} />
                </Form.Item>
            </>
        )
    }

    const getDataTableNameFilter = useCallback(async () => {
        const result = await api.get(`/product/data_table_name/filter/${store.currentItem.as_device}`);
        if (result && result.data) {
            const fil = result.data.result.map(v => v.variable);
            setItemsFilter(fil);
        }
    }, [store.currentItem.as_device]);

    const getDataTableName = useCallback(async (page = 0, checkedList = [], sorter = {column: columns[2] ,order: 'descend', field: 'time'}) => {
        console.log('sorter -> ', sorter);
        try {
            setLoading(true);
            const offset = page ? ((limit * page) - limit) : 0;
            let params = `${store.currentItem.as_device}?limit=${limit}&offset=${offset}`;
            if (checkedList && checkedList.length) {
                params += `&variable=${checkedList.join(',')}`;
            }
            if (sorter && sorter.column) {
                params += `&order=${sorter.field} ${sorter.order === 'descend' ? 'desc' : 'asc'}`;
            }
            console.log('params -> ',params);
            const result = await api.get(`/product/data_table_name/${params}`);
            if (result) {
                setData(result.data.result.data);
                setTotalItems(result.data.result.count || 0);
            }
        } catch (error) {
            setPermission(isAuthorized(error));
        } finally {
            setLoading(false);
        }
    }, [store.currentItem.as_device]);

    function changeOrderPagination(page = 0, checkedList = [], sorter = {order: 'descend', field: 'time'}) {
        setOrder(sorter);
        getDataTableName(page, checkedList, sorter);
    }

    useEffect(() => {
        getDataTableName();
        getDataTableNameFilter();
    }, [getDataTableName, getDataTableNameFilter]);

    return (
        <Content className="padding-20">
            {permission ? (
                <>
                    {itemsFilter && itemsFilter.length ? <Filters /> : null}
                    <Table
                        size="small"
                        rowKey={(record, index) => index}
                        columns={columns}
                        expandedRowRender={record => record.metadata ? <JSONFormatColor value={JSON.parse(record.metadata)} /> : <h1>Empty</h1>}
                        dataSource={data}
                        loading={loading}
                        onChange={(value, a, sorter) => changeOrderPagination(value.current, filter, sorter)}
                        pagination={{
                            defaultPageSize: limit,
                            pageSize: limit,
                            total: totalItems,
                        }}
                    />,
                </>) :
                (
                    <AlertNotAllow />
                )}
        </Content>
    )
}
