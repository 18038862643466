import React, { useState, useContext } from 'react';
import { Button, Col, Input, Form, Row } from 'antd';
import translate from '../../i18n/translate';
import api from '../../helpers/api';
import { storeContext } from '../../context';

export default function ModalNewDevice({ func, url }) {
    const store = useContext(storeContext);
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({ name: store.currentItem.asDeviceMappingName });

    async function save() {
        try {
            setLoading(true);
            await api.put(`as_device_mapping/migration/new/${url}/${store.currentItem.asDeviceMappingId}`, state);
            if (func)
                func();
        } catch (e) {
            if (e.response && e.response.data && e.response.data.message) {
                return store.error = { message: e.response.data.message }
            }
            store.error = { message: translate('default.errorOccurred') }
        } finally {
            setLoading(false);
        }
    }

    return (
        <Form layout="vertical">
            <h3>{`${translate('asDeviceMapping.create')} ${translate(`default.${url}`).toLowerCase()}`}</h3>
            <Row>
                <Col span={12}>
                    <Form.Item label={translate('default.name')} required name="name">
                        <Input value={state.name}
                            onChange={(e) => {
                                const name = e.target.value;
                                setState(prevState => ({ ...prevState, name }));
                            }} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Button onClick={() => save()} loading={loading}>{translate('default.save')}</Button>
                </Col>
            </Row>
        </Form>
    )
}
