/* eslint-disable no-throw-literal */
import api from '../helpers/api';

export async function activation(item) {
    let result = {};
    try {
        result = await createDeviceActivateHistory(item);
        if (result.history_id) {
            return startActivation(result.history_id);
        }
        throw ({
            error: 'Ocorreu um erro na ativação',
            history_id: result.history_id
        });
    } catch (error) {
        throw error;
    }
}

async function createDeviceActivateHistory(item) {
    const {data: appData} = await api.get(`account_profile/up/application`);
    const appId = appData.result?.id;
    console.log('appId --->', appId);
    if(!appId){
        throw ({ error: 'O perfil não está associado a uma aplicação' });
    }

    const body = {
        serial_number: item.serial_number,
        sensor_number: item.sensor_number,
        initial_counter: Number(`${item.initial_counter}.${item.initial_counter_decimal}`),
        multiplier: item.multiplier,
        lat: '',
        lng: '',
        id: item.history_id,
        application_id: appId
    };
    let data = {};
    try {
        data = await createOrUpdateDeviceActivateHistory(body);
        if (!data.data || !data.data.result.id) {
            throw ({ error: 'Ocorreu um erro na ativação' });
        }
        return {
            history_id: data.data.result.id
        };
    } catch (error) {
        console.log('Erro: Ocorreu um erro na ativação', error);
        const history_id = data.data ? data.data.result.id : null;
        throw ({
            error: 'Ocorreu um erro na ativação',
            history_id
        });
    }
}

function createOrUpdateDeviceActivateHistory(body) {
    if (body.id) {
        return api.put(`/device_activation_history/${body.id}`, body);
    }
    return api.post('/device_activation_history', body);
}

async function startActivation(history_id) {
    try {
        const result = await api.get(`/device_activation_history/activate/${history_id}`);
        if (result && result.data && result.data.result.product_id) {
            return result.data.result;
        }
        throw ({
            error: 'Ocorreu um erro na ativação',
            history_id
        });
    } catch (err) {
        let error;
        if (err.response && err.response.data && err.response.data.message) {
            error = error.response.data.message
        }
        throw ({
            error,
            history_id
        });
    }
}