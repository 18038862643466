import React, { useState } from 'react';
import { Button, Row, Col, Form, Input, notification } from 'antd';
import { deactivation } from '../../services/deviceDeactivationService';
import translate from '../../i18n/translate';

const labelCol = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 10,
};

function DeviceDeactivation() {
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({ history_id: null });

    async function onSubmit(event) {
        setLoading(true);
        event.preventDefault();
        try {
            await deactivation(state);
            notification.success({ message: 'Device Successfully deactivated' });
            setState({});
        } catch (error) {
            let message = 'Ocorreu um erro na desativação';
            if (error.response && error.response.data && error.response.data.message) {
                message = `${message}: ${error.response.data.message}`
            }
            notification.error({ message });
            const history_id = error.history_id;
            console.log(error);

            setState(prevState => ({ ...prevState, history_id }));
        } finally {
            setLoading(false);
        }
    }

    function isDisabled() {
        return !state || !state.sensor_number;
    }

    return (
        <>
            <div style={{ background: '#f0f2f5', padding: '30px', marginTop: 3 }}>
                <Form layout="vertical" onSubmit={onSubmit}>
                    <Row gutter={24}>
                        <Col {...labelCol} >
                            <Form.Item label={translate('device.sensorNumber')}>
                                <Input
                                    autoComplete="off"
                                    name="sensor_number"
                                    value={state.sensor_number}
                                    onChange={(e) => {
                                        const sensor_number = e.target.value;
                                        setState(prevState => ({ ...prevState, sensor_number: sensor_number.toUpperCase() }))
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col {...labelCol} >
                            <Form.Item label={translate('device.reasonUninstall')}>
                                <Input
                                    name="reason"
                                    value={state.reason}
                                    autoComplete="off"
                                    onChange={(e) => {
                                        const reason = e.target.value;
                                        setState(prevState => ({ ...prevState, reason: reason }))
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col {...labelCol}>
                            < Button
                                disabled={isDisabled()}
                                htmlType="submit"
                                loading={loading}>{translate('device.startDeactivation')}</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    )
}

export default DeviceDeactivation;
