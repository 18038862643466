import React, { useState, useEffect } from 'react'
import api from '../../helpers/api'
import { storeContext } from '../../context';
import { Layout, Input, Row, Col, Form } from 'antd';
import SpinInfo from '../SpinInfo';
import { formatDate } from "../../helpers/date";
import AlertNotAllow from '../AlertNotAllow';

const { Content } = Layout;

const labelCol = {
    xs: 24,
    sm: 24,
    md: 6,
    lg: 6,
    xl: 6,
};

export default function StatisticsProduct() {
    const store = React.useContext(storeContext);
    const [deviceData, setDeviceData] = useState();
    const [permission, setPermission] = useState(true);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        api.get(`/device_data/${store.currentItem.radio_serial}/statistics`)
            .then(data => {
                setDeviceData(data.data.result)
            })
            .catch(() => setPermission(false))
            .finally(() => setLoading(false));
    }, [store.currentItem.radio_serial])

    return (
        <Content className="padding-20"  >
            {permission ?
                deviceData ? (
                    <>
                        <Row gutter={16}>
                            <Col {...labelCol}>
                                <Form.Item label="Radio Serial">
                                    <Input value={deviceData.radio_serial} disabled />
                                </Form.Item>
                            </Col>
                            <Col {...labelCol}>
                                <Form.Item label="Total">
                                    <Input value={deviceData.total} disabled />
                                </Form.Item>
                            </Col>
                            <Col  {...labelCol}>
                                <Form.Item label="Counter">
                                    <Input value={deviceData.counter} disabled />
                                </Form.Item>
                            </Col>
                            <Col  {...labelCol}>
                                <Form.Item label="Diff From Now">
                                    <Input value={deviceData.diff_from_now} disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col  {...labelCol}>
                                <Form.Item label="Min Rssi">
                                    <Input value={deviceData.min_rssi} disabled />
                                </Form.Item>
                            </Col>
                            <Col {...labelCol}>
                                <Form.Item label="Max Rssi">
                                    <Input value={deviceData.max_rssi} disabled />
                                </Form.Item>
                            </Col>
                            <Col  {...labelCol}>
                                <Form.Item label="Min Time">
                                    <Input value={formatDate(deviceData.min_time)} disabled />
                                </Form.Item>
                            </Col>
                            <Col {...labelCol}>
                                <Form.Item label="Max Time">
                                    <Input value={formatDate(deviceData.max_time)} disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col {...labelCol}>
                                <Form.Item label="Diff Time">
                                    <Input value={deviceData.diff_time} disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>) : (
                        <>
                            <SpinInfo spinning={loading} delay={100} />
                        </>
                    ) :
                (
                    <AlertNotAllow />
                )}
        </Content>
    )
}
