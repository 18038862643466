
import React from 'react';
import { useObserver } from "mobx-react";
import { storeContext } from '../../context';
import CustomAutoComplete from '../CustomAutoComplete';
import CustomFilesUpload from '../CustomFilesUpload';
import axios from 'axios';
import config from '../../config';
import {
  Form,
  Input,
  Switch,
  Row,
  Col
} from 'antd';

import RegistryForm from '../RegistryForm';


const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'SW Executable',
    dataIndex: 'executable_name',
    key: 'executable_name',
  },
  {
    title: 'Version Number',
    dataIndex: 'version_number',
    key: 'version_number',
  },
  {
    title: 'Active',
    dataIndex: 'active',
    key: 'active',
  },
  {
    title: 'Latest',
    dataIndex: 'latest',
    key: 'latest',
  },
];

const labelCol = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 10,
};

let ContentForm = () => {

  const store = React.useContext(storeContext);

  const handleChange = (field, event) => {
    store.currentItem[field] = event.target.value;
  }

  const handleSwitchChange = (field, checked) => {
    store.currentItem[field] = checked ? 1 : 0;
  }

  const handleComboSelect = (field, value) => {
    console.log(field, value);
    store.currentItem[field] = value;
  }

  const switchChecked = (value) => {
    return value && value !== '0' ? true : false;
  }

  const handleUpdatePath = () => {
    const url = `${config.URL}/sw_version/path/${store.currentItem.id}/${store.currentItem.sw_executable_id}/${store.currentItem.version_number}`;
    axios({
      url,
      method: 'PUT',
      headers: { token: global.token },
    }).then((data) => {
      store.currentItem.path = data.data.result
    })
  }

  return useObserver(() => (
    <Form layout="vertical">
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="ID">
            <Input value={store.currentItem.id} disabled={true} />
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label="Version Number">
            <Input value={store.currentItem.version_number} onChange={handleChange.bind(this, 'version_number')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="SW Executable">
            <CustomAutoComplete
              searchValue={'id'}
              searchKey={'name'}
              path={'/sw_executable'}
              placeholder={'Select the SW Executable'}
              onSelect={handleComboSelect.bind(this, 'sw_executable_id')}
              value={store.currentItem.sw_executable_id} />
          </Form.Item>
        </Col>
        <Col {...labelCol} >
        <Form.Item label="Executable">
            <CustomFilesUpload
              fileSingle={store.currentItem.path}
              disabled={!store.currentItem.id}
              func={handleUpdatePath}
              fileName="Download"
              showRemoveIcon={false}
              uploadUrl={`/sw_version/${store.currentItem.id}/${store.currentItem.sw_executable_id}/${store.currentItem.version_number}`}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol}>
        <Form.Item label="Active">
            <Switch onChange={handleSwitchChange.bind(this, 'active')} checked={switchChecked(store.currentItem.active)} />
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label="Latest">
            <Switch onChange={handleSwitchChange.bind(this, 'latest')} checked={switchChecked(store.currentItem.latest)} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  ));
}

const SWVersionList = () => {
  const store = React.useContext(storeContext);

  ContentForm = Form.create({})(ContentForm);

  store.editForm = ContentForm;
  store.columns = columns;
  store.url = '/sw_version';

  return (
    <div>
      <RegistryForm />
    </div>
  )
}

export default SWVersionList;
