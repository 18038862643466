import React, { Component } from 'react';
import { AutoComplete, Spin } from 'antd';
import axios from 'axios'
import configuration from '../config';
import { Bus } from '../helpers/utils';

const { Option } = AutoComplete;

class CustomAutoComplete extends Component {
  state = {
    searchKey: this.props.searchKey || 'name',
    searchValue: this.props.searchValue || 'id',
    path: this.props.path,
    dataSource: [],
    filteredDataSource: [],
    loading: false
  };

  _isMonted = false;

  componentDidMount() {
    this._isMonted = true;
    if (!this.state.path) {
      throw Error('You must provide a path e.g: /product')
    }
    if (this.props.notRequest) {
      return;
    }
    this.getData();
  }

  componentWillUnmount() {
    this._isMonted = false
  }

  getData = async () => {
    const path = this.state.path;

    const url = `${configuration.URL}${path}`;
    const options = {
      headers: {
        token: global.token,
      },
    };
    this.setState({ loading: true });

    let data = await axios.get(url, options).then(r => r.data.result)
      .catch(e => {
        if (e.response && e.response.data && e.response.data.message) {
          Bus.emit('errorStore', { message: e.response.data.message });
        }
      });
    console.log("Data from db ->", data);
    if(this._isMonted) {
      if (!data) {
        return this.setState({ dataSource: [], filteredDataSource: [], loading: false });
      }

      const searchKey = this.state.searchKey;
      const searchValue = this.state.searchValue;

      // eslint-disable-next-line array-callback-return
      data.map((x) => {
        x.key = String(x[searchValue]);
        x.value = String(x[searchKey]);
      });
      console.log('props ->', this.props);
      if (this.props.value) {
        const obj = data.find(v => String(v.key) === String(this.props.value));
        console.log("Obj->", obj);
        if (obj && obj.value)
          this.setState({ value: obj.value });
      }

      if (data.length && this.props.ignoredId) {
        data = data.filter(v => v.id !== this.props.ignoredId);
      }

      this.setState({ dataSource: data, filteredDataSource: data, loading: false });
    }
  }

  onSearch = searchText => {
    const filteredDataSource = this.state.dataSource.filter(x => x.value.toUpperCase().includes(searchText.toUpperCase()));

    this.setState({ filteredDataSource, value: searchText });

    if (!searchText) {
      this.setState({ value: '' });
      this.props.onSelect('');
    }
  };

  onSelect = value => {
    this.setState({ value });
    console.log('customautocomplete', value);
    if (this.props.onSelect) {
      this.props.onSelect(value, (value && this.state.searchValue) ? this.state.dataSource.find(v => v[this.state.searchValue].toString() === value.toString()) : null);
    }
  };

  render() {
    const { filteredDataSource, value = '' } = this.state;
    const children = filteredDataSource.map(x => <Option key={x.key}>{x.value}</Option>);
    return (
      <div>
        <AutoComplete
          value={String(value)}
          style={this.props.style}
          onSelect={this.onSelect}
          onSearch={this.onSearch}
          disabled={this.state.loading || this.props.disabled}
          placeholder={this.props.placeholder || ''}>
          {children}
        </AutoComplete>
        {this.state.loading &&
          <Spin style={{ marginTop: 3.5, marginLeft: -25 }} />
        }
      </div>
    );
  }
}

export default CustomAutoComplete;
