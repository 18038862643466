import React, { useState } from 'react';
import { Button, Row, Col, Form, Input, Radio, notification } from 'antd';
import { activation } from '../../services/deviceActivationService';
import translate from '../../i18n/translate';

const labelCol = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 10,
};

function DeviceActivation() {
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({ history_id: null });

    async function onSubmit(event) {
        setLoading(true);
        event.preventDefault();
        try {
            await activation(state);
            notification.success({ message: 'Device Successfully activated' });
            setState({});
        } catch (error) {
            let message = 'Ocorreu um erro na ativação';
            if (error && error.error) {
                message = error.error
            }
            notification.error({ message });
            const history_id = error.history_id;
            console.log(error);

            setState(prevState => ({ ...prevState, history_id }));
        } finally {
            setLoading(false);
        }
    }

    function isDisabled() {
        return !state || !state.sensor_number || !state.serial_number || !state.initial_counter
            || !state.multiplier;
    }

    return (
        <>
            <div style={{ background: '#f0f2f5', padding: '30px', marginTop: 3 }}>
                <Form layout="vertical" onSubmit={onSubmit}>
                    <Row gutter={24}>
                        <Col {...labelCol} >
                            <Form.Item label={translate('device.sensorNumber')}>
                                <Input
                                    autoComplete="off"
                                    name="sensor_number"
                                    value={state.sensor_number}
                                    onChange={(e) => {
                                        const sensor_number = e.target.value;
                                        setState(prevState => ({ ...prevState, sensor_number: sensor_number.toUpperCase() }))
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col {...labelCol} >
                            <Form.Item label={translate('device.serialNumber')}>
                                <Input value={state.serial_number}
                                    autoComplete="off"
                                    onChange={(e) => {
                                        const serial_number = e.target.value;
                                        setState(prevState => ({ ...prevState, serial_number: serial_number.toUpperCase() }))
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col {...labelCol}>
                            <Form.Item label={translate('device.initialCounter')}>
                                <Input.Group >
                                    <Input type="number" style={{ width: '70%', textAlign: "right" }} suffix={","}
                                        value={state.initial_counter}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            const initial_counter = e.target.value;
                                            setState(prevState => ({ ...prevState, initial_counter }))
                                        }} />
                                    <Input type="number" style={{ width: '30%' }}
                                        value={state.initial_counter_decimal}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            const initial_counter_decimal = e.target.value;
                                            setState(prevState => ({ ...prevState, initial_counter_decimal }))
                                        }} />
                                </Input.Group>
                            </Form.Item>
                        </Col>
                        <Col {...labelCol}>
                            <Form.Item label={translate('device.multiplier')}>
                                <Radio.Group
                                    value={state.multiplier}
                                    onChange={(e) => {
                                        const multiplier = e.target.value;
                                        setState(prevState => ({ ...prevState, multiplier }))
                                    }}  >
                                    <Radio value="0.01">0.01m³</Radio>
                                    <Radio value="0.1">0.1m³</Radio>
                                    <Radio value="1">1m³</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col {...labelCol}>
                            < Button
                                disabled={isDisabled()}
                                htmlType="submit"
                                loading={loading}>{translate('device.startActivation')}</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    )
}

export default DeviceActivation;
