/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from 'react';
import { Table, Button, Col, Row, Form, Input, Select } from 'antd';
import { formatDate } from '../../helpers/date';
import api from '../../helpers/api';
import CustomAutoComplete from '../CustomAutoComplete';
import { storeContext } from '../../context';
import translate from '../../i18n/translate';
const { Option } = Select;
const labelCol = {
    xs: 24,
    sm: 24,
    md: 4,
    lg: 4,
    xl: 4,
};

export default function AlertList({ changePage }) {
    const store = React.useContext(storeContext);
    const [data, setData] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [state, setState] = useState({});
    const [permissions, setPermissions] = useState({});
    const limit = 15;

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: true,
        },
        {
            title: translate('default.title'),
            dataIndex: 'title',
            key: 'title',
            sorter: true,
        }, {
            title: translate('default.content'),
            dataIndex: 'content',
            key: 'content',
        }, {
            title: 'Owner',
            dataIndex: 'owner_user_name',
            key: 'owner_user_name',
            sorter: true,
        }, {
            title: 'Reporter',
            dataIndex: 'report_user_name',
            key: 'report_user_name',
            sorter: true,
        }
        , {
            title: translate('default.type'),
            dataIndex: 'type',
            key: 'type',
            sorter: true,
        }
        , {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: true,
        },
        {
            title: translate('default.createdAt'),
            dataIndex: 'created_at',
            key: 'created_at',
            width: 150,
            align: 'center',
            render: (created_at) => formatDate(created_at),
        },
        {
            title: '',
            align: 'right',
            key: 'action',
            width: 150,
            render: (action) => (
                <>
                    {(permissions.hasProductSN && action.product_installation_date) && <Button icon="eye" type="default" title={translate('product.viewClient')} onClick={() => {
                        changeProductPage(action, true);
                    }} htmlType="button" style={{ marginRight: 10 }} />}

                    {permissions.hasProduct && <Button icon="eye" type="primary" title={translate('product.view')} onClick={() => {
                        changeProductPage(action, false);
                    }} htmlType="button" />}
                </>
            ),
        }
    ];

    function changeProductPage(value, isProductSn) {
        const data = {
            id: value.product_id,
            order_id: value.product_order_id,
            client_id: value.product_client_id,
            device_type_id: value.product_device_type_id,
            firmware_version_id: value.product_firmware_version_id,
            serial: value.product_serial,
            date_manufacture: value.product_date_manufacture,
            date_firmware_update: value.product_date_firmware_update,
            date_test: value.product_date_test,
            radio_serial: value.product_radio_serial,
            serial_cm: value.product_serial_cm,
            product_state: value.product_product_state,
            created_at: value.product_created_at,
            updated_at: value.product_updated_at,
            ready_to_install: value.product_ready_to_install,
            client_sn: value.product_client_sn,
            as_device: value.product_as_device,
            installation_date: value.product_installation_date
        };
        changePage(isProductSn ? 'product_client_sn' : 'product', data);
    }

    function buildUrlParams(stateParams = {}) {
        const offset = stateParams.page ? ((limit * stateParams.page) - limit) : 0;
        let params = `?limit=${limit}&offset=${offset}`;
        if (stateParams.sorter && stateParams.sorter.column) {
            params += `&order=${stateParams.sorter.field} ${stateParams.sorter.order === 'descend' ? 'desc' : 'asc'}`;
        }
        if (stateParams.search) {
            params += `&filter=${stateParams.search}`;
        }
        if (stateParams.status) {
            params += `&status=${stateParams.status}`;
        }
        if (stateParams.owner_user) {
            params += `&owner_user=${stateParams.owner_user}`;
        }
        if (stateParams.type) {
            params += `&type=${stateParams.type}`;
        }
        if (stateParams.problem) {
            params += `&problem=${stateParams.problem}`;
        }
        return params;
    }

    const list = useCallback(async () => {
        try {
            setState(old => ({ ...old, loading: true }));
            const params = buildUrlParams({ ...store.filterAlert });
            const result = await api.get(`/alert/list${params}`);
            if (result) {
                setData(result.data.result);
                setTotalItems(result.data.count || 0);
            }
        } finally {
            setState(old => ({ ...old, loading: false }));
        }
    }, [store.filterAlert]);

    function setFilterAndListAll(params) {
        store.filterAlert = { ...store.filterAlert, ...params, page: 0 };
        list();
    }

    function setPageAndListAll(page, sorter) {
        store.filterAlert = { ...store.filterAlert, page, sorter };
        list();
    }

    useEffect(() => {
        const hasProduct = store.permissions.some(p => p.type === 'w' && p.permission === 'product');
        const hasProductSN = store.permissions.some(p => p.type === 'w' && p.permission === 'product_client_sn');
        setPermissions({ hasProduct, hasProductSN })
        const search = { search: store.filterAlert.search }
        setState(prevState => ({ ...prevState, ...search }));
        setFilterAndListAll(search);
    }, []);

    useEffect(() => {
        const hasProduct = store.permissions.some(p => p.type === 'w' && p.permission === 'product');
        const hasProductSN = store.permissions.some(p => p.type === 'w' && p.permission === 'product_client_sn');
        setPermissions({ hasProduct, hasProductSN })
    }, [store.permissions]);

    return (
        <div style={{ background: '#f0f2f5', padding: '30px', marginTop: 3 }}>
            <Form>
                <Col>
                    <Row>
                        <Col>
                            <Form.Item>
                                <Input.Search
                                    placeholder={translate('default.search')}
                                    onSearch={(search) => setFilterAndListAll({ search })}
                                    value={state.search}
                                    onChange={(e) => {
                                        const search = e.target.value;
                                        setState(prevState => ({ ...prevState, search }));
                                    }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col  {...labelCol} >
                            <Form.Item>
                                <CustomAutoComplete
                                    searchValue={'id'}
                                    searchKey={'name'}
                                    path={'/alert/problem'}
                                    placeholder={translate('alert.select.problem')}
                                    onSelect={(problem) => {
                                        setFilterAndListAll({ problem });
                                    }}
                                    value={store.filterAlert.problem}
                                />
                            </Form.Item>
                        </Col>
                        <Col  {...labelCol} >
                            <Form.Item >
                                <CustomAutoComplete
                                    searchValue='id'
                                    searchKey={'name'}
                                    path={'/account/alert'}
                                    placeholder={translate('alert.select.owner')}
                                    onSelect={(owner_user) => {
                                        setFilterAndListAll({ owner_user });
                                    }}
                                    value={store.filterAlert.owner_user}
                                />
                            </Form.Item>
                        </Col>
                        <Col {...labelCol}>
                            <Form.Item  >
                                <CustomAutoComplete
                                    searchValue={'type'}
                                    searchKey={'type'}
                                    path={'/alert/type'}
                                    placeholder={translate('default.select.type')}
                                    onSelect={(type) => {
                                        setFilterAndListAll({ type });
                                    }}
                                    value={store.filterAlert.type}
                                />
                            </Form.Item>
                        </Col>
                        <Col {...labelCol} >
                            <Form.Item  >
                                <Select defaultValue={null}
                                    value={store.filterAlert.status} style={{ width: '100%' }}
                                    onChange={(status) => setFilterAndListAll({ status })} >
                                    <Option key={-1} value={null}>{translate('default.select.status')}</Option>
                                    <Option value="open">{translate('alert.open')}</Option>
                                    <Option value="in progress">{translate('alert.inProgress')}</Option>
                                    <Option value="suspended">{translate('alert.suspended')}</Option>
                                    <Option value="closed">{translate('alert.closed')}</Option>
                                    <Option value="closed-no action">{translate('alert.closedNoAction')}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Form>
            <Table
                size="small"
                style={{ overflow: 'scroll' }}
                rowKey={(record, index) => index}
                columns={columns}
                dataSource={data}
                loading={state.loading}
                onChange={(value, a, sorter) => setPageAndListAll(value.current, sorter)}
                pagination={{
                    defaultPageSize: limit,
                    pageSize: limit,
                    total: totalItems,
                }} />
        </div>
    )
}
