import moment from 'moment';

const FORMAT = {
    DDMMYYYY_HHMMss: 'DD/MM/YYYY HH:mm:ss'
}

export function isDate(dateObj) {
    return dateObj ? moment(dateObj).isValid() : false;
}

export function parseToMoment(dateString) {
    return isDate(dateString) ? moment(dateString) : null;
}

export function formatDate(date, format = FORMAT.DDMMYYYY_HHMMss) {
    return isDate(date) ? moment(date).format(format) : '';
}
