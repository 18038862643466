import React, { Component, Fragment } from 'react';
import { storeContext } from '../../context';
import { Card } from 'antd';

import './orgchart.css'
import { TreeNode } from 'react-organizational-chart';

class ChartCard extends Component {
    static contextType = storeContext;

    handleItemClick(item) {
        if(item.type){
            this.context.currentItem = item;
            this.props.setActiveTab('1');
        }
    }

    render() {
        return (
            <>
                {this.props.data?.map((item) => {
                    return (
                        <Fragment key={item.id}>
                            <TreeNode label={<Card onClick={() => this.handleItemClick(item)} size='small' style={{display:'inline-block', border:'1px solid grey', borderRadius:5}} className={item.type}>{item.name}</Card>}>
                                {item.children?.length > 0 ? 
                                    <ChartCard data={item.children} setActiveTab={this.props.setActiveTab} showAccount={this.props.showAccount}/> :
                                    null
                                }   
                            </TreeNode>
                        </Fragment>
                    );
                })}
            </>
        );
    }

    // render() {
    //     return (
    //         <>
    //             {this.props.data?.map((item) =>
    //                 {
    //                     if(this.props.showAccount || item.type){
    //                         return (
    //                             <Fragment key={item.id}>
    //                                 <TreeNode label={<Card onClick={() => this.handleItemClick(item)} size='small' style={{display:'inline-block', border:'1px solid grey', borderRadius:5}} className={item.type}>{item.name}</Card>}>
    //                                 {item.children?.length > 0 ? 
    //                                     <ChartCard data={item.children} setActiveTab={this.props.setActiveTab} showAccount={this.props.showAccount}/> :
    //                                     null
    //                                 }   
    //                                 </TreeNode>
    //                             </Fragment>
    //                         );
    //                     }
    //                 }
    //             )}
    //         </>
    //     );
    // }
}

export default ChartCard;