import React, { useState, useCallback, useEffect } from 'react';
import { Table, Col, Row, Form, Input, Button } from 'antd';
import { formatDate } from '../../../helpers/date';
import api from '../../../helpers/api';
import translate from '../../../i18n/translate';
import { storeContext, } from '../../../context';
const labelCol = {
    xs: 24,
    sm: 24,
    md: 22,
    lg: 22,
    xl: 22,
};
const labelColButton = {
    xs: 24,
    sm: 24,
    md: 2,
    lg: 2,
    xl: 2,
};
export default function TableComment({ alertId }) {
    const store = React.useContext(storeContext);
    const [data, setData] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [state, setState] = useState({});
    const limit = 10;

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: true,
        },
        {
            title: translate('user.name'),
            dataIndex: 'account',
            key: 'account',
            sorter: true,
        },
        {
            title: translate('default.comment'),
            dataIndex: 'comment',
            key: 'comment',
            sorter: true,
        },
        {
            title: translate('default.createdAt'),
            dataIndex: 'created_at',
            key: 'created_at',
            width: 200,
            align: 'center',
            sorter: true,
            render: (created_at) => formatDate(created_at),
        },
    ];

    const list = useCallback(async (page = 0, sorter, search) => {
        try {
            setState(old => ({ ...old, loading: true }));
            const offset = page ? ((limit * page) - limit) : 0;
            let params = `${alertId}?limit=${limit}&offset=${offset}`;
            if (sorter && sorter.column) {
                params += `&order=${sorter.field} ${sorter.order === 'descend' ? 'desc' : 'asc'}`;
            }
            if (search) {
                params += `&filter=${search}`;
            }
            const result = await api.get(`/alert/comment/${params}`);
            if (result) {
                setData(result.data.result);
                setTotalItems(result.data.count || 0);
            }
        } finally {
            setState(old => ({ ...old, loading: false }));
        }
    }, [alertId]);

    async function onSubmit(event) {
        event.preventDefault();
        if (!alertId) return;
        setState(old => ({ ...old, loadingButton: true }));
        try {
            const model = { comment: state.comment }
            await api.post(`/alert/comment/${alertId}`, model);
            store.success = { message: translate('default.commentSuccessfullyAdded') };
            setState({ loadingButton: false });
            list(0, null, state.search);
        } catch (error) {
            let message = 'An error has occurred';
            if (error.data && error.data.message) {
                message = error.data.message
            }
            store.error = { message };
            setState(old => ({ ...old, loadingButton: false }));
        }
    }
    useEffect(() => {
        if (alertId) {
            list();
        } else {
            setData([]);
        }
    }, [list, alertId]);

    return (
        <>
            <Form layout="vertical" onSubmit={onSubmit} style={{ marginTop: 4 }}>
                <Row gutter={16}>
                    <Col  {...labelCol}>
                        <Form.Item label={translate('default.comment')} required>
                            <Input.TextArea value={state.comment} disabled={!alertId} rows={4}
                                onChange={(e) => {
                                    const comment = e.target.value;
                                    setState(prevState => ({ ...prevState, comment }))
                                }} />
                        </Form.Item>
                    </Col>
                    <Col {...labelColButton}>
                        <Form.Item>
                            < Button style={{ marginTop: 28 }}
                                disabled={!state.comment || !alertId}
                                htmlType="submit"
                                type="primary"
                                loading={state.loadingButton}>{translate('default.add')} </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Table
                size="small"
                rowKey={(record, index) => index}
                columns={columns}
                dataSource={data}
                loading={state.loading}
                onChange={(value, a, sorter) => list(value.current, sorter, state.search)}
                pagination={{
                    defaultPageSize: limit,
                    pageSize: limit,
                    total: totalItems,
                }} />
        </>
    );
}
