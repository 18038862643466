import React, { useState, useEffect } from 'react'
import api from '../../helpers/api'
import { storeContext } from '../../context';
import { Layout, Table } from 'antd';
import { formatDate } from "../../helpers/date";
import AlertNotAllow from '../AlertNotAllow';
const { Content } = Layout;

export default function ProductProduction() {
  const store = React.useContext(storeContext);
  const [production, setProduction] = useState([]);
  const [permission, setPermission] = useState(true);
  const [page, setPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(true);

  const limit = 15;

  const columns = [
      {
          title: 'Step',
          dataIndex: 'step',
          key: 'step',
      },
      {
          title: 'Retries',
          dataIndex: 'retries',
          key: 'retries',
      },
      {
          title: 'Error Code',
          dataIndex: 'error_code',
          key: 'error_code',
      },
      {
          title: 'Step Start Time',
          dataIndex: 'step_start_time',
          key: 'step_start_time',
          render: (step_start_time) => formatDate(step_start_time),
      },
      {
          title: 'Step End Time',
          dataIndex: 'step_end_time',
          render: (step_end_time) => formatDate(step_end_time),
          key: 'step_end_time',
      },
  ];

  useEffect(() => {
      setLoading(true);
      const offset = page ? ((limit * page) - limit) : 0;
      api.get(`/product/${store.currentItem.id}/production?limit=${limit}&offset=${offset}&order=step_start_time desc`)
          .then(data => {
              setProduction(data.data.result);
              setTotalItems(data.data.count || 0)
          })
          .catch(() => setPermission(false))
          .finally(()=> setLoading(false));
  }, [store.currentItem.id, page]);

  return (
    <Content className="padding-20">
      {permission ? (
        <>
          <div style={{ fontWeight: 'bold', fontSize: 16, marginLeft: 2, marginBottom: 12 }}>Serial CM - {store.currentItem.serial_cm}</div>
          <Table
            size="small"
            rowKey={record => record.key} 
            columns={columns}
            expandedRowRender={record => <pre style={{ margin: 0 }}>{record.step_log}</pre>}
            dataSource={production}
            loading={loading}
            pagination={{
              defaultPageSize: limit,
              pageSize: limit,
              total: totalItems,
              onChange: (value) => setPage(value),
            }}
          />,
        </>) :
        (
          <AlertNotAllow />
        )
      }
    </Content>
  )
}
