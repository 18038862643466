import React from 'react';
import { Checkbox, Form } from 'antd';
import translate from '../i18n/translate';

function ActiveOnlyFilter(props) {

    function onChange(checkedValues) {
        props.onSearchValue({ active: checkedValues.target.checked ? 1 : 0 });
    }

    return (
        <>
            <Form.Item >
                <Checkbox defaultChecked onChange={onChange}>{translate('default.activeOnly')}</Checkbox>
            </Form.Item>
        </>
    )
}
export default ActiveOnlyFilter