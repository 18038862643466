
import React, { useEffect, useCallback, useState } from 'react';
import { useObserver } from "mobx-react";
import { storeContext } from '../../context';
import CustomAutoComplete from '../CustomAutoComplete';
import { formatDate } from '../../helpers/date';
import {
  Form,
  Input,
  Switch,
  Row,
  Col,
  Modal,
  Tag,
  Spin,
  Tooltip,
  Button,
} from 'antd';
import QRCode from 'qrcode.react';
import LogProduct from './LogProduct';
import RegistryForm from '../RegistryForm';
import ProductPhotos from './ProductPhotos';
import SecurityProduct from './SecurityProduct';
import ProductProduction from './ProductProduction';
import StatisticsProduct from './StatisticsProduct';
import ProductFilters from './ProductFilters';
import ProductNsServer from './ProductNsServer';
import ProductAsServer from './ProductAsServer';
import ProductInstallation from './ProductInstallation';
import DataProduct from './DataProduct';
import AlertList from './alert/Alert';
import ProductApplicInfo from './ProductApplicInfo';
import RawData from './RawData';
import api from '../../helpers/api';
import translate from '../../i18n/translate';
import InstallationLogs from './InstallationLogs';
import InstallationTab from './InstallationTab';
import ProductionTab from './ProductionTab';
import DataTab from './DataTab';
import OtherTab from './OtherTab';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
  },
  {
    title: 'Device Type',
    dataIndex: 'device_type_name',
    key: 'device_type_name',
    sorter: true,
  },
  {
    title: 'FW Version',
    dataIndex: 'firmware_version_number',
    key: 'firmware_version_number',
    sorter: true,
  },
  {
    title: 'Serial',
    dataIndex: 'serial',
    key: 'serial',
    sorter: true,
  },
  {
    title: 'Client',
    dataIndex: 'client_name',
    key: 'client_name',
    sorter: true,
  },
  {
    title: 'Serial Radio',
    dataIndex: 'radio_serial',
    key: 'radio_serial',
    sorter: true,
  },
  {
    title: 'Client SN',
    dataIndex: 'client_sn',
    key: 'client_sn',
    sorter: true,
  },
  {
    title: 'Product State',
    dataIndex: 'product_state',
    key: 'client_sn',
    sorter: true,
  },
];

const labelCol = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 10,
};

const labelColLeft = {
  xs: 24,
  sm: 24,
  md: 19,
  lg: 19,
  xl: 17,
};

const labelColRight = {
  xs: 24,
  sm: 24,
  md: 8,
  lg: 8,
  xl: 6,
};


let ContentForm = () => {
  const store = React.useContext(storeContext);
  const [newTag, setNewTag] = useState('');

  function getMessages() {
    api.get(`product/${store.currentItem.id}/messages?only_active=1&on_admin=1`).then(data => {
      if (store.currentItem && data.data.result && data.data.result.length) {
        Modal.info({
          icon: false,
          width: 500,
          title: translate('default.information'),
          content: data.data.result.map((value, index) => <div key={index}><strong>{`${translate('default.message')} #${index + 1}`}</strong>: {value.message}<br /> </div>)
        });
      }
    })
  }

  function getTags() {
    api.get(`/product/${store.currentItem.id}/tags/all`).then(data => {
      if (store.currentItem && data.data.result) {
        store.currentItem.tags = data.data.result;
      }
    }).finally(() => store.currentItem ? store.currentItem.loadingTags = false : null);
  }

  function buildColorTags(tag) {
    if (!tag.active) return null
    return {
      'application': 'blue',
      'user': 'green',
      'system': 'orange',
    }[tag.type] || null;
  }

  function buildDateToolTip(tag) {
    const datesArr = tag.metadata.insert_date;
    if(datesArr.length){
      return datesArr.map(date => <p key={date}>{formatDate(date)}</p>  );
    }
  }

  function tags() {
    const tags = store.currentItem.tags || [];
    if (tags.length) {
      return tags.map(v => <Tooltip title={buildDateToolTip(v)} key={v.id}><Tag color={buildColorTags(v)}>{v.name}</Tag></Tooltip>);
    }
    if (store.currentItem.loadingTags) {
      return <Spin />;
    }
    return translate('default.noTagsRegistered');
  }

  const handleInputChange = (field, event) => {
    store.currentItem[field] = event.target.value
  }

  const handleNewTag = (event) => {
    setNewTag(event.target.value);
  }

  const handleSaveTag = (event) => {
    setNewTag('');
    store.currentItem.loadingTags = true;
    api.post(`/product/${store.currentItem.id}/tags`, {name: newTag, type:'user'}).then(data => {
      getTags();
      console.log(data);
    });
  }

  const handleComboSelect = (field, value) => {
    store.currentItem[field] = value
  }

  const handleSwitchChange = (field, checked) => {
    store.currentItem[field] = checked ? 1 : 0;
  }

  const initialize = useCallback(() => {
    if (store.currentItem.id) {
      store.currentItem.tags = [];
      store.currentItem.loadingTags = true;
      Promise.all([getMessages(), getTags()]);
    }
    // eslint-disable-next-line
  }, [store.currentItem.id]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return useObserver(() => (

    <Form layout="vertical">
      <Row gutter={16}>
        <Col {...labelCol} >
          <Form.Item label="Id">
            <Input value={store.currentItem.id} disabled={true} />
          </Form.Item>
        </Col>
        <Col {...labelCol} >
          <Form.Item label="Created At">
            <Input value={formatDate(store.currentItem.created_at)} disabled={true} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol} >
          <Form.Item label="Updated At">
            <Input value={formatDate(store.currentItem.updated_at)} disabled={true} />
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label="Product State">
            <Input value={store.currentItem.product_state} disabled={true} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="Client">
            <CustomAutoComplete
              searchValue={'id'}
              searchKey={'name'}
              path={'/client'}
              placeholder={'Select the Client'}
              onSelect={handleComboSelect.bind(this, 'client_id')}
              value={store.currentItem.client_id} />
          </Form.Item>
        </Col>
        <Col {...labelCol} >
          <Form.Item label="Device Type">
            <CustomAutoComplete
              searchValue={'id'}
              searchKey={'name'}
              path={'/device_type'}
              placeholder={'Select the Device Type'}
              onSelect={handleComboSelect.bind(this, 'device_type_id')}
              value={store.currentItem.device_type_id} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelColLeft}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={14} style={{ marginLeft: 1 }}>
              <Form.Item label="Serial Radio">
                <Input value={store.currentItem.radio_serial} onChange={handleInputChange.bind(this, 'radio_serial')} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={8} style={{ marginLeft: 4 }}>
              <Form.Item label="Serial">
                <Input value={store.currentItem.serial} onChange={handleInputChange.bind(this, 'serial')} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={14} style={{ marginLeft: 1 }}>
              <Form.Item label="Order">
                <CustomAutoComplete
                  searchValue={'id'}
                  searchKey={'order_description'}
                  path={'/order'}
                  placeholder={'Select the order'}
                  onSelect={handleComboSelect.bind(this, 'order_id')}
                  value={store.currentItem.order_id} disabled={true} />
              </Form.Item>
            </Col>
            <Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={8} style={{ marginLeft: 4 }}>
                <Form.Item label="Ready to Install">
                  <Switch onChange={handleSwitchChange.bind(this, 'ready_to_install')} checked={Boolean(store.currentItem.ready_to_install)} />
                </Form.Item>
              </Col>
            </Col>
          </Row>
          {store.currentItem.tags && <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={14}>
              <Col>
              <Form.Item label="Tags">
                {tags()}
              </Form.Item>
              </Col>
              
              <Col span={12}>
                <Form.Item >
                  <Input value={newTag} onChange={handleNewTag} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item >
                  <Button onClick={handleSaveTag} loading={store.currentItem.loadingTags}>Save</Button>
                </Form.Item>
              </Col>
            </Col>
          </Row>}
        </Col>
        <Col {...labelColRight}>
          <Col xs={24} sm={24} md={14} lg={14} xl={8}>
            {store.currentItem.serial && <Form.Item label="QRCode">
              <QRCode
                value={store.currentItem.serial}
              />
            </Form.Item>}
          </Col>
        </Col>
      </Row>
    </Form>
  ));
}

const ProductList = () => {
  const store = React.useContext(storeContext);

  ContentForm = Form.create({})(ContentForm);

  store.editForm = undefined;
  InstallationTab.tab = 'Installation';
  ProductionTab.tab = 'Production';
  DataTab.tab = 'Data';
  OtherTab.tab = 'Other';
  
  ContentForm.tab = 'Info';
  ProductPhotos.tab = 'default.photos';
  LogProduct.tab = 'Log';
  SecurityProduct.tab = 'Keys';
  ProductProduction.tab = 'Production';
  StatisticsProduct.tab = 'Statistics';
  ProductNsServer.tab = 'NS Server';
  ProductAsServer.tab = 'AS Server';
  ProductInstallation.tab = 'Installation';
  DataProduct.tab = 'App Data';
  AlertList.tab = 'alert.name';
  ProductApplicInfo.tab = 'Applic Info';
  RawData.tab = 'Raw Data';
  InstallationLogs.tab = 'product.installationLogs';
  
  InstallationTab.disabled = true;
  ProductionTab.disabled = true;
  DataTab.disabled = true;
  OtherTab.disabled = true;

  ProductPhotos.disabled = true;
  LogProduct.disabled = true;
  SecurityProduct.disabled = true;
  ProductProduction.disabled = true;
  StatisticsProduct.disabled = true;
  ProductNsServer.disabled = true;
  ProductAsServer.disabled = true;
  ProductInstallation.disabled = true;
  DataProduct.disabled = true;
  AlertList.disabled = true;
  ProductApplicInfo.disabled = true;
  RawData.disabled = true;
  InstallationLogs.disabled = true;

  ContentForm.hideReload = true;
  InstallationTab.hideReload = true;
  ProductionTab.hideReload = true;
  DataTab.hideReload = true;
  OtherTab.hideReload = true;


  store.tabs = [ContentForm, InstallationTab, ProductionTab, SecurityProduct, DataTab, OtherTab, LogProduct, AlertList]

  store.installationTabs = [ProductInstallation, ProductPhotos, ProductApplicInfo, InstallationLogs]

  store.productionTabs = [ProductProduction, StatisticsProduct]

  store.dataTabs = [DataProduct, RawData]

  store.otherTabs = [ProductNsServer, ProductAsServer]

  store.columns = columns;
  store.customFilters = ProductFilters;
  store.url = '/product';
  store.exportUrl = '/product/csv/export';

  useEffect(() => {
    store.customFilters = ProductFilters;
    return () => { store.customFilters = null; }
  }, [store.customFilters]);

  return (
    <div>
      <RegistryForm />
    </div>
  )
}

export default ProductList;
