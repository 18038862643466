
import React, { useEffect } from 'react';
import { useObserver } from "mobx-react";
import { storeContext } from '../context';
import { useHistory } from "react-router-dom";

import Menu from '../components/Menu';

// System Status
import Status from '../components/Status/Status';
import Statistics from '../components/Status/Statistics';

// Installation Validation
import InstallationValidation from '../components/Devices/InstallationValidation';

// Order
import OrderList from '../components/Production/OrderList';

// Product Parts
import LoraoneModuleList from '../components/Production/LoraoneModuleList';
import LavoisierList from '../components/Production/LavoisierList';


// Equipment
import Equipments from '../components/Equipment/EquipmentList';
import ImportEquipments from '../components/Equipment/EquipmentImport';
import EquipmentTypeList from '../components/EquipmentType/EquipmentTypeList'

// Devices
import ProductList from '../components/Devices/ProductList';
import ProductClientSnList from '../components/Devices/ProductClientSnList';
import DeviceTypeList from '../components/Devices/DeviceTypeList';
import FirmwareVersionList from '../components/Devices/FirmwareVersionList';

// Tables
import ClientList from '../components/Tables/ClientList';
import BoardTypeList from '../components/Tables/BoardTypeList';
import DeviceOptionsList from '../components/Tables/DeviceOptionsList';
import FirmwareTypeList from '../components/Tables/FirmwareTypeList';
import AlertProblemList from '../components/Tables/AlertProblemList';

// Software
import SWVersionList from '../components/Software/SWVersionList';
import SWExecutableList from '../components/Software/SWExecutableList';

// Settings
import AccountList from '../components/Settings/AccountList';
import ServiceTokens from '../components/Settings/ServiceTokens';

import ClientCustomData from "../components/Devices/ClientCustomData";

import AccountProfileList from "../components/AccountProfile/AccountProfileList";
import GroupProfileList from "../components/GroupProfile/GroupProfileList";

import DeviceActivation from "../components/activation/DeviceActivation";
import DeviceDeactivation from "../components/activation/DeviceDeactivation";
import AlertList from '../components/alert/AlertList';
import ApplicationList from '../components/Application/ApplicationList';

import translate from '../i18n/translate';
import { Bus } from '../helpers/utils';
import Deploy from '../components/Deploy/DeployList';
import ExternalServicesTypeList from '../components/ExternalServices/ExternalServicesTypeList';
import ExternalServicesList from '../components/ExternalServices/ExternalServices';

import ServiceOrderProblem from '../components/ServiceOrder/ServiceOrderProblem';
import ServiceOrder from '../components/ServiceOrder/ServiceOrder';
import QuickTestPage from '../components/QuickTest/QuickTestPage';
import StatisticsPage from '../components/Tables/StatisticsPage';

const MenuPage = () => {
  const store = React.useContext(storeContext);
  const history = useHistory();

  useEffect(() => {
    Bus.addListener("errorStore", (error) => store.error = error);
    // eslint-disable-next-line
  }, []);

  const changePage = (key, obj) => {
    if (key) {
      store.currentItem = obj;
      store.currentItemBkp = obj;
      const url = key === 'product_client_sn' ? `/product/client_sn/${store.currentItem.id}` : `/product/${store.currentItem.id}`;
      history.push(url);
    }
  }

  const setPermissions = (data) => {
    store.permissions = data || [];
  }

  store.menuItems = [
    {
      title: 'Status',
      icon: 'build',
      subMenuItems: [
        { title: 'System Status', component: (<Status />), url: '/' },
        { title: translate('statistics.name'), component: (<Statistics />), url: '/statistics' },
      ],
    },
    {
      title: translate('product.clientArea'),
      icon: 'customer-service',
      subMenuItems: [
        {
          title: translate('application.name'),
          icon: 'build',
          isAsTypeForm: true,
          subMenuItems: []
        },
        { title: translate('product.client'), component: (<ProductClientSnList />), permission: 'product_client_sn', key: '/product_client_sn', url: '/product/client_sn' },
        { title: translate('product.data'), component: (<ClientCustomData />), permission: 'product_client_sn', url: '/product/client_data' },
      ],
    },
    {
      title: 'Equipment',
      icon: 'hdd',
      subMenuItems: [
        { title: 'Equipments', component: (<Equipments />), permission: 'equipment', url: '/equipment' },
        { title: 'Import Equipments', component: (<ImportEquipments />), permission: 'equipment', url: '/import_equipment' },
      ]
    },
    {
      title: 'Production',
      icon: 'qrcode',
      subMenuItems: [
        { title: 'Order', component: (<OrderList />), permission: 'order', url: '/order' },
        { title: 'Products', component: (<ProductList />), permission: 'product', key: 'product', url: '/product' },
        { title: 'LoraONE', component: (<LoraoneModuleList />), permission: 'loraone', url: '/loraone_module' },
        { title: 'Lavoisier', component: (<LavoisierList />), permission: 'lavoisier', url: '/lavoisier' },
      ],
    },
    {
      title: 'Installations',
      icon: 'rise',
      subMenuItems: [
        { title: 'Service Order', component: (<ServiceOrder />), permission: 'equipment', url: '/service_order' },
        { title: 'Validation', component: (<InstallationValidation />), permission: 'product_client_sn', url: '/product_installation/validation' },
        { title: translate('quickTest.name'), component: (<QuickTestPage />), permission: 'quick_test', url: '/quick_test' },
      ]
    },
    {
      title: translate('alert.name'),
      icon: 'alert',
      subMenuItems: [
        { title: translate('alert.list'), component: (<AlertList changePage={changePage} />), permission: 'alert', url: '/alert_list' },
      ],
    },
    {
      title: translate('device.activation'),
      icon: 'api',
      subMenuItems: [
        { title: translate('device.activation'), component: (<DeviceActivation />), permission: 'device_activation', url: '/device_activation' },
        { title: translate('device.deactivation'), component: (<DeviceDeactivation />), permission: 'device_activation', url: '/device_deactivation' },
      ],
    },
    {
      title: 'Tables',
      icon: 'user',
      subMenuItems: [
        {
          title: 'Devices',
          icon: 'build',
          subMenuItems: [
            { title: 'Device Types', component: (<DeviceTypeList />), permission: 'device_type', url: '/device_type' },
            { title: 'Device Options', component: (<DeviceOptionsList />), permission: 'device_options', url: '/device_options' },
          ],
        },
        {
          title: 'Firmware',
          icon: 'rocket',
          subMenuItems: [
            { title: 'Firmware Type', component: (<FirmwareTypeList />), permission: 'firmware_type', url: '/firmware_type' },
            { title: 'Firmware Versions', component: (<FirmwareVersionList />), permission: 'firmware_version', url: '/firmware_version' },
          ],
        },
        {
          title: 'Software',
          icon: 'windows',
          subMenuItems: [
            { title: 'Software Executable', component: (<SWExecutableList />), permission: 'sw_executable', url: '/sw_executable' },
            { title: 'Software Version', component: (<SWVersionList />), permission: 'sw_version', url: '/sw_version' },
          ],
        },
        {
          title: 'Others',
          icon: 'switcher',
          subMenuItems: [
            { title: 'Board Types', component: (<BoardTypeList />), permission: 'board_type', url: '/board_type' },
            { title: translate('externalServicesType.name'), component: (<ExternalServicesTypeList />), permission: 'external_services_type', url: '/external_services_type' },
            { title: 'Equipment Types', component: (<EquipmentTypeList />), permission: 'equipment', url: '/equipment_type' },
            { title: 'Service Order Problems', component: (<ServiceOrderProblem />), permission: 'equipment', url: '/service_order_problem' },
          ],
        },
        { title: 'Clients', component: (<ClientList />), permission: 'client', url: '/client' },
        { title: translate('managementPages.name'), component: (<StatisticsPage />), permission: 'statistic_page', url: '/statistic_page' },
        { title: 'Alert Problem', component: (<AlertProblemList />), permission: 'alert_problem', url: '/alert_problem' },
        { title: translate('application.name'), component: (<ApplicationList />), permission: 'application', url: '/application' },
        { title: "Deploy", component: (<Deploy />), permission: 'deploy', url: '/deploy' },
        { title: translate('externalServices.name'), component: (<ExternalServicesList />), permission: 'external_services', url: '/external_services' },
      ],
    },
    {
      title: 'Settings',
      icon: 'setting',
      subMenuItems: [
        { title: translate('groupProfile.name'), component: (<GroupProfileList />), permission: 'group_profile', url: '/group_profile' },
        { title: translate('accountProfile.name'), component: (<AccountProfileList />), permission: 'account_profile', url: '/account_profile' },
        { title: 'Accounts', component: (<AccountList />), permission: 'account', url: '/account' },
        { title: 'Service Tokens', component: (<ServiceTokens />), permission: 'account', url: '/service_tokens' },
      ],
    }
  ]


  const onItemClick = () => {
    store.currentItem = undefined;
    store.exportUrl = undefined;
  }

  return useObserver(() => (
    // <Menu menuItems={store.menuItems} onItemClick={onItemClick} setPermissions={setPermissions}/>
    <Menu onItemClick={onItemClick} setPermissions={setPermissions} />
  ));
}

export default MenuPage;
