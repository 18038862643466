import React, { useEffect } from 'react';
import { Button } from 'antd';
import translate from '../../i18n/translate';
import { storeContext } from '../../context';
import confirm from "../Confirm/Confirm";
import configuration from '../../config';
import axios from 'axios';

export default function GenerateTokenButton({ email }) {
    const store = React.useContext(storeContext);
    const [state, setState] = React.useState({});
    const [permissions, setPermissions] = React.useState({});

    async function resetPassword() {
        try {
            setState(old => ({ ...old, loadingButton: true }));
            const url = `${configuration.URL}/account/request_password_reset`;
            const data = {
                email,
            };
            await axios.post(url, data).then(r => console.log(r.data));
        } finally {
            setState(old => ({ ...old, loadingButton: false }));
        }
    }

    useEffect(() => {
        const hasAdminLogin = store.permissions.some(p => p.type === 'r' && p.permission === 'admin_login');
        setPermissions({ hasAdminLogin });
    }, [store.permissions]);

    return (
        <>
            {permissions.hasAdminLogin &&
                <Button type="primary"
                    loading={state.loadingButton}
                    title={translate('user.resetPassword')} onClick={() => confirm({ func: () => resetPassword(), content: translate('user.messageResetPassword') })} htmlType="button">
                    {translate('user.resetPassword')}
                </Button>
            }
        </>
    )
}
