import translate from "../i18n/translate";

export function isAuthorized(error) {
    return (error.response && error.response.data && error.response.data.code !== 401);
}

export function isMessageError(error) {
    return (error.response && error.response.data && error.response.data.message);
}

export function getError(e, store) {
    const authorized = isAuthorized(e);
    if (!authorized) {
        return false;
    }
    if (e.response && e.response.data && e.response.data.message) {
        store.error = { message: e.response.data.message }
    } else {
        store.error = { message: translate('default.errorOccurred') }
    }
    return true;
}