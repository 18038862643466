
import React from 'react';
import { useObserver } from "mobx-react";
import { storeContext } from '../../context';
import {
  Form,
  Input,
  Row,
  Col,
} from 'antd';
import RegistryForm from '../RegistryForm';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name',
  },
];

const labelCol = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 10,
};

let ContentForm = () => {
  const store = React.useContext(storeContext);

  const handleChange = (field, event) => {
    store.currentItem[field] = event.target.value;
  }
  
  return useObserver(() => (
    <Form layout="vertical">
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="ID">
            <Input value={store.currentItem.id}  disabled={true}/>
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label="Nome">
            <Input value={store.currentItem.name} onChange={handleChange.bind(this, 'name')} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  ));
}

const ServiceOrderProblems = () => {
  const store = React.useContext(storeContext);

  ContentForm = Form.create({})(ContentForm);

  store.editForm = ContentForm;
  store.columns = columns;
  store.url = '/service_order_problem';

  return (
    <div>
      <RegistryForm />
    </div>
  )
}

export default ServiceOrderProblems;