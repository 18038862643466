
import React, { useState, useCallback, useEffect } from 'react';
import { storeContext, } from '../../../context';

import {
    Form,
    Input,
    Row,
    Col,
    Layout,
    Table,
    Button,
    Menu,
    Dropdown,
    Icon
} from 'antd';
import { isAuthorized } from '../../../helpers/verifyStatusErrorResponse';
import api from '../../../helpers/api';
import { formatDate } from '../../../helpers/date';
import CustomAutoComplete from '../../CustomAutoComplete';
import ModalStatus from './ModalStatus';
import TableComment from './TableComment';
import translate from '../../../i18n/translate';
import AlertNotAllow from '../../AlertNotAllow';
const { Content } = Layout;


const labelCol = {
    xs: 24,
    sm: 24,
    md: 13,
    lg: 13,
    xl: 13,
};

const labelColForm = {
    xs: 24,
    sm: 24,
    md: 10,
    lg: 10,
    xl: 10,
};

const labelColGrid = {
    xs: 24,
    sm: 24,
    md: 14,
    lg: 13,
    xl: 13,
};

const labelColButton = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 8,
    xl: 4,
};

export default function AlertList() {
    const store = React.useContext(storeContext);
    const [data, setData] = useState([]);
    const [key, setKey] = useState([]);
    const [permission, setPermission] = useState(true);
    const [totalItems, setTotalItems] = useState(0);
    const [state, setState] = useState({ id: null });
    const [visibleModal, setVisibleModal] = useState({});
    const [buttonsModal, setButtonsModal] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const limit = 10;


    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: true,
        },
        {
            title: translate('default.title'),
            dataIndex: 'title',
            key: 'title',
            sorter: true,
        }, {
            title: translate('default.content'),
            dataIndex: 'content',
            key: 'content',
        }, {
            title: translate('user.owner'),
            dataIndex: "owner_user_name",
            key: 'owner_user_name',
            sorter: true,
        }, {
            title: translate('user.report'),
            dataIndex: 'report_user_name',
            key: 'report_user_name',
            sorter: true,
        }
        , {
            title: translate('default.type'),
            dataIndex: 'type',
            key: 'type',
            sorter: true,
        }
        , {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: true,
        },
        {
            title: translate('default.createdAt'),
            dataIndex: 'created_at',
            key: 'created_at',
            width: 200,
            align: 'center',
            render: (created_at) => formatDate(created_at),
        },
        {
            title: '',
            align: 'right',
            key: 'action',
            width: 200,
            render: (action) => (
                <>
                    <Button disabled={action.status !== 'open'} icon="warning" type="default" title={translate('alert.ticket.start')} onClick={() => {
                        startTicket(action);
                    }} htmlType="button" />
                    <Dropdown disabled={disabledButtonDropdown(action.status)} overlay=
                        {
                            <Menu >
                                <Menu.Item key="1" onClick={() => closeTicket(action)}>{translate('alert.ticket.close')}</Menu.Item>
                                <Menu.Item key="3" onClick={() => {
                                    setState(old => ({ ...old, ...action }));
                                    setKey(old => old + 1);
                                }}>{translate('alert.ticket.edit')}</Menu.Item>
                                {action.status !== 'suspended' && <Menu.Item key="4" onClick={() => suspendTicket(action)}>
                                    {translate('alert.ticket.suspend')}</Menu.Item>}
                            </Menu>
                        }>
                        <Button style={{ marginLeft: 10 }}> {translate('default.actions')} <Icon type="down" /> </Button>
                    </Dropdown>
                </>
            ),
        }
    ];

    const list = useCallback(async (page = 0, sorter, search) => {
        try {
            setPageNumber(page);
            setState(old => ({ ...old, loading: true }));
            const offset = page ? ((limit * page) - limit) : 0;
            let params = `${store.currentItem.id}?limit=${limit}&offset=${offset}`;
            if (sorter && sorter.column) {
                params += `&order=${sorter.field} ${sorter.order === 'descend' ? 'desc' : 'asc'}`;
            }
            if (search) {
                params += `&filter=${search}`;
            }
            const result = await api.get(`/alert/list/${params}`);
            if (result) {
                setData(result.data.result);
                setTotalItems(result.data.count || 0);
            }
        } catch (error) {
            setPermission(isAuthorized(error));
        } finally {
            setState(old => ({ ...old, loading: false }));
        }
    }, [store.currentItem.id]);

    function disabledButtonDropdown(status) {
        return ['closed-no action', 'closed'].includes(status);
    }

    function startTicket(value) {
        if (disabledButtonDropdown(value.status) || value.status !== 'open') return;
        const start = async () => {
            try {
                setVisibleModal({});
                const result = await api.put(`/alert/status/start/${value.id}`);
                store.success = { message: result.data.message };
                setState(old => ({ ...old, ...value, owner_user: result.data.result.accountId }));
                setKey(old => old + 1);
                list(pageNumber, null, state.search);
            } catch (error) {
                const message = 'An error has occurred';
                store.error = { message };
            }
        }
        setButtonsModal([
            <Button type="primary" onClick={() => start()} htmlType="button">Start Ticket</Button>
        ]);
        setVisibleModal(value);
    }

    function suspendTicket(value) {
        if (disabledButtonDropdown(value.status)) return;
        const suspend = async () => {
            try {
                setVisibleModal({});
                const result = await api.put(`/alert/status/suspend/${value.id}`);
                store.success = { message: result.data.message };
                setState(old => ({ ...old, ...value, owner_user: result.data.result.accountId }));
                setKey(old => old + 1);
                list(pageNumber, null, state.search);
            } catch (error) {
                const message = 'An error has occurred';
                store.error = { message };
            }
        }
        setButtonsModal([
            <Button type="primary" onClick={() => suspend()} htmlType="button">Suspend Ticket</Button>
        ]);
        setVisibleModal(value);
    }

    function closeTicket(value) {
        if (disabledButtonDropdown(value.status)) return;
        const close = async (status) => {
            try {
                setVisibleModal({});
                const result = await api.put(`/alert/status/close/${value.id}`, { status });
                if (Number(state.id) === Number(value.id)) {
                    setState(() => ({ loadingButton: false, search: state.search }));
                    setKey(old => old + 1);
                }
                store.success = { message: result.data.message };
                list(pageNumber, null, state.search);
            } catch (error) {
                const message = 'An error has occurred';
                store.error = { message };
            }
        }
        setButtonsModal([
            <Button key="1" disabled={value.status === 'closed'} type="primary" onClick={() => close('closed')} htmlType="button">Close</Button>,
            <Button key="2" disabled={value.status === 'closed-no action'} type="dashed" onClick={() => close('closed-no action')} htmlType="button">Close - No Action</Button>
        ]);
        setVisibleModal(value);
    }

    async function onSubmit(event) {
        event.preventDefault();
        setState(old => ({ ...old, loadingButton: true }));
        try {
            const method = state.id ? 'put' : 'post';
            const id = state.id ? state.id : store.currentItem.id;
            const model = {
                id,
                title: state.title,
                owner_user: state.owner_user,
                content: state.content,
                type: state.type,
                problem_id: state.problem_id
            };
            const result = await api[method](`/alert/${id}`, model);
            store.success = { message: result.data.message };
            setState(old => ({ ...old, id: result.data.result.id, loadingButton: false, search: state.search }));
            list(pageNumber, null, state.search);
        } catch (error) {
            let message = 'An error has occurred';
            if (error && error.error) {
                message = error.error
            }
            store.error = { message };
            setState(old => ({ ...old, loadingButton: false }));
        }
    }

    function reset() {
        setState(() => ({ loadingButton: false, search: state.search }));
        setKey(old => old + 1);
    }

    function changeOrderPagination(page = 0, order, search) {
        setState(old => ({ ...old, order }));
        list(page, order, search);
    }

    useEffect(() => {
        list();
    }, [list]);

    function isDisabled() {
        return !state || !state.title || !state.type || !state.owner_user || !state.problem_id;
    }

    return (
        <Content className="padding-20">
            {permission ? (
                <>
                    <Row gutter={8}>
                        <Col {...labelColForm}>
                            <Form layout="vertical" onSubmit={onSubmit} className="alert-table" >
                                <Col>
                                    <Form.Item label="Id">
                                        <Input value={state.id} disabled={true} />
                                    </Form.Item>
                                </Col>
                                <Col className="margin-bottom-less-alert">
                                    <Form.Item label={translate('default.title')} required>
                                        <Input value={state.title}
                                            onChange={(e) => {
                                                const title = e.target.value;
                                                setState(prevState => ({ ...prevState, title }))
                                            }} />
                                    </Form.Item>
                                </Col>
                                <Col className="margin-bottom-less-alert">
                                    <Form.Item label={translate('user.owner')} required>
                                        <CustomAutoComplete
                                            key={key}
                                            searchValue='id'
                                            searchKey={'name'}
                                            path={'/account/alert'}
                                            placeholder={translate('user.select.owner')}
                                            onSelect={(owner_user) => {
                                                setState(prevState => ({ ...prevState, owner_user }))
                                            }}
                                            value={state.owner_user} />
                                    </Form.Item>
                                </Col>
                                <Col className="margin-bottom-less-alert">
                                    <Form.Item label={translate('default.type')} required>
                                        <CustomAutoComplete
                                            key={key}
                                            searchValue={'type'}
                                            searchKey={'type'}
                                            path={'/alert/type'}
                                            placeholder={translate('default.select.type')}
                                            onSelect={(type) => {
                                                setState(prevState => ({ ...prevState, type }))
                                            }}
                                            value={state.type} />
                                    </Form.Item>
                                </Col>
                                <Col className="margin-bottom-less-alert">
                                    <Form.Item label={translate('default.problem')} required>
                                        <CustomAutoComplete
                                            key={key}
                                            searchValue={'id'}
                                            searchKey={'name'}
                                            path={'/alert/problem'}
                                            placeholder={'Select the problem'}
                                            onSelect={(problem_id) => {
                                                setState(prevState => ({ ...prevState, problem_id }))
                                            }}
                                            value={state.problem_id} />
                                    </Form.Item>
                                </Col>
                                <Col className="margin-bottom-less-alert">
                                    <Form.Item label={translate('default.content')}>
                                        <Input.TextArea rows={3} value={state.content}
                                            onChange={(e) => {
                                                const content = e.target.value;
                                                setState(prevState => ({ ...prevState, content }))
                                            }} />
                                    </Form.Item>
                                </Col>
                                <Col {...labelColButton}>
                                    <Form.Item >
                                        < Button
                                            disabled={isDisabled()}
                                            htmlType="submit"
                                            loading={state.loadingButton}>{translate('default.save')}</Button>
                                    </Form.Item>
                                </Col>
                                <Col {...labelColButton}>
                                    <Form.Item>
                                        <Button onClick={() => reset()}
                                            type="danger"
                                            loading={state.loadingButton}>{translate('default.reset')}</Button>
                                    </Form.Item>
                                </Col>
                            </Form>
                        </Col>
                        <Col {...labelColGrid}>
                            <TableComment alertId={state.id} />
                        </Col>
                    </Row>
                    <Row gutter={8}>
                        <Col {...labelCol}>
                            <Form.Item>
                                <Input.Search
                                    placeholder={translate('default.search')}
                                    onSearch={() => list(0, state.order, state.search)}
                                    value={state.search}
                                    onChange={(e) => {
                                        const search = e.target.value;
                                        setState(prevState => ({ ...prevState, search }))
                                    }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Table
                        size="small"
                        rowKey={(record, index) => index}
                        columns={columns}
                        dataSource={data}
                        loading={state.loading}
                        onChange={(value, a, sorter) => changeOrderPagination(value.current, sorter, state.search)}
                        pagination={{
                            defaultPageSize: limit,
                            pageSize: limit,
                            total: totalItems,
                        }} />
                    <ModalStatus alert={visibleModal} buttons={buttonsModal} setAlert={setVisibleModal} />,
                </>) :
                (
                    <AlertNotAllow />
                )}
        </Content>
    );
}
