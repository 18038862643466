import React, { useEffect, useState } from 'react'
import { storeContext } from '../../context';
import CustomTree from '../CustomTree';
import PermissionsGroup from './PermissionsGroup';
import { Col, Row } from "antd";
import api from '../../helpers/api';


export default function Permission() {
    const store = React.useContext(storeContext);
    const [permissions, setPermissios] = useState([]);
    const [filteredTree, setFilteredTree] = useState(null);
    const [checkedKeys, setCheckedKeys] = useState([]);
    const [loading, setLoading] = useState(false);

    async function handleSubmit(e) {
        e.preventDefault()
        setLoading(true);
        try {
            await api.put(`/account/permissions/${store.currentItem.id}`, filteredTree);
            store.success = { message: 'Permission saved successfully' }
        } catch (error) {
            store.error = { message: 'There was an error saving permission' }
        }
        setLoading(false);
    }

    useEffect(() => {
        Promise.all([
            api.get('/sys_permissions'),
            api.get(`/account/${store.currentItem.id}/permissions`),
            api.get('/permission/keys')
        ]).then(data => {
            if (data[2].data.result) {
                buildItems(data[0].data.result, data[1].data.result, data[2].data.result);
            }
        });
    }, [store.currentItem.id])

    function buildItems(sysPermissions = [], permissionsAccount = [], permissionKeys = []) {
        const checked = [];
        const halfCheckedKeys = ['0-0'];
        sysPermissions = sysPermissions.filter(el => el.name !== '*');
        sysPermissions.forEach((element, index) => {
            let flag = false;
            element.key = `${index}-1`;
            element.children = [];
            permissionKeys.forEach((key, idx) => {
                const find = permissionsAccount.find(p => p.permission === element.name && p.type === key.type);
                if (find) {
                    flag = true;
                    checked.push(`${index}-1-${idx + 1}`);
                }
                element.children.push({
                    key: `${index}-1-${idx + 1}`,
                    name: key.info,
                    type: key.type,
                    id: find ? find.id : null,
                });
            });
            if (flag) {
                halfCheckedKeys.push(`${index}-1`)
            }
        })
        setPermissios(sysPermissions)
        setCheckedKeys({ checkedKeys: checked, halfCheckedKeys });
    }

    function setItemsGroup(params) {
        setCheckedKeys([]);
        const checked = [];
        const halfCheckedKeys = [];
        let result = [];
        params.forEach(p => {
            result = [...result, ...p.children]
        });
        permissions.forEach(element => {
            const sys = result.filter(e => e.sys_permissions === element.name);
            if (sys && sys.length) {
                halfCheckedKeys.push(element.key)
                element.children.forEach(children => {
                    const sys_permissions = sys.find(e => e.type === children.type);
                    if (sys_permissions) {
                        checked.push(children.key);
                    }
                });
            }
        });
        setCheckedKeys({ checkedKeys: checked, halfCheckedKeys });
    }

    return (
        <>
            <Row>
                <Col className="gutter-row" span={8}>
                    <PermissionsGroup func={setItemsGroup} />
                </Col>
                <Col className="gutter-row" span={6}>
                    <CustomTree
                        data={permissions}
                        onSubmit={handleSubmit}
                        setFilteredTree={setFilteredTree}
                        loading={loading}
                        disabled={!filteredTree}
                        checkedKeys={checkedKeys}
                        labelButton="Update Permissions"
                    />
                </Col>
            </Row>

        </>
    )
}