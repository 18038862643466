
import React, { useEffect } from 'react';
import { useObserver } from "mobx-react";
import { storeContext } from '../../context';
import {
    Form,
    Input,
    Row,
    Col,
} from 'antd';

import RegistryForm from '../RegistryForm';
import translate from '../../i18n/translate';

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: translate('default.name'),
        dataIndex: 'name',
        key: 'name',
        sorter: true,
    },
    {
        title: translate('application.name'),
        dataIndex: 'application',
        key: 'application',
        sorter: true,
    },
    {
        title: translate('asType.name'),
        dataIndex: 'type',
        key: 'type',
        sorter: true,
    },
    {
        title: translate('asLevel.name'),
        dataIndex: 'level',
        key: 'level',
        sorter: true,
    },
];

const labelCol = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 10,
};
const typesNumber = [
    'decimal',
    'int',
    'interger',
    'number',
]

const ApplicationAsFormList = ({ valuesForm = {} }) => {
    const store = React.useContext(storeContext);
    const newValuesForm = [];

    if (valuesForm && valuesForm.as_form) {
        for (var i = 0; i < valuesForm.as_form.length; i = i + 2) {
            newValuesForm.push(valuesForm.as_form.slice(i, i + 2));
        }
    }

    function getType(value) {
        return typesNumber.includes(value) ? 'number' : 'text';
    }

    let ContentForm = () => {
        useEffect(() => {
            store.hideDeleteButton = true;
            if (store.currentItem.id) {
                store.disableButtonSave = true;
            }
            return () => {
                store.disableButtonSave = false;
                store.hideDeleteButton = false;
            }
            // eslint-disable-next-line
        }, [store.currentItem.id]);

        const handleChange = (field, event) => {
            store.currentItem[field] = event.target.value
        }

        const inputCol = (value, index) => {
            return (
                <Col {...labelCol} key={index}>
                    <Form.Item label={value.label} required={value.required}>
                        <Input value={store.currentItem[value.key]} type={getType(value.type)}
                            onChange={handleChange.bind(this, value.key)} />
                    </Form.Item>
                </Col>
            );
        }

        const inputsDeafult = () => {
            return (
                <>
                    <Col {...labelCol}>
                        <Form.Item label="Id" >
                            <Input value={store.currentItem.id} onChange={handleChange.bind(this, 'id')} type="text" disabled />
                        </Form.Item>
                    </Col>
                    <Col {...labelCol}>
                        <Form.Item label={translate('default.name')} required={true}>
                            <Input value={store.currentItem.name} onChange={handleChange.bind(this, 'name')} type="text" />
                        </Form.Item>
                    </Col>
                </>
            );
        }

        return useObserver(() => (
            <Form layout="vertical">
                {!newValuesForm.length &&
                    <>
                        <Row gutter={16}>
                            {inputsDeafult()}
                        </Row>
                    </>
                }
                {newValuesForm.map((value, index) => (
                    <Row gutter={16} key={index}>
                         {inputsDeafult()}
                        {value.map((v, idex) => inputCol(v, idex))}
                    </Row>
                ))}
            </Form>
        ));
    }

    ContentForm = Form.create({})(ContentForm);

    store.editForm = ContentForm;
    store.columns = columns;
    store.url = `/as_device_mapping/as_form/${valuesForm.id}`;

    return (
        <div>
            <RegistryForm />
        </div>
    )
}

export default ApplicationAsFormList;
