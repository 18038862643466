import React, { Component } from 'react';
import { Table, Input, Button, Col, Row } from 'antd';
import axios from 'axios'
import configuration from '../config';
import localforage from 'localforage';
import api from "../helpers/api";
import translate from '../i18n/translate';

const { Search } = Input;

class CustomTable extends Component {
  state = {
    path: this.props.path,
    dataSource: this.props.dataSource || [],
    loading: false,
    pagination: { pageSize: 15 },
    searchText: '',
    searchedColumn: '',
    params: this.props.filterDefault || {},
    filter: this.props.filter,
    sorter: this.props.sorter || {}
  };

  _isMonted = false;

  async componentDidMount() {
    this._isMonted = true;
    if (!this.state.path && !this.props.dataSource) {
      throw Error('You must provide a path e.g: /product or a dataSource')
    }

    if (!this.props.columns) {
      throw Error('You must provide the columns')
    }
    this.fetch({ filters: this.state.filter, sortField: this.state.sorter.field, sortOrder: this.state.sorter.order, });
  }

  componentWillUnmount() {
    this._isMonted = false
  }

  onSearch = async (search) => {
    await this.setState({
      filter: search,
    });
    return this.fetch({
      filters: search,
    });
  }

  handleTableChange = (pagination, filters, sorter) => {
    console.log('fetching data!')
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    console.log('sorter -->', sorter.field)
    return this.fetch({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      filters: this.state.filter,
    });
  };


  fetch = async ({
    results, page, sortField, sortOrder, filters
  }) => {
    const pagination = { ...this.state.pagination };
    const path = this.state.path;

    const url = `${configuration.URL}${path}`;
    const limit = pagination.pageSize;
    const offset = page ? ((limit * page) - limit) : 0;
    const options = {
      headers: {
        token: global.token,
      },
      params: {
        limit,
        offset,
        ...this.state.params,
        filter: filters,
        order: `${sortField} ${sortOrder === 'descend' ? 'desc' : 'asc'}`
      }
    };
    this.setState({ loading: true });
    let data = await axios.get(url, options).then(r => r.data).catch(async e => {
      if (e.message === 'Request failed with status code 401') {
        await this.setState({
          visible: false,
        });
        await localforage.removeItem('token');
        await localforage.removeItem('hasAccountProfileId');
        window.location.reload(true);
      }
    });

    pagination.total = data ? data.count : 0;

    if(this._isMonted){
      this.setState({
        loading: false,
        dataSource: data.result,
        pagination,
      });
      
      this.setState({ dataSource: data.result, loading: false });
    }    
  }

  setStateValue = (value) => {
    this.setState({ params: value });
  }

  onSearchValue = async (value) => {
    await this.setState({ params: value });
    return this.fetch({
      filters: this.state.filter,
      sortField: this.state.sorter.field, 
      sortOrder: this.state.sorter.order
    });
  }

  exportCsv = async () => {
    const params = {
      filter: this.state.filter,
      ...this.state.params,
    };
    try {
      this.setState({ exportLoading: true });
      const data = await api.get(this.props.exportUrl, { params });
      if (data.data && data.data.result) {
        const pom = document.createElement('a');
        pom.href = data.data.result;
        pom.setAttribute('download', data.data.result);
        pom.click();
      }
    } finally {
      this.setState({ exportLoading: false });
    }
  }

  render() {
    const { customFilters, exportUrl } = this.props;
    return (
      <div>
        <Row>
          <Col md={20} sm={24} xs={24}>
            {customFilters && React.createElement(customFilters, this)}
          </Col>
          <Col md={4} sm={24} xs={24} className="align-right">
            {exportUrl && <Button type="dashed" style={{ marginBottom: 5 }} onClick={this.exportCsv} loading={this.state.exportLoading}>
              {translate('export.csv')}
            </Button>
            }
          </Col>
        </Row>
        <Search
          autoFocus='true'
          placeholder={translate('default.search')}
          value={this.state.filter}
          onSearch={search => { this.onSearch(search) }}
          onChange={e => { this.setState({ filter: e.target.value }) }}
          style={{ flex: 1 }}
        />
        <Table
          size="small"
          style={this.props.style || { minHeight: 700, overflow: 'scroll' }}
          columns={this.props.columns}
          dataSource={this.state.dataSource}
          loading={this.state.loading}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          onRow={this.props.onRow}
          rowKey={(record, index) => index}
        />
      </div>
    );
  }
}

export default CustomTable;
