import React, { useState } from 'react';
import CustomAutoComplete from '../CustomAutoComplete';
import { Button, Row, Col, Form, Upload, Icon, notification } from 'antd';
import api from '../../helpers/api';
import translate from '../../i18n/translate';

const labelCol = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 10,
};
const ACCEPT = 'text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

function ClientCustomData() {
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({});

    async function onSubmit(event) {
        setLoading(true);
        event.preventDefault();
        try {
            console.log(state.selectedFile);
            const headers = { 'Content-Type': ACCEPT };
            await api.put(`client_custom_data/import/${state.client}/${state.application}`, state.selectedFile, { headers });
            notification.success({ message: 'Uploaded CSV with Successfully' });
            setState({});
        } catch (error) {
            let message = 'CSV upload error';
            if (error.response && error.response.data && error.response.data.message) {
                message = error.response.data.message
            }
            notification.error({ message });
        } finally {
            setLoading(false);
        }
    }

    function onChange(info) {
        if (info.file && info.file.originFileObj) {
            setState(old => ({
                ...old,
                selectedFile: info.file.originFileObj,
                selectedFileList: [info.file]
            }));
        }
    }

    function isDisabled() {
        return !state || !state.client || !state.application || !state.selectedFile;
    }

    return (
        <>
            <div style={{ background: '#ECECEC', padding: '30px', marginTop: 3 }}>
                <Form layout="vertical" onSubmit={onSubmit}>
                    <Row gutter={24}>
                        <Col {...labelCol}>
                            <Form.Item label={translate('client.name')}>
                                <CustomAutoComplete
                                    searchValue={'id'}
                                    searchKey={'name'}
                                    path={'/client'}
                                    placeholder={translate('client.select.name')}
                                    onSelect={(value) => setState(old => ({ ...old, client: value }))}
                                    value={state.client} />
                            </Form.Item>
                        </Col>
                        <Col {...labelCol}>
                            <Form.Item label={translate('application.name')}>
                                <CustomAutoComplete
                                    searchValue={'id'}
                                    searchKey={'name'}
                                    path={'/application'}
                                    placeholder={translate('application.select.name')}
                                    onSelect={(value) => setState(old => ({ ...old, application: value }))}
                                    value={state.application} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col {...labelCol}>
                            <Form.Item label={translate('file.name')}>
                                <Upload
                                    accept={ACCEPT}
                                    onChange={onChange}
                                    customRequest={({ onSuccess }) => setTimeout(() => onSuccess("ok"), 0)}
                                    fileList={state.selectedFileList}>
                                    <Button><Icon type="upload" />{translate('file.select.upload')}</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col {...labelCol}>
                            < Button
                                disabled={isDisabled()}
                                htmlType="submit"
                                loading={loading}>{translate('file.start')}</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    )
}

export default ClientCustomData;
