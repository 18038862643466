import React, { useState } from 'react';
import { Button, Col, DatePicker, Form, Row, notification } from 'antd';
import translate from '../../i18n/translate';
import api from "../../helpers/api";
// import moment from 'moment';

const { RangePicker } = DatePicker;
const labelCol = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 10,
};

function QuickTestExport() {
  const [rangeDate, setRangeDate] = useState();
  const [loading, setLoading] = useState(false);

  async function onExport(e) {
    e.preventDefault()
    setLoading(true);

    if(!rangeDate || rangeDate.length < 2) {
      notification.error({ message: 'Por favor selecione um intervalo de data para o relatório' });
      setLoading(false);
      return;
    }
    
    try {
      const url = `/quick_test/export?initialDate=${rangeDate[0].toISOString()}&finalDate=${rangeDate[1].toISOString()}`;
      const data = await api.get(url);
      if (data.data && data.data.result) {
        const pom = document.createElement('a');
        pom.href = data.data.result;
        pom.setAttribute('download', data.data.result);
        pom.click();
      }
    } catch (error) {
      console.log(error);
      notification.error({ error });
    }
    setLoading(false);
  }

  function onChange(value) {
    setRangeDate(value);
  }

  return (
    <>
      <div style={{ background: '#ECECEC', padding: '20px', marginTop: 3 }}>
        <Form layout="vertical" onSubmit={onExport}>
          <Row gutter={24}>
            <Col {...labelCol}>
              <Form.Item label={translate('quickTest.dateSelectLabel')}>
              <RangePicker
                showTime={{ format: 'HH:mm' }}
                format="YYYY-MM-DD HH:mm"
                placeholder={[translate('quickTest.startTime'), translate('quickTest.endTime')]}
                onChange={onChange}
              />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col {...labelCol}>
              <Button htmlType="submit" loading={loading}>
                {translate('quickTest.exportButton')}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  )
}

export default QuickTestExport;