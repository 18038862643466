
import React, { useEffect } from 'react';
import { useObserver } from "mobx-react";
import { storeContext } from '../../context';
import {
  Form,
  Input,
  Row,
  Col,
  Switch,
} from 'antd';
import RegistryForm from '../RegistryForm';
import ActiveOnlyFilter from '../ActiveOnlyFilter';
import translate from '../../i18n/translate';
import CustomMultiSelect from '../CustomMultiSelect';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: translate('default.name'),
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Url',
    dataIndex: 'url',
    key: 'url',
  }
];

const labelCol = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 10,
};

let ContentForm = () => {
  const store = React.useContext(storeContext);

  const handleChange = (field, event) => {
    store.currentItem[field] = event.target.value
  }

  const handleSwitchChange = (field, checked) => {
    store.currentItem[field] = checked ? 1 : 0;
  }

  const handleSelect = (field, value) => {
    store.currentItem[field] = value;
  }

  return useObserver(() => (
    <Form layout="vertical">
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="Id">
            <Input value={store.currentItem.id} disabled={true} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label={translate('default.name')}>
            <Input value={store.currentItem.name} onChange={handleChange.bind(this, 'name')} />
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label={translate('default.description')}>
            <Input value={store.currentItem.description} onChange={handleChange.bind(this, 'description')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="Url">
            <Input value={store.currentItem.url} onChange={handleChange.bind(this, 'url')} />
          </Form.Item>
        </Col>
        <Col {...labelCol} >
          <Form.Item label={translate('account.accounts')}>
            <CustomMultiSelect
              key={store.currentItem.keyAccount}
              searchValue={'id'}
              searchKey={'name'}
              path={'/account?active=1'}
              placeholder={translate('default.select.name')}
              onSelect={handleSelect.bind(this, 'accounts')}
              value={store.currentItem.accounts} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol} >
          <Row>
            <Form.Item label={translate("default.active")}>
              <Switch onChange={handleSwitchChange.bind(this, 'active')} checked={Boolean(store.currentItem.active)} />
            </Form.Item>
          </Row>
        </Col>
        {store.currentItem.id && <Col {...labelCol} >

        </Col>}
      </Row>
    </Form>
  ));
}

const StatisticsPage = () => {
  const store = React.useContext(storeContext);

  ContentForm = Form.create({})(ContentForm);

  store.editForm = undefined;
  ContentForm.tab = 'Info';

  store.tabs = [ContentForm];

  store.columns = columns;
  store.url = '/statistic_page';
  store.filterDefault = { active: 1 };

  useEffect(() => {
    store.customFilters = ActiveOnlyFilter;
    return () => {
      store.filterDefault = {};
      store.customFilters = null;
    }
  }, [store.filterDefault, store.customFilters]);

  return (
    <div>
      <RegistryForm />
    </div>
  )
}

export default StatisticsPage;
