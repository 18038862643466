
import React, { useEffect, useState, useCallback } from 'react';
import { useObserver } from "mobx-react";
import { storeContext } from '../../context';

import {
    Form,
    Input,
    Row,
    Col,
    Switch,
    Button
} from 'antd';

import RegistryForm from '../RegistryForm';
import translate, { translateFormat } from '../../i18n/translate';
import CustomAutoComplete from '../CustomAutoComplete';
import ApplicationSettings from './ApplicationSettings';
import api from '../../helpers/api';
import Confirm from '../Confirm/Confirm';
import ActiveOnlyFilter from '../ActiveOnlyFilter';
import TabsAsType from '../AsType/TabsAsType';
import AsLevel from '../AsLevel/AsLevel';
import TabsAsDeviceMapping from '../AsDeviceMapping/TabsAsDeviceMapping';
import { getError } from '../../helpers/verifyStatusErrorResponse';
import { Bus } from '../../helpers/utils';

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: translate('default.name'),
        dataIndex: 'name',
        key: 'name',
        sorter: true,
    },
    {
        title: translate('default.description'),
        dataIndex: 'description',
        key: 'description',
        sorter: true,
    },
    {
        title: translate('client.name'),
        dataIndex: 'client',
        key: 'client',
        sorter: true,
    },
    {
        title: translate('default.parent'),
        dataIndex: 'parent',
        key: 'parent',
    },
    {
        title: translate('default.version'),
        dataIndex: 'version',
        key: 'version',
    },
];

const labelCol = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 10,
};

function MigrateByTago() {
    const store = React.useContext(storeContext);
    const [loading, setLoading] = useState(false);

    async function migrate() {
        try {
            setLoading(true);
            const { data } = await api.put(`/as_device_mapping/migration/application/${store.currentItem.id}`);
            store.success = { message: translateFormat('application.msg.migrateSuccess', data.result || 0) }
        } finally {
            setLoading(false);
        }
    }

    return <Button loading={loading} type="primary"
        onClick={() => Confirm({ func: () => migrate(), content: translate('application.msg.migrateByTago'), })}>{translate('application.migrateByTago')}
    </Button>;
}

function Duplicate() {
    const store = React.useContext(storeContext);
    const [loading, setLoading] = useState(false);

    async function duplicate() {
        try {
            setLoading(true);
            const { data } = await api.post(`/application/duplicate/${store.currentItem.id}`);
            store.success = { message: translateFormat('application.msg.duplicateSuccess') }
            store.currentItem = data.result;
            Bus.emit('reloadCustomTabs');
        } finally {
            setLoading(false);
        }
    }
    const content = (
        <>
            <strong>{translate('application.msg.followingDuplicated')}</strong>
            <br />
            <ul>
                <li>{translate('asLevel.name')}</li>
                <li>{translate('asType.name')}
                    <ul>
                        <li>{translate('asType.asForm')}</li>
                        <li>{translate('asScript.name')}</li>
                        <li>{translate('asDashboard.name')}</li>
                        <li>{translate('asUser.name')}</li>
                        <li>{translate('asAccess.name')}</li>
                        <li>As Tag</li>
                        <li>As Data</li>
                        <li>{translate('asAnalysis.name')}</li>
                        <li>{translate('asAction.name')}</li>
                    </ul>
                </li>
                <li>{translate('asDeviceMapping.name')}</li>
            </ul>
        </>
    );
    return <Button loading={loading} type="dashed"
        style={{ marginRight: 3 }}
        onClick={() => Confirm({
            func: () => duplicate(), title: translate('application.msg.duplicate'),
            width: 600,
            content,
            okText: translate('application.duplicate')
        })}>{translate('application.duplicate')}
    </Button>;
}

let ContentForm = () => {
    const store = React.useContext(storeContext);

    const handleChange = (field, event) => {
        store.currentItem[field] = event.target.value
    }

    const handleSelect = (field, value) => {
        store.currentItem[field] = value
    }

    const handleSwitchChange = (field, checked) => {
        store.currentItem[field] = checked ? 1 : 0;
    }

    const initializa = useCallback(async () => {
        try {
            const comp = [<Duplicate />]
            const data = await api.get(`/as_device_mapping/count/${store.currentItem.id}`);
            if (data.data && data.data.result) {
                comp.push(<MigrateByTago />);
            }
            store.buttonFooter = comp;
        } catch (error) {
            getError(error, store);
        }
        // eslint-disable-next-line
    }, [store.currentItem.id]);

    useEffect(() => {
        if (store.currentItem.id) {
            if (!store.currentItem.application_eui) {
                store.currentItem.application_eui = store.currentItem.id
            }
            if (!store.buttonFooter) {
                initializa();
            }
        }
        return () => store.buttonFooter = null;
        // eslint-disable-next-line
    }, [store.currentItem.id, initializa]);

    return useObserver(() => (
        <Form layout="vertical">
            <Row gutter={16}>
                <Col {...labelCol}>
                    <Form.Item label="Id">
                        <Input value={store.currentItem.id} disabled={true} />
                    </Form.Item>
                </Col>
                <Col {...labelCol}>
                    <Form.Item label={translate('default.name')} required>
                        <Input value={store.currentItem.name} onChange={handleChange.bind(this, 'name')} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col {...labelCol}>
                    <Form.Item label={translate('default.description')} required>
                        <Input value={store.currentItem.description} onChange={handleChange.bind(this, 'description')} />
                    </Form.Item>
                </Col>
                <Col {...labelCol}>
                    <Form.Item label={translate('application.eui')}>
                        <Input value={store.currentItem.application_eui} onChange={handleChange.bind(this, 'application_eui')} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col {...labelCol}>
                    <Form.Item label={translate('client.name')}>
                        <CustomAutoComplete
                            searchValue={'id'}
                            searchKey={'name'}
                            path={'/client'}
                            onSelect={handleSelect.bind(this, 'client_id')}
                            value={store.currentItem.client_id} />
                    </Form.Item>
                </Col>
                <Col {...labelCol}>
                    <Form.Item label={translate('default.version')}>
                        <Input value={store.currentItem.version} onChange={handleChange.bind(this, 'version')} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col {...labelCol} >
                    <Form.Item label={translate('default.active')}>
                        <Switch onChange={handleSwitchChange.bind(this, 'active')} checked={Boolean(store.currentItem.active)} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    ));
}

const ApplicationList = () => {
    const store = React.useContext(storeContext);

    ContentForm = Form.create({})(ContentForm);

    store.columns = columns;
    store.url = '/application';
    store.filterDefault = { active: 1 };

    store.editForm = undefined;
    ContentForm.tab = 'Info';

    ApplicationSettings.tab = 'applicationSettings.name'
    ApplicationSettings.disabled = true;

    TabsAsType.tab = 'asType.name'
    TabsAsType.disabled = true;
    TabsAsType.hideReload = true;

    AsLevel.tab = 'asLevel.name'
    AsLevel.disabled = true;
    AsLevel.hideReload = true;

    TabsAsDeviceMapping.tab = 'asDeviceMapping.name'
    TabsAsDeviceMapping.disabled = true;
    TabsAsDeviceMapping.hideReload = true;

    useEffect(() => {
        store.customFilters = ActiveOnlyFilter;
        return () => {
            store.filterDefault = {};
            store.customFilters = null;
        }
    }, [store.filterDefault, store.customFilters]);

    store.tabs = [ContentForm, ApplicationSettings, AsLevel, TabsAsType, TabsAsDeviceMapping];

    return <RegistryForm />
}

export default ApplicationList;
