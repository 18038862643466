import React, { useState, useEffect } from 'react'
import api from '../../helpers/api'
import { storeContext } from '../../context';
import { Layout, Input, Row, Col, Form, Icon, Tooltip } from 'antd';
import SpinInfo from '../SpinInfo';
import AlertNotAllow from '../AlertNotAllow';
import translate from '../../i18n/translate';
import { copyToClipboard } from '../../helpers/utils';
const { Content } = Layout;

const labelCol = {
    xs: 24,
    sm: 24,
    md: 6,
    lg: 6,
    xl: 6,
};

export default function SecurityProduct() {
    const store = React.useContext(storeContext);
    const [keysValue, setKeysValue] = useState();
    const [permission, setPermission] = useState(true);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        api.get(`/product_keys/${store.currentItem.id}`)
            .then(data => {
                if (data.data.result) {
                    setKeysValue(data.data.result.keys_value);
                }
            })
            .catch(() => setPermission(false))
            .finally(() => setLoading(false));
    }, [store.currentItem.id])

    return (
        <Content className="padding-20"  >
            {permission ?
                keysValue ? (
                    <>
                        <Row gutter={16}>
                            {Object.entries(keysValue).map(([key, value], index) =>
                                <Col  {...labelCol} key={index}>
                                    <Form.Item label={key}>
                                        <Input value={value} disabled addonAfter={
                                            <Tooltip placement="topLeft" title={translate('default.copy')}>
                                                <Icon type="copy" onClick={() => copyToClipboard(value)} />
                                            </Tooltip>
                                        } />
                                    </Form.Item>
                                </Col>
                            )}
                        </Row>
                    </>) : (
                    <SpinInfo spinning={loading} delay={100} />
                ) :
                (
                    <AlertNotAllow />
                )}
        </Content>
    )
}