import React, { useState, useEffect, useCallback } from 'react'
import { storeContext } from '../../context';
import { Layout, Button, Table, Col, Row, Form, Input, Divider } from 'antd';
import translate from '../../i18n/translate';
import api from '../../helpers/api';
import { getError } from '../../helpers/verifyStatusErrorResponse';
import AlertNotAllow from '../AlertNotAllow';
import Confirm from '../Confirm/Confirm';
import TargetsPermissionsComponent from './TargetsPermissionsComponent';

const { Content } = Layout;
const labelCol = {
  xs: 24,
  sm: 24,
  md: 10,
  lg: 10,
  xl: 8,
};

export default function AsAccess() {
  const store = React.useContext(storeContext);
  const [data, setData] = useState([]);
  const [state, setState] = useState({});
  const [permission, setPermission] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [targets, setTargets] = useState([]);
  const [permissions, setPermissions] = useState([]);

  const columns = [
    {
      title: translate('default.name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '',
      align: 'right',
      key: 'action',
      width: 200,
      render: (text, record, index) => (
        <>
          <Button icon="edit" type="default" onClick={() => {
            if (record.data) {
              setPermissions(record.data.permissions || []);
              setTargets(record.data.targets || []);
            }
            setState({ ...record, index });
          }}
            htmlType="button" style={{ marginRight: 10 }} />
          <Button icon="delete" type="danger" onClick={() => Confirm({ func: () => remove(index) })} htmlType="button" />
        </>
      ),
    }
  ];

  async function remove(index) {
    try {
      setLoading(true);
      await api.delete(`/as_access/${data[index].id}`);
      const temp = [...data];
      temp.splice(index, 1);
      setData(temp);
    } catch (e) {
      setPermission(getError(e, store));
    }
    setLoading(false);
  }

  async function onAdd() {
    const model = {
      ...state,
      as_type_id: store.currentItem.asTypeId,
      permissions,
      targets
    }
    const result = await api.post('/as_access', model);
    state.id = result.data.result.id;
    state.data = {
      targets: targets || [],
      permissions: permissions || []
    };
    const temp = [...data, state];
    setData(temp);
    setState({});
    setPermissions([]);
    setTargets([]);
  }

  async function onUpdate() {
    const index = state.index;
    delete state.index;
    const model = {
      ...state,
      as_type_id: store.currentItem.asTypeId,
      permissions,
      targets
    }
    await api.put(`/as_access/${state.id}`, model);
    state.data = {
      targets: targets || [],
      permissions: permissions || []
    };
    data[index] = state;
    setData(data);
    setState({});
    setPermissions([]);
    setTargets([]);
  }

  async function onSubmit(event) {
    event.preventDefault();
    try {
      setLoadingButton(true);
      setLoading(true);
      if (state.id) {
        await onUpdate();
      } else {
        await onAdd();
      }
      store.success = { message: translate('asAccess.save') }
    } catch (e) {
      setPermission(getError(e, store));
    }
    setLoadingButton(false);
    setLoading(false);
  }

  function isDisabled() {
    return !state || !state.name;
  }

  const initialize = useCallback(async () => {
    setLoading(true);
    try {
      const data = await api.get(`/as_access/as_type/${store.currentItem.asTypeId}`);
      if (data.data && data.data.result) {
        return setData(data.data.result);
      }
      setData([]);
    } catch (e) {
      setPermission(getError(e, store));
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [store.currentItem.asTypeId]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <Content className="padding-20">
      {permission ?
        <>
          <Form layout="vertical" onSubmit={onSubmit}>
            <Row gutter={16}>
              <Col {...labelCol}>
                <Form.Item label={translate('default.name')} required>
                  <Input value={state.name}
                    onChange={(e) => {
                      const name = e.target.value;
                      setState(prevState => ({ ...prevState, name }));
                    }} />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <TargetsPermissionsComponent values={targets} setValues={setTargets} valueDefault={{ target: 'any' }} />
            <Divider />
            <TargetsPermissionsComponent values={permissions} setValues={setPermissions} isPemission valueDefault={{ resource: null, target: 'any' }} />
            <Row gutter={16}>
              <Col>
                <Form.Item>
                  <Button loading={loadingButton} type="primary" disabled={isDisabled()} htmlType="submit" >
                    {state.id ? translate('default.save') : translate('default.add')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Col>
            <Table
              size="small"
              rowKey={record => record.key}
              columns={columns}
              dataSource={data}
              pagination={false}
              loading={loading}
            />
          </Col>
        </>
        : (
          <AlertNotAllow />
        )}
    </Content>
  )
}
