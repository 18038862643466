
import React, { useCallback, useEffect, useState } from 'react';
import { useObserver } from "mobx-react";
import { storeContext } from '../../context';
import {
    Form,
    Input,
    Row,
    Col,
    Radio,
    Tree,
    Spin,
    Tabs,
} from 'antd';

import RegistryForm from '../RegistryForm';
import translate from '../../i18n/translate';
import CustomMultiSelect from '../CustomMultiSelect';
import CustomAutoComplete from '../CustomAutoComplete';
import api from '../../helpers/api';
import Orgchart from '../Orgchart/Orgchart';

const { TabPane } = Tabs;

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 300
    },
    {
        title: translate('default.name'),
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: translate('client.name'),
        dataIndex: 'client',
        key: 'client',
    },
    {
        title: translate('groupProfile.name'),
        dataIndex: 'group_profile',
        key: 'group_profile',
    },
    {
        title: translate('default.type'),
        dataIndex: 'type',
        key: 'type',
    },
    {
        title: translate('default.parent'),
        dataIndex: 'parentName',
        key: 'parentName',
    },
];

const labelCol = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 10,
};
let count = 0;
let ContentForm = () => {
    const store = React.useContext(storeContext);
    const [treeData, setTreeData] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleChange = (field, event) => {
        store.currentItem[field] = event.target.value
    }

    const handleChangeType = (event) => {
        store.currentItem.type = event.target.value;
        count++
        store.currentItem.parent = null;
        store.currentItem.client_id = null;
        store.currentItem.applications = [];
        store.currentItem.accounts = [];
        store.currentItem.keyAll = count;
        store.currentItem.keyAccount = count;
    }

    const handleSelect = (field, value) => {
        store.currentItem[field] = value;
    }

    const isDisableClientType = () => {
        return !store.currentItem.type || store.currentItem.type === 'client';
    }

    const isDisableApplicationType = () => {
        return !store.currentItem.type || store.currentItem.type === 'application';
    }

    const isDisableApplicationOrClientType = () => {
        return isDisableClientType() || isDisableApplicationType()
    }

    const isDisableDifferentClientType = () => {
        return !store.currentItem.type || store.currentItem.type !== 'client';
    }

    const getUrlApplication = () => {
        return store.currentItem.type === 'application' ? '/application' : `/account_profile/up/application/type/${store.currentItem.type}`;
    }

    const buildParentTree = useCallback(async () => {
        setLoading(true);
        setTreeData([]);
        if (store.currentItem.parent) {
            const { data } = await api.get(`account_profile/parent_tree/${store.currentItem.parent}`);
            if (data && data.result) {
                setTreeData(data.result);
            }
        }
        setLoading(false);
    }, [store.currentItem.parent]);

    useEffect(() => {
        buildParentTree();
    }, [buildParentTree]);

    return useObserver(() => (
        <Form layout="vertical">
            <Row gutter={16}>
                <Col {...labelCol}>
                    <Form.Item label="Id">
                        <Input value={store.currentItem.id} disabled={true} />
                    </Form.Item>
                </Col>
                <Col {...labelCol}>
                    <Form.Item label={translate('default.name')}>
                        <Input value={store.currentItem.name} onChange={handleChange.bind(this, 'name')} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col {...labelCol}>
                    <Form.Item label={translate('default.type')}>
                        <Radio.Group onChange={handleChangeType.bind(this)}
                            value={store.currentItem.type}>
                            <Radio value='client'>{translate('client.name')}</Radio>
                            <Radio value='application' style={{ marginLeft: 15 }}>{translate('application.name')}</Radio>
                            <Radio value='group' style={{ marginLeft: 15 }}>{translate('default.group')}</Radio>
                            <Radio value='profile' style={{ marginLeft: 15 }}>{translate('default.profile')}</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col {...labelCol}>
                    <Form.Item label={translate('accountProfile.parentTree')}>
                        <CustomAutoComplete
                            key={store.currentItem.keyAll}
                            notRequest={isDisableClientType()}
                            disabled={isDisableClientType()}
                            ignoredId={store.currentItem.id}
                            searchValue={'id'}
                            searchKey={'name'}
                            path={`/account_profile/up/type/${store.currentItem.type}`}
                            onSelect={handleSelect.bind(this, 'parent')}
                            placeholder={translate('default.select.name')}
                            value={store.currentItem.parent} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col {...labelCol} >
                    <Form.Item label={translate('client.name')}>
                        <CustomAutoComplete
                            key={store.currentItem.keyAll}
                            notRequest={isDisableDifferentClientType()}
                            disabled={isDisableDifferentClientType()}
                            searchValue={'id'}
                            searchKey={'name'}
                            path={'/client'}
                            onSelect={handleSelect.bind(this, 'client_id')}
                            value={store.currentItem.client_id}
                            placeholder={translate('default.select.name')} />
                    </Form.Item>
                </Col>
                <Col {...labelCol} >
                    <Form.Item label={translate('account.accounts')}>
                        <CustomMultiSelect
                            key={store.currentItem.keyAccount}
                            notRequest={isDisableApplicationOrClientType()}
                            disabled={isDisableApplicationOrClientType()}
                            searchValue={'id'}
                            searchKey={'name'}
                            path={'/account?active=1'}
                            placeholder={translate('default.select.name')}
                            onSelect={handleSelect.bind(this, 'accounts')}
                            value={store.currentItem.accounts} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col {...labelCol}>
                    <Form.Item label={translate('groupProfile.name')}>
                        <CustomAutoComplete
                            key={store.currentItem.keyAll}
                            notRequest={isDisableApplicationOrClientType()}
                            disabled={isDisableApplicationOrClientType()}
                            searchValue={'id'}
                            searchKey={'name'}
                            path={'/group_profile'}
                            onSelect={handleSelect.bind(this, 'group_profile_id')}
                            value={store.currentItem.group_profile_id}
                            placeholder={translate('default.select.name')} />
                    </Form.Item>
                </Col>
                <Col {...labelCol} >
                    <Form.Item label={translate('application.applications')}>
                        <CustomMultiSelect
                            key={store.currentItem.keyAll}
                            notRequest={isDisableClientType()}
                            disabled={isDisableClientType()}
                            searchValue={'id'}
                            searchKey={'name'}
                            path={getUrlApplication()}
                            placeholder={translate('default.select.name')}
                            onSelect={handleSelect.bind(this, 'applications')}
                            value={store.currentItem.applications} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col {...labelCol} >
                    <Form.Item label='Deploy'>
                        <CustomAutoComplete
                            key={store.currentItem.keyAll}
                            notRequest={isDisableClientType()}
                            disabled={isDisableClientType()}
                            searchValue={'id'}
                            searchKey={'name'}
                            path={'/deploy'}
                            onSelect={handleSelect.bind(this, 'deploy_id')}
                            value={store.currentItem.deploy_id}
                            placeholder={translate('default.select.name')} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col  {...labelCol} style={{ marginTop: 25 }}>
                    {!loading ? (<Tree
                        defaultExpandAll treeData={treeData} selectable={false} />) : <Spin />}
                </Col>
            </Row>
        </Form>
    ));
}

const AccountProfileList = () => {
    const [activeTab, setActiveTab] = useState('1');
    const store = React.useContext(storeContext);

    ContentForm = Form.create({})(ContentForm);

    store.editForm = ContentForm;
    store.columns = columns;
    store.url = '/account_profile';

    return (
        <Tabs activeKey={activeTab} onTabClick={(key) => {setActiveTab(key);}} >
            <TabPane tab="Account Profile List" key="1">
                <RegistryForm />
            </TabPane>

            <TabPane tab="Org Chart" key="2" style={{overflow: 'scroll'}}>
                <Orgchart setActiveTab={setActiveTab} />
            </TabPane>
        </Tabs>
            
    )
}

export default AccountProfileList;
