
export const createStore = () => {
  const store = {
    permissions: [],
    filterProductClientSn: { status: null },
    filterAlert: { status: null }
  };

  return store;
};

export default createStore;
