import localforage from 'localforage'; 
import { Modal } from 'antd';

const { confirm } = Modal;

export default function LogoutConfirm() {
    
    async function onOk() {
        await localforage.removeItem('token');
        await localforage.removeItem('hasAccountProfileId');
        window.location.reload(true);
    };
    
    return confirm({
        title: 'Logout',
        content: 'Are you sure you want to logout?',
        onOk() {
            onOk();
        },
    });
}
