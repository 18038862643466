import React, { useState, useEffect } from 'react';
import api from '../../helpers/api'
import { storeContext } from '../../context';
import { Layout, Table, Alert } from 'antd';
import { formatDate } from "../../helpers/date";
import translate from '../../i18n/translate';
const { Content } = Layout;

export default function ProductInstallation() {
    const store = React.useContext(storeContext);
    const [installation, setInstallation] = useState([]);
    const [permission, setPermission] = useState(true);
    const [loading, setLoading] = useState(true);

    function installationLog(log) {
        return log ? log.replace(/,/g, '\n') : '';
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: translate('user.name'),
            dataIndex: 'account',
            key: 'account',
        },
        {
            title: translate('product.clientSN'),
            dataIndex: 'client_sn',
            key: 'client_sn',
        },
        {
            title: translate('device.NSDevice'),
            dataIndex: 'ns_device',
            key: 'ns_device',
        },
        {
            title: translate('device.ASDevice'),
            dataIndex: 'as_device',
            key: 'as_device',
        },
        {
            title: translate('default.type'),
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: translate('default.createdAt'),
            dataIndex: 'created_at',
            key: 'created_at',
            render: (created_at) => formatDate(created_at),
        },
        {
            title: translate('default.updatedAt'),
            dataIndex: 'updated_at',
            render: (updated_at) => formatDate(updated_at),
            key: 'updated_at',
        },
    ];

    useEffect(() => {
        setLoading(true);
        api.get(`/product_installation/product/${store.currentItem.id}`)
            .then(data => {
                setInstallation(data.data.result);
            })
            .catch(() => setPermission(false))
            .finally(() => setLoading(false));
    }, [store.currentItem.id])

    return (
        <Content className="padding-10"  >
            {permission ? (
                <>
                    <Table
                        size="small"
                        rowKey={record => record.key}
                        columns={columns}
                        expandedRowRender={record => <pre> {installationLog(record.installation_log)}</pre>}
                        dataSource={installation}
                        loading={loading}
                        pagination={false}
                        style={{ overflow: 'scroll', marginTop: 8 }}
                    />,
                </>) :
                (
                    <Alert
                        message="Warning"
                        description="You are currently not allowed to access this information"
                        type="warning"
                    />
                )}
        </Content>
    )
}
