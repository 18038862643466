import React, { useState, useEffect, useCallback } from 'react';
import api from '../../helpers/api'
import { storeContext } from '../../context';
import { Layout, Table, Alert, Checkbox, Form } from 'antd';
import { formatDate } from "../../helpers/date";
import translate from '../../i18n/translate';
import JSONFormatColor from '../JSONFormatColor';
import { isAuthorized } from "../../helpers/verifyStatusErrorResponse";
const { Content } = Layout;
const columns = [
    {
        title: translate('deviceData.provider'),
        dataIndex: 'provider',
        key: 'provider',
        sorter: true,
    },
    {
        title: translate('default.type'),
        dataIndex: 'type',
        key: 'type',
        sorter: true,
    },
    {
        title: translate('deviceData.counter'),
        dataIndex: 'counter',
        key: 'counter',
        sorter: true,
    },
    {
        title: translate('deviceData.duplicate'),
        dataIndex: 'duplicate',
        key: 'duplicate',
        sorter: true,
    },
    {
        title: 'RSSI',
        dataIndex: 'rssi',
        key: 'rssi',
        sorter: true,
    },
    {
        title: 'Gateway',
        dataIndex: 'gateway',
        key: 'gateway',
        sorter: true,
    },
    {
        title: translate('default.time'),
        dataIndex: 'time',
        key: 'time',
        sorter: true,
        defaultSortOrder: 'descend',
        render: (time) => formatDate(time ? new Date(time * 1000): null),
    }
];

export default function RawData() {
    const store = React.useContext(storeContext);
    const [data, setData] = useState([]);
    const [permission, setPermission] = useState(true);
    const [loading, setLoading] = useState(true);
    const [totalItems, setTotalItems] = useState(0);
    const [duplicate, setDuplicate] = useState(false);
    const [order, setOrder] = useState(undefined);
    const limit = 20;

    function onChange(e) {
        setDuplicate(e.target.checked);
        list(0, order, e.target.checked)
    }

    function CheckboxDuplicate() {
        return (
            <Form.Item>
                <Checkbox onChange={onChange} checked={duplicate}>{translate('deviceData.duplicate')}</Checkbox>
            </Form.Item>
        )
    }

    const list = useCallback(async (page, sorter = {column: columns[6] ,order: 'descend', field: 'time'}, duplicate) => {
        try {
            setLoading(true);
            const offset = page ? ((limit * page) - limit) : 0;
            let params = `?limit=${limit}&offset=${offset}`;
            if (sorter && sorter.column) {
                params += `&order=${sorter.field} ${sorter.order === 'descend' ? 'desc' : 'asc'}`;
            } 
            if (duplicate) {
                params += `&duplicate=${duplicate}`
            }   
            const result = await api.get(`/device_data/radio_serial/${store.currentItem.radio_serial}${params}`);
            if (result) {
                setData(result.data.result);
                setTotalItems(result.data.count || 0);
            }
        } catch (error) {
            setPermission(isAuthorized(error));
        } finally {
            setLoading(false);
        }
    }, [store.currentItem.radio_serial]);

    function changeOrderPagination(page = 0, sorter, duplicate) {
        setOrder(sorter);
        list(page, sorter, duplicate)
    }

    useEffect(() => {
        list();
    }, [list]);

    return (
        <Content className="padding-20"  >
            {permission ? (
                <>
                    <CheckboxDuplicate/>
                    <Table
                        size="small"
                        rowKey={(record, index) => index}
                        columns={columns}
                        expandedRowRender={record => record.data ? <JSONFormatColor value={JSON.parse(record.data)} /> : <h1>Empty</h1>}
                        dataSource={data}
                        loading={loading}
                        onChange={(value, a, sorter) => changeOrderPagination(value.current, sorter, duplicate)}
                        pagination={{
                            defaultPageSize: limit,
                            pageSize: limit,
                            total: totalItems,
                        }}
                    />,
                </>) :
                (
                    <Alert
                        message="Warning"
                        description="You are currently not allowed to access this information"
                        type="warning"
                    />
                )}
        </Content>
    )
}
