import React, { useState } from 'react'
import { Tabs, Button } from 'antd';
import AsType from './AsType';
import AsForm from './AsForm';
import AsScript from './AsScript';
import AsDashboard from './AsDashboard';
import AsUser from './AsUser';
import AsAccess from './AsAccess';
import AsTag from './AsTag';
import AsData from './AsData';
import AsAnalysis from './AsAnalysis';
import AsAction from './AsAction';
import translate from '../../i18n/translate';
const { TabPane } = Tabs;

export default function TabsAsType() {
    const [reload, setReload] = useState({});

    function onClick(param) {
        setReload(prevState => ({ ...prevState, [param]: new Date().getTime() }));
    }

    function button(param) {
        return (
            <Button style={{ marginLeft: 20 }} key="2" onClick={() => onClick(param)} icon="reload">
                {translate('default.reload')}
            </Button>
        );
    }

    function setRealodId(id) {
        setReload({
            id,
            asForm: id,
            asScript: id,
            asDashboard: id,
            asUser: id,
            asTag: id,
            asData: id,
            asAction: id,
        });
    }

    return (
        <Tabs defaultActiveKey="0">
            <TabPane tab="Info" key="0">
                <AsType setId={setRealodId} />
            </TabPane>
            <TabPane tab={translate('asType.asForm')} key="1" disabled={!reload.id}>
                {button('asForm')}
                <AsForm key={reload.asForm} />
            </TabPane>
            <TabPane tab={translate('asScript.name')} key="2" disabled={!reload.id}>
                {button('asScript')}
                <AsScript key={reload.asScript} />
            </TabPane>
            <TabPane tab={translate('asDashboard.name')} key="3" disabled={!reload.id}>
                {button('asDashboard')}
                <AsDashboard key={reload.asDashboard} />
            </TabPane>
            <TabPane tab={translate('asUser.name')} key="4" disabled={!reload.id}>
                {button('asUser')}
                <AsUser key={reload.asUser} />
            </TabPane>
            <TabPane tab={translate('asAccess.name')} key="5" disabled={!reload.id}>
                {button('asAccess')}
                <AsAccess key={reload.asAccess} />
            </TabPane>
            <TabPane tab='As Tag' key="6" disabled={!reload.id}>
                {button('asTag')}
                <AsTag key={reload.asTag} />
            </TabPane>
            <TabPane tab='As Data' key="7" disabled={!reload.id}>
                {button('asData')}
                <AsData key={reload.asData} />
            </TabPane>
            <TabPane tab={translate('asAnalysis.name')} key="8" disabled={!reload.id}>
                {button('asAnalysis')}
                <AsAnalysis key={reload.asAnalysis} />
            </TabPane>
            <TabPane tab={translate('asAction.name')} key="9" disabled={!reload.id}>
                {button('asAction')}
                <AsAction key={reload.asAction} />
            </TabPane>
        </Tabs>
    )
}
