
import React from 'react';
import { useObserver } from "mobx-react";
import { storeContext } from '../../context';

import {
    Form,
    Input,
    Row,
    Col
} from 'antd';

import RegistryForm from '../RegistryForm';
import translate from '../../i18n/translate';
import CustomAutoComplete from '../CustomAutoComplete';
import DeploySettings from './DeploySettings';

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: translate('default.name'),
        dataIndex: 'name',
        key: 'name',
        sorter: true,
    },
    {
        title: translate('application.name'),
        dataIndex: 'application',
        key: 'application',
    },
    {
        title: translate('deploy.applicationService'),
        dataIndex: 'application_service_name',
        key: 'application_service_name',
    },
    {
        title: translate('deploy.networkService'),
        dataIndex: 'network_service_name',
        key: 'network_service_name',
    },
];

const labelCol = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 10,
};

let ContentForm = () => {
    const store = React.useContext(storeContext);
    const handleChange = (field, event) => {
        store.currentItem[field] = event.target.value
    }

    const handleComboSelect = (field, value) => {
        console.log(field, value);
        store.currentItem[field] = value
    }

    return useObserver(() => (
        <Form layout="vertical">
            <Row gutter={16}>
                <Col {...labelCol}>
                    <Form.Item label="Id">
                        <Input value={store.currentItem.id} disabled={true} />
                    </Form.Item>
                </Col>
                <Col {...labelCol}>
                    <Form.Item label={translate('default.name')}>
                        <Input value={store.currentItem.name} onChange={handleChange.bind(this, 'name')} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col {...labelCol}>
                    <Form.Item label={translate('application.name')}>
                        <CustomAutoComplete
                            searchValue={'id'}
                            searchKey={'name'}
                            path={'/application'}
                            placeholder={translate('application.select.name')}
                            onSelect={handleComboSelect.bind(this, 'application_id')}
                            value={store.currentItem.application_id} />
                    </Form.Item>
                </Col>
                <Col {...labelCol}>
                    <Form.Item label={translate('deploy.applicationService')}>
                        <CustomAutoComplete
                            searchValue={'id'}
                            searchKey={'name'}
                            path={'/external_services?service_type=AS'}
                            placeholder={translate('default.select.name')}
                            onSelect={handleComboSelect.bind(this, 'application_service_id')}
                            value={store.currentItem.application_service_id} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col {...labelCol}>
                    <Form.Item label={translate('deploy.networkService')}>
                        <CustomAutoComplete
                            searchValue={'id'}
                            searchKey={'name'}
                            path={'/external_services?service_type=NS'}
                            placeholder={translate('default.select.name')}
                            onSelect={handleComboSelect.bind(this, 'network_service_id')}
                            value={store.currentItem.network_service_id} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    ));
}

const Deploy = () => {
    const store = React.useContext(storeContext);

    ContentForm = Form.create({})(ContentForm);

    store.columns = columns;
    store.url = '/deploy';

    store.editForm = undefined;
    ContentForm.tab = 'Info';

    DeploySettings.tab = 'deploySettings.name'
    DeploySettings.disabled = true;

    store.tabs = [ContentForm, DeploySettings];

    return <RegistryForm />
}

export default Deploy;
