
import React from 'react';
import moment from 'moment';
import { useObserver } from "mobx-react";
import { storeContext } from '../../context';
import {
  Form,
  Input,
  Row,
  Col,
  DatePicker,
} from 'antd';
import translate from '../../i18n/translate';
import RegistryForm from '../RegistryForm';
import CustomAutoComplete from '../CustomAutoComplete';

const columns = [
  {
    title: 'Token',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Account',
    dataIndex: 'account',
    key: 'account',
  },
  {
    title: 'Account Profile',
    dataIndex: 'account_profile',
    key: 'account_profile',
  },
  {
    title: 'Expire Date',
    dataIndex: 'expires',
    key: 'expires',
  },
];

const labelCol = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 10,
};

let ContentForm = () => {
  const store = React.useContext(storeContext);

  const handleChange = (field, event) => {
    store.currentItem[field] = event.target.value;
  }

  const handleDateChange = (field, event) => {
    store.currentItem[field] = event;
  }

  const handleSelect = (field, value) => {
    store.currentItem[field] = value;
  }

  function disabledDate(current) {
    // Can not select days before today and today
    console.log(current);
    return current < moment().endOf('day');
  }
  
  return useObserver(() => (
    <Form layout="vertical">
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="Token">
            <Input value={store.currentItem.id} disabled={true} />
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label="Description">
            <Input value={store.currentItem.description} onChange={handleChange.bind(this, 'description')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label={translate('account.name')}>
            <CustomAutoComplete
              key={store.currentItem.keyAll}
              searchValue={'id'}
              searchKey={'name'}
              path={'/account'}
              onSelect={handleSelect.bind(this, 'account')}
              value={store.currentItem.account}
              placeholder={translate('default.select.name')} />
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label={translate('accountProfile.name')}>
            <CustomAutoComplete
              key={store.currentItem.account}
              searchValue={'id'}
              searchKey={'name'}
              path={`/account_profile/account/${store.currentItem.account}`}
              onSelect={handleSelect.bind(this, 'account_profile_id')}
              value={store.currentItem.account_profile_id}
              placeholder={translate('default.select.name')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="Expire Date">
            {/* <Input value={store.currentItem.expires} onChange={handleChange.bind(this, 'expires')} /> */}
            <DatePicker value={moment(store.currentItem.expires)} onChange={handleDateChange.bind(this, 'expires')} format='DD/MM/YYYY' disabledDate={disabledDate}/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  ));
}

const ServiceTokens = () => {
  const store = React.useContext(storeContext);

  ContentForm = Form.create({})(ContentForm);

  store.editForm = ContentForm;

  store.columns = columns;
  store.url = '/service_tokens';

  return (
    <div>
      <RegistryForm />
    </div>
  )
}

export default ServiceTokens;