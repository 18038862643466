import React, { Component } from 'react';
import { Select, Spin } from 'antd';
import axios from 'axios'
import configuration from '../config';

const { Option } = Select;

class CustomMultiSelect extends Component {
  state = {
    searchKey: this.props.searchKey || 'name',
    searchValue: this.props.searchValue || 'id',
    path: this.props.path,
    dataSource: this.props.dataSource || [],
    filteredDataSource: [],
    loading: false
  };

  _isMonted = false;

  componentDidMount() {
    this._isMonted = true;
    if (!this.state.path && !this.props.dataSource) {
      throw Error('You must provide a path e.g: /product or a dataSource')
    }
    if(this.props.notRequest){
      return;
    }
    this.getData();
    this.setValue();
  }

  componentWillUnmount() {
    this._isMonted = false
  }

  setValue = () => {
    const value = this.props.value ? this.props.value.map((x) => String(x[this.props.searchValue])) : [];
    this.setState({ value });
  }

  getData = async () => {
    if (this.state.dataSource && this.state.dataSource.length) {
      this.setState({ filteredDataSource: this.state.dataSource, loading: false });
      return;
    }
    const path = this.state.path;

    const url = `${configuration.URL}${path}`;
    const options = {
      headers: {
        token: global.token,
      }
    };
    this.setState({ loading: true });

    let data = await axios.get(url,options).then(r => r.data.result);

    const searchKey = this.state.searchKey;
    const searchValue = this.state.searchValue;

    // eslint-disable-next-line array-callback-return
    data.map((x) => {
      x.key = x[searchValue];
      x.value = x[searchKey];
    })
    if(this._isMonted){
      this.setState({ dataSource: data, filteredDataSource: data, loading: false });
    }
  }

  onSearch = searchText => {
    const filteredDataSource = this.state.dataSource.filter(x => x.value.toUpperCase().includes(searchText.toUpperCase()));
    this.setState({ filteredDataSource, value: searchText });
  };

  onSelect = value => {
    console.log('onselect value', value);

    this.setState({ value });
    if (this.props.onSelect) {
      this.props.onSelect(value)
    }
  };

  render() {
    const { filteredDataSource, value } = this.state;
    const children = filteredDataSource.map(x => <Option key={x.key}>{x.value}</Option>);
    return (
      <div>
         <Select
          mode="multiple"
          style={{ width: '100%' }}
          placeholder={this.props.placeholder || "Please select"}
          value={value}
          onChange={this.onSelect}
          disabled={this.props.disabled}
          optionFilterProp='children'
        >
          {children}
        </Select>
        {this.state.loading &&
          <Spin style={{ marginTop: 3.5, marginLeft: -25 }}/>
        }
      </div>
    );
  }
}

export default CustomMultiSelect;
