import React, { useState, useEffect } from 'react'
import api from '../../helpers/api'
import JSONFormatColor from '../JSONFormatColor';
import { storeContext } from '../../context';
import { Layout, Collapse, Button, Form, Row, Col } from 'antd';
import SpinInfo from '../SpinInfo';
import AlertNotAllow from '../AlertNotAllow';
import CustomAutoComplete from '../CustomAutoComplete';
import translate from '../../i18n/translate';
const { Content } = Layout;
const { Panel } = Collapse;

export default function ProductAsServer() {
    const store = React.useContext(storeContext);
    const [application, setApplication] = useState();
    const [permission, setPermission] = useState(true);
    const [loading, setLoading] = useState(true);
    const [targetAs, setTargetAs] = useState();

    function accessApplication() {
        window.open(`https://admin.tago.io/devices/${application.id}`);
    }

    const handleComboSelect = (field, value) => {
      console.log(field, value);
      setTargetAs(value);
  }

    const handleSubmit = async () => {
      const url = `external_services/application_service/${store.currentItem.id}`;
      console.log(targetAs)
      const data = {as_id: targetAs};
      const options = {
        method: 'POST',
        data,
        url,
      };

      await api(options)
        .then(res => {
          console.log(res.data);

        })
        .catch((error) => console.log(error));
    }

    const handleRemove = async () => {
      const url = `external_services/application_service/${store.currentItem.id}`;
      const options = {
        method: 'DELETE',
        url,
      };

      await api(options)
        .then(res => {
          console.log(res.data);
          
        })
        .catch((error) => console.log(error));
    }

    useEffect(() => {
        api.get(`/external_services/application_service/${store.currentItem.id}`)
            .then(data => {
                if (data.data.result) {
                    setApplication(data.data.result);
                }
            })
            .catch(() => setPermission(false))
            .finally(() => setLoading(false));
    }, [store.currentItem.id])

    return (
      <Content className="padding-20"  >
        {permission ?
          application ? 
            (<>
              <h3>{application.as}</h3>
              <Button onClick={handleRemove} style={{ marginBottom: 8 }}>Remover Device</Button>
              <Button type="link" onClick={accessApplication}>Device at Application Server</Button>
              <Collapse>
                <Panel header="JSON Application Server" key="1">
                  <JSONFormatColor value={application} />
                </Panel>
              </Collapse>
            </>) :
            loading ?
              <SpinInfo spinning={loading} delay={100} /> :
              (
                <>
                  <h3>Selecione um AS</h3>
                  <Form layout="vertical">
                    <Row gutter={16}>
                      <Col lg={6}>
                        <Form.Item>
                          <CustomAutoComplete
                            searchValue={'id'}
                            searchKey={'name'}
                            path={'/external_services?service_type=AS'}
                            placeholder={translate('default.select.name')}
                            onSelect={handleComboSelect.bind(this, 'application_service_id')}
                            value={targetAs}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Button onClick={handleSubmit}>Criar Device</Button>
                      </Col>
                    </Row>
                  </Form>
                </>
              )
          :<AlertNotAllow />
        }
      </Content>
    )
}
