import React from 'react'
import { storeContext } from '../../context';
import { Layout, Button, Table, Col, Input, Row, Form } from 'antd';
import translate from '../../i18n/translate';

const { Content } = Layout;

const labelCol = {
    xs: 24,
    sm: 24,
    md: 8,
    lg: 8,
    xl: 6,
};

export default function EquipmentTypeTags() {
  const store = React.useContext(storeContext);
  const [fields, setFields] = React.useState([]);
  const [keyField, setKeyField] = React.useState('');
  const [valueField, setValueField] = React.useState('');

  React.useEffect(() => {
    if(store.currentItem && store.currentItem.tags) {
      const initialFields = Object.entries(store.currentItem.tags).map(([key, value], index) =>{ 
        return {key, value}
      });
      setFields([...initialFields]);
    }
  }, [store.currentItem]);

  React.useEffect(() => {
    if (fields.length !== 0) {
      const newTags = {}
    fields.forEach((field) => {
      newTags[field.key] = field.value;
    });
    console.log('Novas Tags -->',newTags);
    store.currentItem.tags = newTags;
    }else {
      store.currentItem.tags = null;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  const columns = [
    {
        title: translate('default.key'),
        dataIndex: 'key',
        key: 'key'
    },
    {
        title: translate('default.value'),
        dataIndex: 'value',
        key: 'value',
    },
    {
        title: '',
        align: 'right',
        key: 'action',
        width: 200,
        render: (text, record, index) => (
            <>
                <Button icon="edit" type="default" htmlType="button" 
                onClick={() => {
                  setKeyField(record.key);
                  setValueField(record.value);
                }} 
                style={{ marginRight: 10 }} />
                <Button icon="delete" type="danger"  htmlType="button" 
                  onClick={() => handleDelete(record)}
                />
            </>
        ),
    }
  ];

  function handleDelete(field) {
    console.log(field.key);
    const index = fields.findIndex(element => element.key === field.key);
    const newFields = [...fields];
    newFields.splice(index, 1); 
    setFields(newFields);

  }

  function handleSaveFields() {
    const newField = {key: keyField, value: valueField};
    const index = fields.findIndex(element => element.key === newField.key);
    const newFields = [...fields];
    if(index !== -1){
      console.log('entrou no if')
      newFields[index] = newField;
      setFields(newFields);
    }else {
      console.log('entrou no else')
      newFields.push(newField);
      console.log('Dentro do else -->',newFields)
      setFields(newFields);
    }
  }

  return (
      <Content className="padding-20">
        <Row gutter={16}>
        <Form layout="vertical">
            <Row gutter={16}>
              <Col {...labelCol}>
                <Form.Item label={translate('default.key')} required>
                  <Input value={keyField} onChange={(e) => setKeyField(e.target.value)}/>
                </Form.Item>
              </Col>
              <Col {...labelCol}>
                <Form.Item label={translate('default.value')}>
                  <Input value={valueField} onChange={(e) => setValueField(e.target.value)}/>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button type="primary" onClick={() => handleSaveFields()}> Add Field </Button>
              </Col>
            </Row>
        </Form>
        </Row>
        <Row gutter={16} style={{marginTop: 32, marginBottom: 32}}>
        <Col>
          <Table
            size="small"
            rowKey={record => record.key}
            columns={columns}
            dataSource={fields}
            pagination={false}
          />
        </Col>
        </Row>
      </Content>
  )
}