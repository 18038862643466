import React, { useState, useEffect, useCallback } from 'react'
import { storeContext } from '../../context';
import { Layout, Button, Table, Col, Row, Form, Input, Tag, Tooltip, Select, message } from 'antd';
import translate, { translateFormat } from '../../i18n/translate';
import api from '../../helpers/api';
import { getError } from '../../helpers/verifyStatusErrorResponse';
import AlertNotAllow from '../AlertNotAllow';
import Confirm from '../Confirm/Confirm';
import CustomAutoComplete from '../CustomAutoComplete';
import { copyToClipboard } from '../../helpers/utils';
const { Content } = Layout;
const labelCol = {
  xs: 24,
  sm: 24,
  md: 10,
  lg: 10,
  xl: 8,
};
const INIT_DEFAULT = {
  as_device_mapping_id: null,
  data: `[
    {
      "variable": "value_default",
      "value": "value_default"
    }
]`
};
export default function AsData() {
  const store = React.useContext(storeContext);
  const [data, setData] = useState([]);
  const [devices, setDevices] = useState([]);
  const [state, setState] = useState(INIT_DEFAULT);
  const [permission, setPermission] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const columns = [
    {
      title: translate('default.description'),
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: translate('asDeviceMapping.name'),
      dataIndex: 'device_mapping_name',
      key: 'device_mapping_name'
    },
    {
      title: translate('asDashboard.name'),
      dataIndex: 'as_dashboard_name',
      key: 'as_dashboard_name'
    },
    {
      title: '',
      align: 'right',
      key: 'action',
      width: 200,
      render: (text, record, index) => (
        <>
          <Button icon="edit" type="default" onClick={() => {
            setState({ ...record, index, as_type_key: new Date().getTime() });
          }} htmlType="button" style={{ marginRight: 10 }} />
          <Button icon="delete" type="danger" onClick={() => Confirm({ func: () => remove(index) })} htmlType="button" />
        </>
      ),
    }
  ];

  async function remove(index) {
    try {
      setLoading(true);
      await api.delete(`/as_data/${data[index].id}`);
      const temp = [...data];
      temp.splice(index, 1);
      setData(temp);
    } catch (e) {
      setPermission(getError(e, store));
    }
    setLoading(false);
  }

  async function onAdd() {
    const model = {
      ...state,
      as_type_id: store.currentItem.asTypeId
    }
    const result = await api.post('/as_data', model);
    state.id = result.data.result.id;
    if (state.as_device_mapping_id) {
      state.device_mapping_name = devices.find(v => v.id === state.as_device_mapping_id).name;
    }
    const temp = [...data, state];
    setData(temp);
    setState({ ...INIT_DEFAULT, as_type_key: new Date().getTime() });
  }

  async function onUpdate() {
    const index = state.index;
    delete state.index;
    const model = {
      ...state,
      as_type_id: store.currentItem.asTypeId
    }
    await api.put(`/as_data/${state.id}`, model);
    if (state.as_device_mapping_id) {
      state.device_mapping_name = devices.find(v => v.id === state.as_device_mapping_id).name;
    }
    data[index] = state;
    setData(data);
    setState({ ...INIT_DEFAULT, as_type_key: new Date().getTime() });
  }

  async function onSubmit(event) {
    event.preventDefault();
    try {
      setLoadingButton(true);
      setLoading(true);
      if (state.id) {
        await onUpdate();
      } else {
        await onAdd();
      }
      store.success = { message: translate('asData.save') }
    } catch (e) {
      setPermission(getError(e, store));
    }
    setLoadingButton(false);
    setLoading(false);
  }

  function isDisabled() {
    return !state || !state.data || !state.description;
  }

  const initialize = useCallback(async () => {
    setLoading(true);
    try {
      const [data, mapping] = await Promise.all([
        api.get(`/as_data/as_type/${store.currentItem.asTypeId}`),
        api.get(`/as_device_mapping/application/${store.currentItem.id}`)
      ]);
      if (mapping.data && mapping.data.result) {
        setDevices(mapping.data.result);
      }
      if (data.data && data.data.result) {
        return setData(data.data.result);
      }
      setData([]);
    } catch (e) {
      setPermission(getError(e, store));
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [store.currentItem.asTypeId]);

  function copyValueExemplo(value) {
    copyToClipboard(value);
    message.info(translateFormat('default.copySuccessfully', value));
  }

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <Content className="padding-20">
      {permission ?
        <>
          <Form layout="vertical" onSubmit={onSubmit}>
            <Row gutter={16}>
              <Col {...labelCol}>
                <Form.Item label={translate('default.description')} required>
                  <Input value={state.description}
                    onChange={(e) => {
                      const description = e.target.value;
                      setState(prevState => ({ ...prevState, description }));
                    }} />
                </Form.Item>
              </Col>
              <Col {...labelCol}>
                <Form.Item label={translate('asDeviceMapping.name')}>
                  <Select defaultValue={null}
                    value={state.as_device_mapping_id} style={{ width: '100%' }}
                    onChange={(as_device_mapping_id) => setState(prevState => ({ ...prevState, as_device_mapping_id }))} >
                    <Select.Option key={-1} value={null}>{translate('default.select.name')}</Select.Option>
                    {devices.map(x => <Select.Option key={x.id} value={x.id}>{x.name}</Select.Option>)}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col {...labelCol}>
                <Form.Item label={translate('asType.name')}>
                  <CustomAutoComplete
                    key={state.as_type_key}
                    searchValue={'id'}
                    searchKey={'name'}
                    path={`/as_type/application/${store.currentItem.id}`}
                    onSelect={(as_dashboard_type_id) => setState(prevState => ({ ...prevState, as_dashboard_type_id }))}
                    value={state.as_type_id}
                    placeholder={translate('default.select.name')} />
                </Form.Item>
              </Col>
              <Col {...labelCol}>
                <Form.Item label={translate('asType.name')}>
                  <CustomAutoComplete
                    notRequest={!state.as_dashboard_type_id}
                    key={state.as_dashboard_type_id}
                    disabled={!state.as_dashboard_type_id}
                    searchValue={'id'}
                    searchKey={'label'}
                    path={`/as_dashboard/as_type/${state.as_dashboard_type_id}`}
                    onSelect={(as_dashboard_id, as_dashboard) => {
                      const as_dashboard_name = as_dashboard ? as_dashboard.label : null;
                      setState(prevState => ({ ...prevState, as_dashboard_id, as_dashboard_name }));
                    }}
                    value={state.as_dashboard_id}
                    placeholder={translate('default.select.name')} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col >
                <Form.Item label="Data" required>
                  <Input.TextArea value={state.data} rows={10}
                    onChange={(e) => {
                      const data = e.target.value;
                      setState(prevState => ({ ...prevState, data }))
                    }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col>
                <h3>{translate('asType.how_use_variables')}</h3>
                <span>{translate('asType.add_around_fields')}</span>
                <br />
                <br />
                <b>{translate('default.example')}:</b>
                <br />
                <b>{translate('default.device')}:</b>
                <Tooltip placement="top" title='ID; NAME; PROFILE; NETWORK; CONNECTOR.'>
                  <Tag onClick={() => copyValueExemplo('$DEVICE$ID$')} color="purple">$DEVICE$ID$</Tag>
                </Tooltip>
                <b>Dashboard:</b>
                <Tooltip placement="top" title='ID; LABEL; TYPE.'>
                  <Tag onClick={() => copyValueExemplo('$DASHBOARD$0$ID$')} color="purple">$DASHBOARD$0$ID$</Tag>
                </Tooltip>
                <b>{translate('user.name')}:</b>
                <Tooltip placement="top" title='ID; NAME; EMAIL; PROFILE.'>
                  <Tag onClick={() => copyValueExemplo('$USER$0$ID$')} color="purple">$USER$0$ID$</Tag>
                </Tooltip>
                <b>{translate('asAccess.access')}:</b>
                <Tooltip placement="top" title='ID; NAME; PROFILE.'>
                  <Tag onClick={() => copyValueExemplo('$ACCESS$0$ID$')} color="purple">$ACCESS$0$ID$</Tag>
                </Tooltip>
                <b>{translate('default.analysis')}:</b>
                <Tooltip placement="top" title='ID; NAME, TOKEN.'>
                  <Tag onClick={() => copyValueExemplo('$ANALYSIS$0$ID$')} color="purple">$ANALYSIS$0$ID$</Tag>
                </Tooltip>
                <b>Tag</b>:
                <Tooltip placement="top" title='KEY; VALUE.'>
                  <Tag onClick={() => copyValueExemplo('$TAG$0$KEY$')} color="purple">$TAG$0$KEY$</Tag>
                </Tooltip>
                <b>URL</b>:
                <Tag onClick={() => copyValueExemplo('$URL$')} color="purple">$URL$</Tag>
                <br />
                <p>{translate('asTag.clickTagCopy')}</p>
                <br />
                <br />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col>
                <Form.Item>
                  <Button loading={loadingButton} type="primary" disabled={isDisabled()} htmlType="submit" >
                    {state.id ? translate('default.save') : translate('default.add')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Col>
            <Table
              size="small"
              rowKey={(record, index) => index}
              columns={columns}
              dataSource={data}
              pagination={false}
              loading={loading}
            />
          </Col>
        </>
        : (
          <AlertNotAllow />
        )}
    </Content>
  )
}
