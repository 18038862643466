import React, { useEffect, useState, useCallback } from 'react'
import { Tabs, Button, Layout } from 'antd';
import { storeContext } from '../context';
import translate from '../i18n/translate';
import { Bus } from '../helpers/utils';
const { TabPane } = Tabs;
const { Content } = Layout;

export default function CustomTabs({ children }) {
  const store = React.useContext(storeContext);
  const [loading, setLoading] = useState(false);
  const [childs] = useState(children.map(child => ({ ...child, key: 0 })));

  function disabledTab(disabled) {
    return disabled ? (!store.currentItem || !store.currentItem.id) : false;
  }

  const onReload = useCallback((index) => {
    childs[index].key += 1;
    setLoading(true);
    setTimeout(() => setLoading(false), 1000);
  }, [childs]);

  useEffect(() => {
    Bus.addListener("reloadCustomTabs", () => {
      console.log('reloadCustomTabs');
      childs.forEach((element, index) => {
        if (index) onReload(index);
      });
    });
  }, [childs, onReload]);

  function renderButtonReload(index, child) {
    console.log('Custom Tab --->', index, child);
    if (child.props.hideReload) return;
    return (
      <Content className="padding-20" style={{ marginBottom: -20 }}>
        <Button loading={loading} key={index} onClick={() => onReload(index)} icon="reload">
          {translate('default.reload')}
        </Button>
      </Content>
    )
  }

  return (
    <Tabs defaultActiveKey="0">
      {childs.map((child, index) => (
        <TabPane tab={translate(child.props.tab)} key={index} disabled={disabledTab(child.props.disabled)}>
          <>
            {renderButtonReload(index, child)}
            <div key={child.key}> {child}</div>
          </>
        </TabPane>
      ))}
    </Tabs>
  )
}
