import React, { useState, useEffect, useCallback } from 'react'
import { storeContext } from '../../context';
import { Layout, Button, Table, Col, Input, Row, Form, Select } from 'antd';
import translate from '../../i18n/translate';
import api from '../../helpers/api';
import Confirm from '../Confirm/Confirm';
import AlertNotAllow from '../AlertNotAllow';
import { isAuthorized } from '../../helpers/verifyStatusErrorResponse';
const { Content } = Layout;
const { Option } = Select;
const labelCol = {
    xs: 24,
    sm: 24,
    md: 8,
    lg: 8,
    xl: 6,
};

const labelColValue = {
    xs: 24,
    sm: 24,
    md: 16,
    lg: 16,
    xl: 12,
};

const types = [
    'secret',
    'environment',
    'settings',
    'tag'
];
const INIT_VALUE = { key_type: null };
export default function ApplicationSettings({ settings_key = 'application' }) {
    const store = React.useContext(storeContext);
    const [data, setData] = useState([]);
    const [state, setState] = useState(INIT_VALUE);
    const [loading, setLoading] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [permission, setPermission] = useState(true);

    const columns = [
        {
            title: "ID",
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: translate('default.keyType'),
            dataIndex: 'key_type',
            key: 'key_type'
        },
        {
            title: translate('default.key'),
            dataIndex: 'key',
            key: 'key'
        },
        {
            title: translate('default.value'),
            dataIndex: 'value',
            key: 'value',
            render: (text, record, index) => record.key_type === 'secret' && text ? '*********************' : text
        },
        {
            title: '',
            align: 'right',
            key: 'action',
            width: 200,
            render: (text, record, index) => (
                <>
                    <Button icon="edit" type="default" onClick={() => setState({ ...record, index })} htmlType="button" style={{ marginRight: 10 }} />
                    <Button icon="delete" type="danger" onClick={() => Confirm({ func: () => remove(index) })} htmlType="button" />
                </>
            ),
        }
    ];

    async function remove(index) {
        try {
            setLoading(true);
            await api.delete(`/${settings_key}_settings/${data[index].id}`);
            const temp = [...data];
            temp.splice(index, 1);
            setData(temp);
        } catch (error) {
            const authorized = isAuthorized(error);
            if (!authorized) {
                return setPermission(false);
            }
            store.error = { message: translate('default.errorOccurred') }
        }
        setLoading(false);
    }

    async function onAdd() {
        const model = {
            ...state,
            [`${settings_key}_id`]: store.currentItem.id
        }
        const result = await api.post(`/${settings_key}_settings`, model);
        state.id = result.data.result.id;
        const temp = [...data, state];
        setData(temp);
        setState(INIT_VALUE);
    }

    async function onUpdate() {
        const index = state.index;
        delete state.index;
        const model = {
            ...state,
            [`${settings_key}_id`]: store.currentItem.id
        }
        await api.put(`/${settings_key}_settings/${state.id}`, model);
        data[index] = state;
        setData(data);
        setState(INIT_VALUE);
    }

    async function onSubmit(event) {
        setLoadingButton(true);
        event.preventDefault();
        setLoading(true);
        try {
            if (state.id) {
                await onUpdate();
            } else {
                await onAdd();
            }
        } catch (error) {
            const authorized = isAuthorized(error);
            if (!authorized) {
                return setPermission(false);
            }
            store.error = { message: translate('default.errorOccurred') }
        }
        setLoadingButton(false);
        setLoading(false);
    }

    function isDisabled() {
        return !state || !state.key || !state.key_type;
    }

    const list = useCallback(async () => {
        setLoading(true);
        try {
            const data = await api.get(`/${settings_key}_settings/${settings_key}/${store.currentItem.id}`);
            if (data.data && data.data.result) {
                return setData(data.data.result);
            }
            setData([]);
        } catch (error) {
            const authorized = isAuthorized(error);
            if (!authorized) {
                return setPermission(false);
            }
            store.error = { message: translate('default.errorOccurred') }
        } finally {
            setLoading(false);
        }
    }, [store.currentItem.id, store.error, settings_key]);

    useEffect(() => {
        list();
    }, [list]);

    return (
        <Content className="padding-20">
            {permission ?
                <>
                    <Form layout="vertical" onSubmit={onSubmit}>
                        <Row gutter={16}>
                            <Col {...labelCol}>
                                <Form.Item label={translate('default.key')} required>
                                    <Input value={state.key}
                                        onChange={(e) => {
                                            const key = e.target.value;
                                            setState(prevState => ({ ...prevState, key }));
                                        }} />
                                </Form.Item>
                            </Col>
                            <Col {...labelCol}>
                                <Form.Item label={translate('default.keyType')} required>
                                    <Select defaultValue={null}
                                        value={state.key_type} style={{ width: '100%' }}
                                        onChange={(key_type) => setState(prevState => ({ ...prevState, key_type }))} >
                                        <Option key={-1} value={null}>{translate('default.select.keyType')}</Option>
                                        {types.map(x => <Option key={x}>{x}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col {...labelColValue}>
                                <Form.Item label={translate('default.value')}>
                                    <Input value={state.value}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setState(prevState => ({ ...prevState, value }));
                                        }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col>
                                <Form.Item label={translate('default.comment')}>
                                    <Input.TextArea value={state.comment} rows={4}
                                        onChange={(e) => {
                                            const comment = e.target.value;
                                            setState(prevState => ({ ...prevState, comment }))
                                        }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col>
                                <Form.Item>
                                    <Button loading={loadingButton} type="primary" disabled={isDisabled()} htmlType="submit" >
                                        {state.id ? translate('default.save') : translate('default.add')}
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <Col>
                        <Table
                            loading={loading}
                            size="small"
                            rowKey={record => record.id}
                            columns={columns}
                            dataSource={data}
                            pagination={false}
                            expandedRowRender={record => (
                                <>
                                    <h3>{translate('default.comment')}</h3><br />
                                    {record.comment}
                                </>
                            )}
                        />
                    </Col>
                </>
                : (
                    <AlertNotAllow />
                )}
        </Content>
    )
}
