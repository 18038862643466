import React, { useState } from 'react';
import translate from '../../i18n/translate';
import { useEffect } from 'react';
import { Select, Row, Col } from 'antd';
import api from '../../helpers/api';

const { Option } = Select;

const Statistics = () => {

  const [response_url, setResponse_url] = useState([]);
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(false);

  const defaultPage = 'https://www.enablers.com.br';

  const handleChange = (value) => {
    setValue(value);
  }

  useEffect(() => {
    (
      async () => {
        try {
          setLoading(true);
          const response_url = await api.get('/statistic_page/my_pages');
          setResponse_url(response_url.data.result);
        } finally {
          setLoading(false);
        }
      })();

  }, []);

  return (
    <>
      <Row gutter={[1, 75]}>

        <Col span={48}>
          <Select style={{ width: '100%', marginBottom: 20 }} defaultValue={translate('statistics.placeholder')} onChange={handleChange} loading={loading}>
            {response_url.map(option => (
              <Option key={option.id} value={option.url}>{option.name}</Option>
            ))}
          </Select>
        </Col>
      </Row >
      <iframe src={value ? value : defaultPage} width="100%" height="1080" scrolling='auto' frameBorder="0" marginHeight="0" marginWidth="0" seamless="1" title='Metabase'></iframe>
    </>
  )
}

export default Statistics;
