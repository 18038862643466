import React, { useEffect, useState } from 'react';
import CustomTree from '../CustomTree';
import api from "../../helpers/api";

export default function PermissionsGroup({ func }) {

    const [group, setGroup] = useState([])

    useEffect(() => {
        api.get('item_permissions_group').then(data => {
            setGroup(buildItems(data.data.result));
        });
    }, []);

    function buildItems(permissionsGroup) {
        const result = [];
        Object.keys(permissionsGroup).forEach((element, index) => {
            const children = [];
            permissionsGroup[element].forEach((item, inx) => {
                children.push(
                    {
                        key: `${index}-1-${inx}`,
                        name: `${item.sys_permissions} - ${item.type === 'w' ? 'Write' : 'Read'}`,
                        sys_permissions: item.sys_permissions,
                        type: item.type
                    }
                )
            });
            result.push(
                {
                    key: `${index}-1`,
                    name: element,
                    children,
                }
            );

        });
        return result;
    }

    return (<CustomTree
        defaultCheckedKeys={[]}
        data={group}
        setFilteredTree={func}
        title="Select Permissions Group"
    />
    )
}
