
import React, { useEffect, useState } from 'react';
import { useObserver } from "mobx-react";
import { storeContext } from '../../context';

import {
  Form,
  Input,
  Row,
  Col
} from 'antd';

import RegistryForm from '../RegistryForm';
import CustomFilesUpload from '../CustomFilesUpload';
import api from '../../helpers/api';
import ActiveOnlyFilter from '../ActiveOnlyFilter';
import BoardTypeTags from './BoardTypeTags';


const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
];

const labelCol = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 10,
};

const labelCol24 = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
  xl: 20,
};

let ContentForm = () => {
  const store = React.useContext(storeContext);
  const [loading, setLoading] = useState(false);
  const handleChange = (field, event) => {
    store.currentItem[field] = event.target.value
  }

  function save({ fileUrl = '' }) {
    store.currentItem.imagePath = null;
    setLoading(true);
    api.put(`board_type/photo/${store.currentItem.id}`, { file_url: fileUrl  }).finally(() => {
      if (fileUrl) {
        store.currentItem.imagePath = `${fileUrl}?${new Date().getTime()}`;
      }
      setLoading(false);
    });
  }

  useEffect(() => {
    if (store.currentItem.photo) {
      store.currentItem.imagePath = `${store.currentItem.photo}?${new Date().getTime()}`;
    }
  }, [store.currentItem]);

  return useObserver(() => (
    <Form layout="vertical">
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="Id">
            <Input value={store.currentItem.id} disabled={true} />
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label="Name">
            <Input value={store.currentItem.name} onChange={handleChange.bind(this, 'name')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol24}>
          <Form.Item label="Description">
            <Input.TextArea rows={3} value={store.currentItem.description} onChange={handleChange.bind(this, 'description')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol} >
          <Form.Item label="Photo">
            <CustomFilesUpload
              uploadUrl={`/board_type/photo/${store.currentItem.id}`}
              imagePath={store.currentItem.imagePath}
              func={save}
              limit={1}
              loading={loading}
              disabled={!store.currentItem.id} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  ));
}

const BoardTypeList = () => {
  const store = React.useContext(storeContext);

  ContentForm = Form.create({})(ContentForm);

  store.editForm = undefined;
  store.columns = columns;
  store.url = '/board_type';
  store.filterDefault = { active: 1 };

  ContentForm.tab = 'Info';
  BoardTypeTags.tab = "Tags";

  store.tabs = [ContentForm, BoardTypeTags];

  useEffect(() => {
    store.customFilters = ActiveOnlyFilter;
    return () => {
      store.filterDefault = {};
      store.customFilters = null;
    }
  }, [store.customFilters, store.filterDefault]);

  return (
    <div>
      <RegistryForm />

    </div>
  )
}

export default BoardTypeList;
