
import React from 'react';
import { useObserver } from "mobx-react";
import { storeContext } from '../../context';
import {
  Form,
  Input,
  Row,
  Col,
  Collapse,
} from 'antd';
import RegistryForm from '../RegistryForm';
const { Panel } = Collapse;



const columns = [
  { title: 'ID', dataIndex: 'id', key: 'id',},
  { title: 'Chip ID', dataIndex: 'chip_id', key: 'chip_id',},
  { title: 'EUI', dataIndex: 'eui', key: 'eui',},
  { title: 'Order ID', dataIndex: 'order_id', key: 'order_id',},
  { title: 'Serial CM', dataIndex: 'serial_cm', key: 'serial_cm',},
];

const labelCol = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 10,
};

let ContentForm = () => {
  const store = React.useContext(storeContext);

  return useObserver(() => (
    <Form layout="vertical">
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="Id">
            <Input value={store.currentItem.id} disabled={true} />
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label="Order ID">
            <Input value={store.currentItem.order_id} disabled={true} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="EUI">
            <Input value={store.currentItem.eui} disabled={true} />
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label="Chip ID">
            <Input value={store.currentItem.chip_id} disabled={true} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="Serial CM">
            <Input value={store.currentItem.serial_cm} disabled={true} />
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label="Test Result">
            <Input value={store.currentItem.test_result} disabled={true} />
          </Form.Item>
        </Col>
      </Row>
        <Collapse>
          <Panel header="Test Log" key="1">
              <pre>
                {store.currentItem.log}
              </pre>
          </Panel>
        </Collapse>
    </Form>
  ));
}

const LoraoneModuleList = () => {
  const store = React.useContext(storeContext);

  ContentForm = Form.create({})(ContentForm);

  store.editForm = ContentForm;
  store.columns = columns;
  store.url = '/loraone_module';

  return (
    <div>
      <RegistryForm />
    </div>
  )
}

export default LoraoneModuleList;
