import React, { Component } from 'react';
import { Tree } from 'react-organizational-chart';
import { Card, Spin, Button } from 'antd';

import ChartCard from './ChartCard';
import api from '../../helpers/api';

class Orgchart extends Component {
    state = {
        data: [],
        loading: true,
        showAccount: false,
      }

    componentDidMount() {
        api.get('account_profile/orgtree')
            .then(response => {
                const data = response.data.result;
                this.setState({ data, loading: false });
            });
    }

    filterAccountsOut(data) {
        const result = data.map(element => {
            let filteredChildren = element.children?.filter(el => el.type);
            if(filteredChildren?.length > 0){
                filteredChildren = this.filterAccountsOut(filteredChildren);
            }
            return {...element, children: filteredChildren}
        });
        return result;
    }

    render() {
        let dataToUse;

        if(!this.state.showAccount) {
            dataToUse = this.filterAccountsOut(this.state.data);
        }else {
            dataToUse = this.state.data;
        }


        if( !this.state.loading) {
            return (
                <>
                    <Tree
                    lineWidth={'2px'}
                    lineColor={'grey'}
                    lineBorderRadius={'8px'}
                    label={<Card size='small' style={{display:'inline-block', border:'1px solid grey', borderRadius:5, backgroundColor:'lightgreen'}}> {this.state.data[0]?.name} </Card>}
                    >
                        <ChartCard data={dataToUse[0]?.children} setActiveTab={this.props.setActiveTab} showAccount={this.state.showAccount}/>
                    </Tree>
                    <Button 
                    onClick={() => {this.setState({showAccount: !this.state.showAccount})}} 
                    type='primary'
                    size='large'
                    style={{margin:'20px'}}> 
                            {this.state.showAccount ? 'Hide Accounts' :  'Show accounts'} 
                    </Button>
                </>
            );
        }

        return (
            (<div style={{ display: 'flex', justifyContent: 'center' }}><Spin /></div>)
        );
    }
}

export default Orgchart;