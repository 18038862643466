
import React from 'react';
import { useObserver } from "mobx-react";
import { storeContext } from '../../context';

import {
  Form,
  Input,
  Row,
  Col
} from 'antd';

import RegistryForm from '../RegistryForm';
import configuration from '../../config';
import CustomAutoComplete from '../CustomAutoComplete';
import CustomFilesUpload from '../CustomFilesUpload';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'SubType',
    dataIndex: 'subtype',
    key: 'subtype',
  },
];

const labelCol = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 10,
};

let ContentForm = () => {
  const store = React.useContext(storeContext);
  setImage();
  
  function setImage(){
    store.imagePath = `https://${configuration.s3_buckets.IMAG_UPLOAD}.s3.amazonaws.com/options/${store.currentItem.id}_device_options.jpg?${new Date().getTime()}`;
  }
  
  const handleChange = (field, event) => {
    store.currentItem[field] = event.target.value
  }

  const handleComboSelect = (field, value) => {
    store.currentItem[field] = value
  }

  const afterUpload = (time) => {
    console.log('afterUpload', time);
    setImage();
  };

  return useObserver(() => (
    <Form layout="vertical">
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="Id">
            <Input value={store.currentItem.id} disabled={true} />
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label="Name">
            <Input value={store.currentItem.name} onChange={handleChange.bind(this, 'name')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="Type">
            <CustomAutoComplete
              searchValue={'name'}
              searchKey={'name'}
              path={'/device_options_type'}
              onSelect={handleComboSelect.bind(this, 'type')}
              value={store.currentItem.type} />
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label="SubType">
            <CustomAutoComplete
              searchValue={'name'}
              searchKey={'name'}
              path={'/device_options_subtype'}
              onSelect={handleComboSelect.bind(this, 'subtype')}
              value={store.currentItem.subtype} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="Photo">
            <CustomFilesUpload
              uploadUrl={`/device_options/${store.currentItem.id}/photo`}
              imagePath={store.imagePath}
              disabled={!store.currentItem.id}
              func={afterUpload.bind(this)}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  ));
}

const DeviceOptionsList = () => {
  const store = React.useContext(storeContext);

  ContentForm = Form.create({})(ContentForm);

  store.editForm = ContentForm;
  store.columns = columns;
  store.url = '/device_options';

  return(
    <div>
      <RegistryForm />

    </div>
    )
  }

export default DeviceOptionsList;
