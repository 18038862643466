import React, { useEffect, useCallback, useState, useContext } from 'react';
import api from '../../helpers/api';
import { getError } from '../../helpers/verifyStatusErrorResponse';
import { Alert, Layout } from 'antd';
import AlertNotAllow from '../AlertNotAllow';
import { storeContext } from '../../context';
import SpinInfo from '../SpinInfo';
import translate from '../../i18n/translate';
import { diffValueJSON } from '../../helpers/utils';
import NewMigration from './NewMigration';
import TableTransfer from '../TableTransfer';
const { Content } = Layout;


const columns = [
    {
        dataIndex: 'attr',
        title: translate('default.key'),
    },
    {
        dataIndex: 'value',
        title: translate('default.value'),
        render: (text) => {
            return (
                <>
                    {typeof text === 'boolean' || text ? String(text) : ""}
                </>
            )
        },
    },
];

export default function Migration({ url }) {
    const store = useContext(storeContext);
    const [loading, setLoading] = useState(true); 
    const [permission, setPermission] = useState(true);
    const [permissionTable, setPermissionTable] = useState(false);
    const [dataSourceKeys, setDataSourceKeys] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [isCreate, setCreate] = useState(false);

    const getData = useCallback(async () => {
        try {
            const { data: { result } } = await api.get(`as_device_mapping/migration/${url}/${store.currentItem.asDeviceMappingId}`);
            if (!result || result.isDeviceIdNull) {
                return setCreate(result || true);
            }
            const { valuesArray } = diffValueJSON(result.enablers, result.tago);
            setDataSource(valuesArray.values);
            setDataSourceKeys(valuesArray.keys);
        } catch (e) {
            setPermission(getError(e, store));
        }
        // eslint-disable-next-line
    }, [store.currentItem.asDeviceMappingId]);

    async function save(data) {
        try {
            setPermissionTable(true);
            await api.put(`as_device_mapping/migration/${url}/${store.currentItem.asDeviceMappingId}`, data);
            await getData();
        } catch (e) {
            setPermission(getError(e, store));
        } finally {
            setPermissionTable(false);
        }
    }

    function onChange(value, direction, keys = []) {
        const data = dataSource.filter(v => keys.includes(v.key));
        if (data.length) {
            const obj = {};
            data.forEach(v => obj[v.attr] = v.value);
            save({ data: obj, type: data[0].type === 'local' ? 'tago' : 'enablers' });
        }
    }

    const initialize = useCallback(async () => {
        setLoading(true);
        setCreate(false);
        await getData();
        setLoading(false);
    }, [getData]);

    useEffect(() => {
        initialize();
    }, [initialize]);

    function component() {
        if (!permission) {
            return <AlertNotAllow />
        }
        if (loading) {
            return <SpinInfo spinning={loading} delay={100} />
        }
        if (isCreate) {
            if (Object.assign(isCreate).isDeviceIdNull) {
                return <Alert
                    message={translate('default.info')}
                    description={translate('information.empty')}
                    type="info"
                />
            }
            return <NewMigration func={initialize} url={url} />;
        }
        return (
            <>
                <TableTransfer
                    loading={permissionTable}
                    dataSource={dataSource}
                    targetKeys={dataSourceKeys}
                    onChange={onChange}
                    filterOption={(inputValue, item) =>
                        item.title.indexOf(inputValue) !== -1 || item.tag.indexOf(inputValue) !== -1
                    }
                    leftColumns={columns}
                    rightColumns={columns}
                    titles={['Enablers', 'Tago']}
                />
            </>
        );
    }

    return (
        <>
            <Content className="padding-20">
                {component()}
            </Content>
        </>
    )
}
