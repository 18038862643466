import React, { Component } from 'react';

import { withRouter } from 'react-router';
import axios from 'axios'
import { Layout, Menu, Breadcrumb, Icon, Row, Col, Tooltip } from 'antd';
import configuration from '../config';
import api from '../helpers/api';
import localforage from 'localforage';
import LogoutConfirm from './LogoutConfirm';
import CustomSelectMenu from './CustomSelectMenu/CustomSelectMenu';
import ApplicationAsFormList from './ApplicationAsForm/ApplicationAsFormList';
import { Bus } from '../helpers/utils';
import translate from '../i18n/translate';
import { storeContext } from '../context';
const { SubMenu } = Menu;

const { Sider, Content } = Layout;
const BASE_URLS = ['localhost', 'admindev'];
const PATH = window.location.origin.replace(/[^a-zA-Zs]/g, '.').split('.')[3];

class MenuScreen extends Component {
  static contextType = storeContext;
  state = {
    selectedMenu: this.context.menuItems[0].subMenuItems[0],
    menuItems: [],
    collapsed: false,
    isResizing: false,
    sidebarWidth: 200
  }

  onCollapse = () => {

    // this.setState({ collapsed });
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  startResizing = (event) => {
    event.preventDefault()
    this.setState({
      isResizing: true,
    });
  }

  stopResizing = () => {
    this.setState({
      isResizing: false,
    });
  }

  resize = (mouseMoveEvent) => {
    if(this.state.isResizing){
      if (mouseMoveEvent.clientX >200 && mouseMoveEvent.clientX < 400){
        this.setState({
          sidebarWidth: mouseMoveEvent.clientX,
        });
      }
      
    }
  }

  addAuxMenu = (aux, sub, result) => {
    const subMenuItems = [];
    this.buildItemsMenuRecursive(sub, subMenuItems, result);
    if (subMenuItems.length) {
      aux.push({
        title: sub.title,
        icon: sub.icon,
        subMenuItems
      });
    }
  }

  buildItemsMenuRecursive = (item, aux, result, asTypeForm = []) => {
    for (const sub of item.subMenuItems) {
      if (sub.subMenuItems && sub.subMenuItems.length) {
        this.addAuxMenu(aux, sub, result);
      } else if (sub.isAsTypeForm && asTypeForm.length) {
        sub.subMenuItems = asTypeForm.map((v, index) => ({ title: v.name, component: (<ApplicationAsFormList valuesForm={v} key={index} />), id: v.id, url: `/as_device_mapping/as_form/${v.id}`  }));
        this.addAuxMenu(aux, sub, result);
      } else if (!sub.permission || result.some(p => p.permission === sub.permission && p.type === 'r')) {
        aux.push(sub);
      }
    }
  }

  buildItemsMenu = (result = [], asTypeForm) => {
    const menuItems = [];
    for (const item of this.context.menuItems) {
      const aux = [];
      this.buildItemsMenuRecursive(item, aux, result, asTypeForm);
      if (aux.length) {
        menuItems.push({
          title: item.title,
          icon: item.icon,
          subMenuItems: aux
        });
      }
    }
    return this.setState({ menuItems });
  }

  buildMenu = (data, asTypeForm) => {
    const result = data.data.result || [];
    if (!result) {
      return this.setState({ menuItem: [] });
    }
    this.props.setPermissions(result);
    if (result.some(p => p.permission === '*' && p.type === 'r')) {
      return this.setState({ menuItems: this.context.menuItems });
    }
    return this.buildItemsMenu(result, asTypeForm);
  }

  initialize = async () => {
    try {
      const [data, asTypeForm] = await Promise.all([api.get('account/permissions'), api.get('/as_type/as_form/client')]);
      this.buildMenu(data, asTypeForm.data.result);
    } catch (error) {
      if (!error.response || error.response.status === 401) {
        await localforage.removeItem('token');
        await localforage.removeItem('hasAccountProfileId');
        window.location.reload(true);
      } else {
        this.buildItemsMenu();
      }
    }
  }

  addOrUpdateMenuAsType = async () => {
    const result = await api.get('/as_type/as_form/client');
    const menuItems = this.state.menuItems;
    menuItems.forEach(el => {
      el.subMenuItems.forEach(item => {
        if (item.subMenuItems && item.title === translate('application.name')) {
          if (!result.data || !result.data.result || !result.data.result.length) {
            item.subMenuItems = [];
          } else {
            item.subMenuItems = result.data.result.map((v, index) => ({ title: v.name, component: (<ApplicationAsFormList key={index} valuesForm={v} />), id: v.id, url: `/as_device_mapping/as_form/${v.id}` }));
          }
          return;
        }
      });
    });
    this.setState({ menuItems });
  }

  componentDidMount() {
    this.initialize();
    Bus.addListener("addOrUpdateMenuAsType", () => this.addOrUpdateMenuAsType());
    this.selectMenu();
    window.addEventListener("mousemove", this.resize);
    window.addEventListener("mouseup", this.stopResizing);
  }

  componentWillUnmount() {
    window.removeEventListener("mousemove", this.resize);
    window.removeEventListener("mouseup", this.stopResizing);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.selectMenu();
    }
  }

  onMenuItemClick = async (menuItem) => {
    // if (this.state.selectedMenu.title === menuItem.title) {
    //   console.log('same menu');

    //   return;
    // }
    // this.setState({ selectedMenu: menuItem })
    this.props.onItemClick();
    this.props.history.push(menuItem.url);
  }

  redirectHome = (event) => {
    this.onMenuItemClick(this.context.menuItems[0].subMenuItems[0]);
    event.preventDefault();
  }

  redirectPage = (key) => {
    for (const element of this.state.menuItems) {
      const p = element.subMenuItems.find(p => p.key === key);
      if (p) {
        this.setState({ selectedMenu: p });
        break;
      }
    }
  }

  renderRibbon = () => {
    if (BASE_URLS.includes(PATH)) {
      return (
        <div className="ribbon">
          <a href="/#/">
            <span>Development</span>
          </a>
        </div>
      )
    }
    return false;
  }

  menuRecursiveRender = (value, index) => {
    return <SubMenu
      key={`${value.title}_i_${index}`}
      title={<span><Icon type={value.icon} />{value.title}</span>}>
      {value.subMenuItems.map((subItem, idx) => {
        if (subItem.subMenuItems && subItem.subMenuItems.length) {
          return this.menuRecursiveRender(subItem, idx);
        } else if (!subItem.isAsTypeForm && subItem.title) {
          return (
            <Menu.Item
              key={`${subItem.title}_s_${idx}`}
              onClick={() => this.onMenuItemClick(subItem)}>
              <Tooltip placement="top" title={subItem.title.length > 21 ? subItem.title : null} key={`${subItem.title}_s_${idx}`}>
              {subItem.title}
              </Tooltip>

            </Menu.Item>
          )
        }
        return null;
      })}
    </SubMenu>
  }

  menuRender = () => {
    const { menuItems } = this.state;
    return menuItems.map((value, index) => this.menuRecursiveRender(value, index));
  }

  searchSubMenuItem(url, item) {
    let result;
    if(item.subMenuItems && item.subMenuItems.length > 0){
      item.subMenuItems.forEach(subItem => {
        if(!result) {
          result = this.searchSubMenuItem(url, subItem);
        }
      });
    }
    return (result) ? result : (item.url === url) ? item : null;
  }

  findMenuItemByUrl(url) {
    const menuItems = this.state.menuItems.length ? this.state.menuItems : this.context.menuItems;
    let result;
    menuItems.forEach(item => {
      if(!result){
        result = this.searchSubMenuItem(url, item);
      }
    });
    return result;
  }

  parseUrl() {
    let url;
    let itemId;
    const urlArr = this.props.history.location.pathname.split('/');
    if(urlArr[2] === 'client_sn' || urlArr[2] === 'client_data'){
      url = `/${urlArr[1]}/${urlArr[2]}`;
      itemId = urlArr[3];
    }else if(urlArr[1] === 'as_device_mapping') {
      url = `/${urlArr[1]}/${urlArr[2]}/${urlArr[3]}`;
      itemId = urlArr[4];
    }else if(urlArr[1] === 'equipment') {
      url = `/${urlArr[1]}`;
      if(urlArr[2] && urlArr[3]){
        itemId = `${urlArr[2]}/${urlArr[3]}`;
      }else {
        itemId = undefined;
      }
    }else if(urlArr[1] === 'product_installation' && urlArr[2] === 'validation') {
      console.log('product_installation');
      url = `/${urlArr[1]}/${urlArr[2]}`;
      if(urlArr[3]){
        itemId = `${urlArr[3]}`;
      }
    }else {
      url = `/${urlArr[1]}`;
      itemId = urlArr[2];
    }
    return [url, itemId];
  }

  async checkItemId(path, itemId){
    const url = `${configuration.URL}${path}/${itemId}`;
    console.log(url)
    const options = {
      headers: {
        token: global.token,
      }
    };

    let data = await axios.get(url, options).then(r => r.data).catch(async e => {
      if (e.message === 'Request failed with status code 401') {
        await localforage.removeItem('token');
        await localforage.removeItem('hasAccountProfileId');
        window.location.reload(true);
      }
    }); 

    const currentItem = data.result;
    if (currentItem && !currentItem.id) {
      currentItem.id = `${currentItem.deploy_id}/${currentItem.client_sn}`
    }
    this.context.currentItem = currentItem;
    this.context.currentItemBkp = currentItem;
  }

  selectMenu(){
    const [url, itemId] = this.parseUrl();
    this.context.itemId = itemId;
    let menuItem = null;
    console.log('Select Menu item ID --> ', itemId);
    console.log('Tipo item ID --> ', typeof itemId);

    if(!itemId) {
      this.context.currentItem = undefined;
      this.context.currentItemBkp = undefined;
    }
 
    menuItem = this.findMenuItemByUrl(url);
 
    if(menuItem){
      if(itemId){
        this.checkItemId(menuItem.url, itemId);
      }
      this.setState({ selectedMenu: menuItem });
    }else{
      this.props.history.push('/');
    }
  }

  render() {
    const { collapsed, sidebarWidth } = this.state;
    return (
      <Layout>

        <div >
          {this.renderRibbon()}
          <Row style={{ backgroundColor: '#1E8A9A', height: 65 }}>
            <Col span={12}>
              <a href="/#/" onClick={this.redirectHome}>
                <img src={process.env.PUBLIC_URL + '/favicon.ico'} alt=""
                  style={{ margin: 5, marginLeft: 25, height: 55, objectFit: 'scale-down' }} />
              </a>
              <CustomSelectMenu />
            </Col>
            <Col span={12}
              style={{
                marginLeft: 'auto',
                marginRight: 0
              }}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Tooltip placement="left" title="Logout">
                  <Icon type="logout" style={{ fontSize: 45, margin: 5, display: 'flex', marginTop: 10 }}
                    onClick={() => LogoutConfirm()} />
                </Tooltip>
              </div>
            </Col>
          </Row>
        </div>

        <Layout>
        <Sider width={sidebarWidth} className="collapsible" collapsible collapsed={collapsed} onCollapse={this.onCollapse} style={{ background: '#fff', padding: '0'}}>
            <div style={{display: 'flex', height: '100%'}}>
              <Menu  
                mode="inline"
                defaultSelectedKeys={['1']}
                defaultOpenKeys={['sub1']}
                style={{ height: '100%', borderRight: 0, flex: '1' }}>
                                  
                  {this.menuRender()}                            
              </Menu>
              <div onMouseDown={this.startResizing} style={{flexGrow: '0', flexShrink: '0', flexBasis:'6px', background:'darkgrey', cursor:'col-resize', resize: 'horizontal'}}></div>
            </div>
          </Sider>
          <Layout style={{ padding: '0 38px 24px' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>{this.state.selectedMenu.title}</Breadcrumb.Item>
            </Breadcrumb>
            <Content
              style={{
                background: '#fff',
                padding: 29,
                margin: 0,
                minHeight: 750,
              }}>
              <div>
                {this.state.selectedMenu.component}
              </div>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    )
  }
}

export default withRouter(MenuScreen);
