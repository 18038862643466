import React, { useState, useEffect } from 'react'
import { Tree, Form, Button } from 'antd';
const { TreeNode } = Tree;

export default function CustomTree({ onSubmit, data, setFilteredTree, defaultCheckedKeys, loading,
    disabled, labelButton, title = "Select All", checkedKeys }) {
    const [newCheckedKeys, setNewCheckedKeys] = useState([]);

    function filterTree(keys, halfKeys, rootNode) {
        return rootNode
            ? rootNode
                .filter(node => keys.includes(node.key) || halfKeys.includes(node.key))
                .map(nodeRoot => ({
                    ...nodeRoot,
                    children: filterTree(keys, halfKeys, nodeRoot.children)
                }))
            : [];
    }

    function onCheck(checkedKeys, info) {
        const filteredTree = filterTree(checkedKeys, info.halfCheckedKeys, data);
        setNewCheckedKeys(checkedKeys);
        setFilteredTree(filteredTree);
    };

    function renderTreeNodes(values) {
        return values.map(item => {
            if (item.children) {
                return (
                    <TreeNode title={item.name} key={item.key} dataRef={item}>
                        {renderTreeNodes(item.children)}
                    </TreeNode>
                );
            }
            return <TreeNode key={item.key} title={item.name} dataRef={item} />;
        });
    }

    useEffect(() => {
        if (checkedKeys) {
            if (Array.isArray(checkedKeys)) {
                onCheck(checkedKeys, { halfCheckedKeys: ['0-0'] })
            } else {
                onCheck(checkedKeys.checkedKeys, { halfCheckedKeys: checkedKeys.halfCheckedKeys })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkedKeys]);

    function hasButton() {
        return (labelButton && onSubmit);
    }

    function treeComponent() {
        return data.length ? (
            <>
                <Form.Item>
                    <Tree checkable defaultExpandedKeys={['0-0']} onCheck={onCheck}
                        defaultCheckedKeys={newCheckedKeys}
                        checkedKeys={newCheckedKeys}>
                        <TreeNode title={title} key="0-0">
                            {renderTreeNodes(data)}
                        </TreeNode>
                    </Tree>
                </Form.Item>
                <Form.Item>
                    {hasButton() &&
                        <Button loading={loading} htmlType="submit" type="primary" disabled={disabled}>{labelButton}</Button>
                    }
                </Form.Item>
            </>
        ) : (
                'Loading Tree'
            )
    }

    return hasButton() ? (
        <Form onSubmit={onSubmit} className="ant-advanced-search-form" style={{ marginLeft: 20 }} >
            {treeComponent()}
        </Form>)
        : treeComponent()
}
