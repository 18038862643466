import React, { useState, useEffect } from 'react'
import api from '../../helpers/api'
import { storeContext } from '../../context';
import { Layout, Input, Row, Col, Form, Collapse } from 'antd';
import { formatDate } from "../../helpers/date";
import SpinInfo from '../SpinInfo';
const { Content } = Layout;
const { Panel } = Collapse;

const labelCol = {
    xs: 24,
    sm: 24,
    md: 8,
    lg: 8,
    xl: 8,
};

export default function LogProduct() {
    const store = React.useContext(storeContext);
    const [product, setProduct] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        api.get(`/product/log/${store.currentItem.id}`)
            .then(data => {
                setProduct(data.data.result);
            }).finally(() => setLoading(false));
    }, [store.currentItem.id])

    return (
        <Content className="padding-20"  >
            {product ? (
                <>
                    <Row gutter={16}>
                        <Col {...labelCol}>
                            <Form.Item label="Date Manufacture">
                                <Input value={formatDate(product.date_manufacture)} disabled />
                            </Form.Item>
                        </Col>
                        <Col  {...labelCol}>
                            <Form.Item label="Date Firmware Update">
                                <Input value={formatDate(product.date_firmware_update)} disabled />
                            </Form.Item>
                        </Col>
                        <Col  {...labelCol}>
                            <Form.Item label="Date Test">
                                <Input value={formatDate(product.date_test)} disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Collapse  >
                        <Panel header="Test Log" key="1">
                            <pre>
                                {product.test_log}
                            </pre>
                        </Panel>
                        <Panel header="Comment" key="2">
                            <pre>
                                {product.comment}
                            </pre>
                        </Panel>
                    </Collapse>
                </>
            ) : (
                    <SpinInfo spinning={loading} delay={100} />
                )}
        </Content>
    )
}
