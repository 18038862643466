
import React, { useRef, useEffect } from 'react';
import { useObserver } from "mobx-react";
import { storeContext } from '../../context';
import CustomAutoComplete from '../CustomAutoComplete';
import {
  Form,
  Input,
  Row,
  Col,
  Switch,
} from 'antd';

import RegistryForm from '../RegistryForm';

const columns = [
  { title: 'Order ID', dataIndex: 'id', key: 'id', sorter: true, },
  { title: 'Description', dataIndex: 'order_description', key: 'order_description', sorter: true, },
  { title: 'Client', dataIndex: 'client_name', key: 'client_name', sorter: true  },
  { title: 'Device Type', dataIndex: 'device_type_name', key: 'device_type_name', sorter: true, },
  { title: 'Firmware', dataIndex: 'firmware_type_name', key: 'firmware_type_name', sorter: true, },
  { title: 'Manufacturer', dataIndex: 'manufacturer_name', key: 'manufacturer_name', sorter: true, },
  { title: 'Quantity', dataIndex: 'quantity', key: 'quantity', sorter: true, },
  { title: 'Produced', dataIndex: 'produced', key: 'produced', sorter: true, },
];

const labelCol = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 10,
};

let ContentForm = () => {
  const firmwareVersionRef = useRef();
  const store = React.useContext(storeContext);

  const handleChange = (field, event) => {
    store.currentItem[field] = event.target.value
  }

  const handleComboSelect = (field, value) => {
    store.currentItem[field] = value
  }

  const handleSwitchChange = (field, checked) => {
    store.currentItem[field] = checked ? 1 : 0;
  }

  const handleSelectFirmwareType = async (field, value) => {
    store.currentItem.firmware_version_id = undefined;
    store.currentItem[field] = value
  }

  useEffect(() => {
    if (typeof store.currentItem.jig_screen === 'object') {
      store.currentItem.jig_screen = JSON.stringify(store.currentItem.jig_screen, null, 2);
    }
  }, [store.currentItem.jig_screen])

  return useObserver(() => (
    <Form layout="vertical">
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="Id">
            <Input value={store.currentItem.id} disabled={true} />
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label="Description">
            <Input value={store.currentItem.order_description} onChange={handleChange.bind(this, 'order_description')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="Client">
            <CustomAutoComplete
              searchValue={'id'}
              searchKey={'name'}
              path={'/client'}
              placeholder={'Select the client'}
              onSelect={handleComboSelect.bind(this, 'client_id')}
              value={store.currentItem.client_id} />
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label="Device Type">
            <CustomAutoComplete
              searchValue={'id'}
              searchKey={'name'}
              path={'/device_type'}
              placeholder={'Select the device type'}
              onSelect={handleComboSelect.bind(this, 'device_type_id')}
              value={store.currentItem.device_type_id} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="Firmware">
            <CustomAutoComplete
              searchValue={'id'}
              searchKey={'name'}
              path={'/firmware_type'}
              placeholder={'Select the firmware type'}
              onSelect={handleSelectFirmwareType.bind(this, 'firmware_type_id')}
              value={store.currentItem.firmware_type_id} />
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label="Firmware Version">
            <CustomAutoComplete
              key={store.currentItem.firmware_type_id}
              ref={firmwareVersionRef}
              searchValue={'id'}
              searchKey={'version_number'}
              path={`/firmware_type/${store.currentItem.firmware_type_id}/version`}
              placeholder={'Select the firmware version'}
              onSelect={handleComboSelect.bind(this, 'firmware_version_id')}
              value={store.currentItem.firmware_version_id} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="Manufacturer">
            <CustomAutoComplete
              searchValue={'id'}
              searchKey={'name'}
              path={'/manufacturer'}
              placeholder={'Select the manufacturer'}
              onSelect={handleComboSelect.bind(this, 'manufacturer_id')}
              value={store.currentItem.manufacturer_id} />
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label="Application">
            <CustomAutoComplete
              searchValue={'id'}
              searchKey={'name'}
              path={'/application'}
              placeholder={'Select the Application'}
              onSelect={handleComboSelect.bind(this, 'application_id')}
              value={store.currentItem.application_id} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="Quantity">
            <Input value={store.currentItem.quantity} onChange={handleChange.bind(this, 'quantity')} />
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label="JIG Screen Name">
            <Input.TextArea value={store.currentItem.jig_screen} onChange={handleChange.bind(this, 'jig_screen')} rows={8} style={{ resize: 'none' }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol} >
          <Form.Item label="Active?" className="margin-top-active-order">
            <Switch onChange={handleSwitchChange.bind(this, 'active')} checked={Boolean(store.currentItem.active)} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  ));
}

const ClientList = () => {
  const store = React.useContext(storeContext);

  ContentForm = Form.create({})(ContentForm);

  store.editForm = ContentForm;
  store.columns = columns;
  store.url = '/order';

  return (
    <div>
      <RegistryForm />
    </div>
  )
}

export default ClientList;
