import React, { useState, useEffect, useCallback } from 'react'
import { storeContext } from '../../context';
import { Layout, Button, Table, Col, Row, Form, Input } from 'antd';
import translate from '../../i18n/translate';
import api from '../../helpers/api';
import { getError } from '../../helpers/verifyStatusErrorResponse';
import AlertNotAllow from '../AlertNotAllow';
import Confirm from '../Confirm/Confirm';
import CustomAutoComplete from '../CustomAutoComplete';
const { Content } = Layout;
const labelCol = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 10,
};

const DATA_DEFAULT = { count: 0, result: [] };
const PAGINATION_DEFAULT = { pageSize: 15, current: 0 };

export default function AsLevel() {
    const store = React.useContext(storeContext);
    const [data, setData] = useState(DATA_DEFAULT);
    const [state, setState] = useState({});
    const [permission, setPermission] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: translate('default.name'),
            dataIndex: 'name',
            key: 'name',
            sorter: true,
        },
        {
            title: translate('default.description'),
            dataIndex: 'description',
            key: 'description',
            sorter: true,
        },
        {
            title: translate('application.name'),
            dataIndex: 'application',
            key: 'application',
            sorter: true,
        },
        {
            title: translate('default.parent'),
            dataIndex: 'parent',
            key: 'parent',
        },
        {
            title: '',
            align: 'right',
            key: 'action',
            width: 200,
            render: (text, record, index) => (
                <>
                    <Button icon="edit" type="default" onClick={() => setState({ ...record, index })} htmlType="button" style={{ marginRight: 10 }} />
                    <Button icon="delete" type="danger" onClick={() => Confirm({ func: () => remove(index) })} htmlType="button" />
                </>
            ),
        }
    ];

    async function remove(index) {
        try {
            setLoading(true);
            await api.delete(`/as_level/${data.result[index].id}`);
            await initialize(PAGINATION_DEFAULT);
            setState({});
        } catch (e) {
            setPermission(getError(e, store));
        }
        setLoading(false);
    }

    async function onAdd() {
        const model = {
            ...state,
            application_id: store.currentItem.id,
            application: store.currentItem.name
        }
        await api.post('/as_level', model);
    }

    async function onUpdate() {
        delete state.index;
        const model = {
            ...state,
            application_id: store.currentItem.id,
            application: store.currentItem.name
        }
        await api.put(`/as_level/${state.id}`, model);
    }

    async function onSubmit(event) {
        event.preventDefault();
        try {
            setLoadingButton(true);
            setLoading(true);
            if (state.id) {
                await onUpdate();
            } else {
                await onAdd();
            }
            store.success = { message: translate('asLevel.save') }
            await initialize(PAGINATION_DEFAULT);
            setState({});
        } catch (e) {
            setPermission(getError(e, store));
        }
        setLoadingButton(false);
        setLoading(false);
    }

    function isDisabled() {
        return !state || !state.name || !state.description;
    }

    const initialize = useCallback(async ({ current = 0, pageSize = 15 }) => {
        setLoading(true);
        try {
            const offset = current ? ((pageSize * current) - pageSize) : 0;
            const data = await api.get(`/as_level?application=${store.currentItem.id}&limit=${pageSize}&offset=${offset}`);
            if (data.data && data.data.result) {
                return setData(data.data);
            }
            setData(DATA_DEFAULT);
        } catch (e) {
            setPermission(getError(e, store));
        } finally {
            setLoading(false);
        }
        // eslint-disable-next-line
    }, [store.currentItem.id]);

    useEffect(() => {
        initialize(PAGINATION_DEFAULT);
    }, [initialize]);

    return (
        <Content className="padding-20">
            {permission ?
                <>
                    <Form layout="vertical" onSubmit={onSubmit}>
                        <Row gutter={16}>
                            <Col {...labelCol}>
                                <Form.Item label="Id">
                                    <Input value={state.id} disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col {...labelCol}>
                                <Form.Item label={translate('default.name')} required>
                                    <Input value={state.name}
                                        onChange={(e) => {
                                            const name = e.target.value;
                                            setState(prevState => ({ ...prevState, name }));
                                        }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col {...labelCol}>
                                <Form.Item label={translate('default.description')} required>
                                    <Input value={state.description}
                                        onChange={(e) => {
                                            const description = e.target.value;
                                            setState(prevState => ({ ...prevState, description }));
                                        }} />
                                </Form.Item>
                            </Col>
                            <Col {...labelCol}>
                                <Form.Item label={translate('application.name')} required>
                                    <Input disabled={true} value={store.currentItem.name} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col {...labelCol}>
                                <Form.Item label={translate('default.parent')}>
                                    <CustomAutoComplete
                                        key={state.id}
                                        ignoredId={state.id}
                                        searchValue={'id'}
                                        searchKey={'name'}
                                        path={`/as_level/application/${store.currentItem.id}`}
                                        onSelect={(parent_id) => setState(prevState => ({ ...prevState, parent_id }))}
                                        value={state.parent_id} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col>
                                <Form.Item>
                                    <Button loading={loadingButton} type="primary" disabled={isDisabled()} htmlType="submit" >
                                        {state.id ? translate('default.save') : translate('default.add')}
                                    </Button>
                                    <Button htmlType="button" style={{ marginLeft: 10 }} onClick={() => setState({})}>
                                        {translate('default.cancel')}
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <Col>
                        <Table
                            size="small"
                            rowKey={(record, index) => index}
                            columns={columns}
                            dataSource={data.result}
                            loading={loading}
                            onChange={(pagination) => initialize(pagination)}
                            pagination={{
                                pageSize: 15,
                                total: data.count || 0
                            }}
                        />
                    </Col>
                </>
                : (
                    <AlertNotAllow />
                )}
        </Content>
    )
}
