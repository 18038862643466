
import React from 'react';
import { useObserver } from "mobx-react";
import { storeContext } from '../../context';
import {
  Form,
  Input,
  Row,
  Col,
  Tooltip,
  Icon,
  Divider,
} from 'antd';
import translate from '../../i18n/translate';
import RegistryForm from '../RegistryForm';
import CustomAutoComplete from '../CustomAutoComplete';
import { copyToClipboard } from '../../helpers/utils';
import Details from './Details';

const columns = [
  {
    title: 'Deploy',
    dataIndex: 'deploy_id',
    key: 'deploy_id',
  },
  {
    title: 'Serial do Cliente',
    dataIndex: 'client_sn',
    key: 'client_sn',
  },
  {
    title: 'Sensor',
    dataIndex: 'sensor',
    key: 'sensor',
  },
  {
    title: 'Endereço',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'Unidade',
    dataIndex: 'address_unit',
    key: 'address_unit',
  },
  {
    title: 'Complemento',
    dataIndex: 'address_complement',
    key: 'address_complement',
  },
  {
    title: 'CEP',
    dataIndex: 'zip',
    key: 'zip',
  },
  {
    title: 'Cidade',
    dataIndex: 'city',
    key: 'city',
  },
  {
    title: 'Estado',
    dataIndex: 'state',
    key: 'state',
  },
  {
    title: 'País',
    dataIndex: 'country',
    key: 'country',
  },
  {
    title: 'Tipo',
    dataIndex: 'equipment_type_name',
    key: 'equipment_type_name',
  }
];

const labelCol = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 10,
};

let ContentForm = () => {
  const store = React.useContext(storeContext);

  const handleChange = (field, event) => {
    store.currentItem[field] = event.target.value;
  }

  const handleSelect = (field, value) => {
    store.currentItem[field] = value;
  }
  
  return useObserver(() => (
    <Form layout="vertical">
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="Deploy">
            <CustomAutoComplete
              searchValue={'id'}
              searchKey={'name'}
              path={'/deploy'}
              placeholder={translate('default.select.name')}
              onSelect={handleSelect.bind(this, 'deploy_id')}
              value={store.currentItem.deploy_id} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="Client SN">
            <Input value={store.currentItem.client_sn} onChange={handleChange.bind(this, 'client_sn')} />
          </Form.Item>
        </Col>        
        <Col {...labelCol}>
          <Form.Item label="Client Generic ID">
            <Input value={store.currentItem.client_generic_id} onChange={handleChange.bind(this, 'client_generic_id')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="Latitude">
            <Input value={store.currentItem.lat} onChange={handleChange.bind(this, 'lat')} />
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label="Longitude">
            <Input value={store.currentItem.lng} onChange={handleChange.bind(this, 'lng')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="Tipo">
            <CustomAutoComplete
              searchValue={'id'}
              searchKey={'name'}
              path={'/equipment_type'}
              placeholder={translate('default.select.name')}
              onSelect={handleSelect.bind(this, 'equipment_type_id')}
              value={store.currentItem.equipment_type_id} />
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label="Endereço">
            <Input value={store.currentItem.address} onChange={handleChange.bind(this, 'address')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="Unidade">
            <Input value={store.currentItem.address_unit} onChange={handleChange.bind(this, 'address_unit')} />
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label="Complemento">
            <Input value={store.currentItem.address_complement} onChange={handleChange.bind(this, 'address_complement')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="CEP">
            <Input value={store.currentItem.zip} onChange={handleChange.bind(this, 'zip')} />
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label="Cidade">
            <Input value={store.currentItem.city} onChange={handleChange.bind(this, 'city')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="Estado">
            <Input value={store.currentItem.state} onChange={handleChange.bind(this, 'state')} />
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label="País">
            <Input value={store.currentItem.country} onChange={handleChange.bind(this, 'country')} />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="AS Device">
            <Input value={store.currentItem.as_device} onChange={handleChange.bind(this, 'as_device')}/>
          </Form.Item>
        </Col>
        {
          store.currentItem.as_device_params && (
            Object.entries(store.currentItem.as_device_params).map(([key, value], index) =>
              <Col  {...labelCol} key={index}>
                <Form.Item label={key}>
                  <Input value={value} disabled addonAfter={
                    <Tooltip placement="topLeft" title={translate('default.copy')}>
                      <Icon type="copy" onClick={() => copyToClipboard(value)} />
                    </Tooltip>
                  } />
                </Form.Item>
              </Col>
          ))
        }
      </Row>
    </Form>
  ));
}

const Equipments = () => {
  const store = React.useContext(storeContext);

  ContentForm = Form.create({})(ContentForm);

  store.columns = columns;
  store.url = '/equipment';

  store.editForm = undefined;
  ContentForm.tab = 'Info';
  Details.tab = "Details";

  store.tabs = [ContentForm, Details];

  return (
    <div>
      <RegistryForm />
    </div>
  )
}

export default Equipments;