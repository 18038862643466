import React from 'react';
import { Button, Form, Modal } from 'antd';
import translate from '../../../i18n/translate';

function ModalStatus({ buttons = [], alert, setAlert }) {

    function handleCancel() {
        setAlert({});
    }

    return (
        <>
            <Modal
                visible={!!alert.id}
                title={translate('alert.ticket.update')}
                onCancel={handleCancel}
                footer={[
                    ...buttons,
                    <Button key="back" onClick={handleCancel}>  Cancel </Button>,
                ]}>
                <Form layout="vertical">
                   {translate('alert.manageAlert')}{alert.title}?
                </Form>
            </Modal>
        </>
    )
}

export default ModalStatus;
