import React, { Component } from 'react';
import { Form, Icon, Input, Button, Row, Col } from  'antd';
import axios from 'axios';
import configuration from '../config';
import translate from '../i18n/translate';

class NormalLoginForm extends Component {
  state = {
    confirmDirty: false,
    password: '',
    loading: false,
    recovered: false,
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        return this.updatePassword(values.password);
      }
    });
    
  };

  onBackClick = e => {
    e.preventDefault();

    this.props.history.push('/')
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback(translate('password.confirmationError'));
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  validatePassword = (rule, value, callback) => {
    const regex = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{12,})');
    if (!regex.test(value)) {
      callback(translate('password.passwordError'));
    } else {
      callback();
    }
  };

  async updatePassword(password) {
    await this.setState({ loading: true });
    const url = `${configuration.URL}/account/reset_password`;

    const options = {
      method: 'POST',
      data: { password },
      headers: { token: this.state.recoveryToken },
      url,
    };
    await axios(options).then(r => r.data.result);

    await this.setState({ loading: false, recovered: true, });
  }

  handlePasswordChange = (event) => {
    this.setState({password: event.target.value});
  }

  componentDidMount() {
    console.log('Recover Path', window.location.pathname);
    this.setState({
      recoveryToken: window.location.pathname.replace('/pw/', ''),
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Row>
          <Col span={9}></Col>
          <Col span={6}>
            <img src="http://enablers.com.br/upload/logo_enablers2.png" style={{ width: 330, margin: 50 }} alt="Enablers"/>
          </Col>
          <Col span={9}></Col>
        </Row>
        <Row>
          <Col span={9}></Col>
          <Col span={6}>
            <Form onSubmit={this.handleSubmit} className="login-form">
              <Form.Item hasFeedback>
              {
                getFieldDecorator('password', {
                  rules: [
                    { 
                      required: true, 
                      message: translate('password.password')
                    },
                    {
                      validator: this.validateToNextPassword,
                    },
                    {
                      validator: this.validatePassword,
                    },
                  ],
                })(
                  <Input
                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder={translate('password.password')}
                  />
              )}
              </Form.Item>
              <Form.Item hasFeedback>
              {
                getFieldDecorator('confirm', {
                  rules: [
                    { 
                      required: true, 
                      message: translate('password.confirmation')
                    },
                    {
                      validator: this.compareToFirstPassword,
                    },
                  ],
                })(
                  <Input
                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder={translate('password.confirmation')}
                    onBlur={this.handleConfirmBlur}
                  />
              )}
              </Form.Item>
              <Form.Item
                style={{
                  flexDirection: 'row',
                  flex: 1,
                }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  disabled={this.state.recovered}
                  loading={this.state.loading}
                  style={{ flex: 1, display: 'inline-block' }}
                  >
                  Save New Password
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  disabled={!this.state.recovered}
                  loading={this.state.loading}
                  onClick={this.onBackClick}
                  style={{ marginLeft: 10, flex: 1, display: 'inline-block' }}
                  >
                  Back
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col span={9}></Col>
        </Row>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(NormalLoginForm);

export default WrappedNormalLoginForm;
