import React, { useEffect } from 'react';
import { Button } from 'antd';
import translate from '../../i18n/translate';
import { storeContext } from '../../context';
import api from '../../helpers/api';
import confirm from "../Confirm/Confirm";
import localforage from 'localforage';

export default function GenerateTokenButton({ id }) {
    const store = React.useContext(storeContext);
    const [state, setState] = React.useState({ id: null });
    const [permissions, setPermissions] = React.useState({});

    async function createToken() {
        try {
            setState(old => ({ ...old, loadingButton: true }));
            const { data } = await api.post(`/account/create_token`, { id });
            if (!data.token) throw data.message;
            if (!data.hasAccountProfileId) {
                await localforage.removeItem('hasAccountProfileId');
            }
            const token = data.token;
            await localforage.setItem('token', token);
            global.token = token;
            window.location.reload(true);
        } finally {
            setState(old => ({ ...old, loadingButton: false }));
        }
    }

    useEffect(() => {
        const hasAdminLogin = store.permissions.some(p => p.type === 'r' && p.permission === 'admin_login');
        setPermissions({ hasAdminLogin });
    }, [store.permissions]);

    return (
        <>
            {permissions.hasAdminLogin &&
                <Button type="primary"
                    style={{marginRight: '16px'}}
                    loading={state.loadingButton}
                    title={translate('user.generateToken')} onClick={() => confirm({ func: () => createToken(), content: translate('user.messageAccessAccount') })} htmlType="button">
                    {translate('user.accessAccount')}
                </Button>
            }
        </>
    )
}
