import React, { useState, useEffect } from 'react';
import { Upload, Button, Icon, Modal, Spin } from 'antd';
import moment from 'moment';
import api, { apiUrl } from '../helpers/api';
import { storeContext } from '../context';

export default function CustomFilesUpload({
    fileSingle,
    fileList,
    loading,
    deleteUrl,
    uploadUrl,
    func,
    limit = null,
    showRemoveIcon = true,
    showButtonUpload = true,
    disabled,
    fileName,
    imagePath,
    isTypeCard,
    funcDownload
   }) {
    const store = React.useContext(storeContext);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [previewPhoto, setPreviewPhoto] = useState('');
    const [removeId, setRemoveId] = useState(0);
    const [newFileList, setNewFileList] = useState([]);
    const [fileUrl, setFileUrl] = useState('');
    const [imgPath, setImgPath] = useState('');

    function handlePreview(file) {
        if (file && file.url) {
            if(funcDownload){
                funcDownload(file.url);
                return;
            }
            if ((/\.(jpg|png|gif|jpeg)/g).test(file.url)) {
                setPreviewPhoto(file.url);
                setPreviewVisible(true);
            } else {
                window.open(file.url);
            }
        }
    };

    function closeModal() {
        setPreviewVisible(false);
    }

    useEffect(() => {
        var img = new Image();
        img.src = imagePath;
        img.onload = () => setImgPath(imagePath);
        img.onerror = () => setImgPath('');
    }, [imagePath])

    useEffect(() => {
        if (fileList || fileSingle) {
            if (fileList) {
                setNewFileList(fileList);
            } else {
                setNewFileList([{
                    uid: '1',
                    url: fileSingle,
                    thumbUrl: fileSingle,
                    name: fileName || fileSingle,
                }])
            }
        }
    }, [fileList, fileSingle, fileName])

    function handleRemove() {
        api.delete(`${deleteUrl}/${removeId}`).then(() => {
            setNewFileList(newFileList.filter(x => x.id !== removeId));
            fileList.filter(x => x.id !== removeId)
            setRemoveId(null);
        });
    }

    function customRequest({ file }) {
        apiUrl({ baseUrl: file }).put(fileUrl, file, { processData: false })
            .then(() => {
                if (func) {
                    func({ fileName: file.name, fileUrl: fileUrl.split('?')[0] })
                }
                store.success = { message: 'Upload completed successfully' }
            }).catch(() => {
                store.error = { message: 'An error occurred while uploading the file' }
            }).finally(() => setLoadingButton(false));
    }

    function beforeUpload(file) {
        if (file && file.name) {
            setLoadingButton(true);
            return api.post(`${uploadUrl}`, {}, { headers: { filename: file.name } })
                .then(data => {
                    if (data.data && data.data.result) {
                        return setFileUrl(data.data.result);
                    }
                    throw Error('An error occurred while saving the image')
                }).catch(() => {
                    setLoadingButton(false);
                    store.error = { message: 'An error occurred while uploading the file' }
                });
        }
        throw Error('You must provide a photo')
    }

    function renderModalPreview() {
        const url = previewPhoto || null;
        let toConvert;
        let date;
        if(url){
            toConvert = url.split('/')[3].split('_')[2].split('.')[0];
            date =  moment.utc(toConvert, "YYYYMMDDHHmmss").utcOffset(-3).format("DD/MM/YYYY, HH:mm:ss");    
        }

        return (
            <Modal
                width={1000}
                visible={previewVisible}
                okButtonProps={{ style: { display: 'none' } }}
                onCancel={closeModal}
                cancelText="Close">
                <p style={{fontSize: '18px'}}>{date}</p>
                <img style={{ width: "100%", height: '65%' }} src={previewPhoto} alt="Preview" />
            </Modal>
        )
    }

    function renderModalRemove() {
        return (
            <Modal
                title="Delete"
                visible={!!removeId}
                onCancel={() => setRemoveId(null)}
                onOk={handleRemove}>
                Are you sure you want to delete?
            </Modal>
        )
    }

    function disabledButtonUpload() {
        return loadingButton ||
            disabled || ((limit == null || !newFileList)
                ? false : newFileList.length >= limit);
    }

    function clsName() {
        let cls = '';
        if (isTypeCard) {
            cls += 'upload-custom-card '
        } else if (!fileSingle) {
            cls += 'upload-list-inline '
        }
        return cls
    }

    return (
        <div>
            {!loading ? (
                <>
                    {imgPath &&
                        <img alt="Preview" style={{ maxWidth:'300px', display: 'block' }} src={imgPath} />
                    }
                    <Upload className={clsName()}
                        fileList={newFileList}
                        listType={isTypeCard ? 'picture-card' : 'picture'}
                        onPreview={handlePreview}
                        customRequest={customRequest}
                        beforeUpload={beforeUpload}
                        showUploadList={{ showRemoveIcon }}
                        onRemove={(file) => setRemoveId(file.id)}>
                        {showButtonUpload &&
                            <Button loading={loadingButton} disabled={disabledButtonUpload()}> <Icon type="upload" /> Upload </Button>}
                    </Upload>
                    {renderModalPreview()}
                    {renderModalRemove()}
                </>
            ) : (
                    <Spin tip="Loading..." size="large" />
                )
            }
        </div>
    )
}
