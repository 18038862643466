import React, { useState, useEffect } from 'react'
import api from '../../helpers/api'
import { storeContext } from '../../context';
import { Layout, Collapse } from 'antd';
import SpinInfo from '../SpinInfo';
const { Content } = Layout;
const { Panel } = Collapse;

export default function InstallationLogs() {
    const store = React.useContext(storeContext);
    const [installationLogs, setInstallationLogs] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        api.get(`/product/installation_logs/${store.currentItem.id}`)
            .then(data => {
                if(data.data.result){
                    setInstallationLogs(data.data.result.trim());
                }
            }).finally(() => setLoading(false));
    }, [store.currentItem.id])

    return (
        <Content className="padding-20"  >
            {installationLogs ? (
                <>
                    <Collapse  >
                        <Panel header="Log" key="1">
                            <pre>
                                {installationLogs}
                            </pre>
                        </Panel>
                    </Collapse>
                </>
            ) : (
                <SpinInfo spinning={loading} delay={100} />
            )}
        </Content>
    )
}
