import React, { Component } from 'react';

import axios from 'axios';
import configuration from '../../config';
import { Statistic, Card, Row, Col } from 'antd';
import packageJson from '../../../package.json';

class Status extends Component {
  state = {
    data: {},
  }

  _isMonted = false;

  getData = async () => {
    const url = `${configuration.URL}`;
    const options = {
      headers: {
        token: global.token,
      }
    };
    let data = await axios.get(url,options).then(r => r.data);
    if(this._isMonted){
      this.setState({ data });
    }
    
  }
 
  componentDidMount() {
    this._isMonted = true;
    this.getData();
  }

  componentWillUnmount() {
    this._isMonted = false
  }

  render() {
    return(
      <div style={{ background: '#ECECEC', padding: '30px' }}>
      <Row gutter={16}>
        <Col span={12}>
          <Card title="API">
            <Statistic
              title="Stage"
              value={this.state.data.stage}
              valueStyle={{ color: '#3f8600' }}
            />
            <Statistic
              title="Version"
              value={this.state.data.version}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Admin">
            <Statistic
              title="Env"
              value={'Env'}
              precision={2}
              valueStyle={{ color: '#3f8600' }}
            />
            <Statistic
              title="Version"
              value={packageJson.version}
              precision={2}
              valueStyle={{ color: '#3f8600' }}
            />
          </Card>
        </Col>
      </Row>
    </div>
    )
  }
}

export default Status;
