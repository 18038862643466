import React, { Component } from 'react';
import localforage from 'localforage';
import StoreProvider from './context';
import api from "./helpers/api";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Login from './pages/Login';
import Recover from './pages/Recover';
import Menu from './pages/Menu';
import AccountProfileSelectProfile from "./components/AccountProfile/AccountProfileSelectProfile";

class LoginApp extends Component {
  state = {
    loading: true,
    userLogged: false,
    hasAccountProfileId: false
  }

  componentDidMount() {
    localforage.getItem('token').then(async token => {
      const hasAccountProfileId = await localforage.getItem('hasAccountProfileId');
      if (token) {
        api.defaults.headers.token = token;
        global.token = token;
        this.setState({ userLogged: true, loading: false, hasAccountProfileId: hasAccountProfileId });
      } else {
        global.token = '';
        this.setState({ userLogged: false, loading: false });
      }
    });
  }

  handleComponent = () => {
    if (!this.state.userLogged) {
      return (
        <Switch>
          <Route exact path="/pw/*" component={Recover} />
          <Route exact path="/*" component={Login} />
        </Switch>
      );
    }
    if (this.state.userLogged && !this.state.hasAccountProfileId) {
      return (
        <Switch>
          <Route exact path="/pw/*" component={Recover} />
          <Route exact path="/*" component={AccountProfileSelectProfile} />
        </Switch>
      );
    }
    return (
      <Switch>
        <Route exact path="/pw/*" component={Recover} />
        <Route exact path="/*" component={Menu} />
      </Switch>
    );
  }

  render() {
    return <Router>{this.handleComponent()}</Router>
  }
}

export default function App() {
  return (
    <StoreProvider>
      <LoginApp />
    </StoreProvider>
  )
};
