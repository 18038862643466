
import React from 'react';
import { useObserver } from "mobx-react";
import { storeContext } from '../../context';
import {
  Form,
  Input,
  Row,
  Col,
  Select,
  Tooltip,
  Icon,
  DatePicker,
} from 'antd';
import RegistryForm from '../RegistryForm';
import CustomAutoComplete from '../CustomAutoComplete';
import translate from '../../i18n/translate';
import { copyToClipboard } from '../../helpers/utils';
import moment from 'moment';

const { Option } = Select;

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Deploy',
    dataIndex: 'deploy_name',
    key: 'deploy_name',
  },
  {
    title: 'Client SN',
    dataIndex: 'equipment_client_sn',
    key: 'equipment_client_sn',
  },
  {
    title: 'Endereço',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'Unidade',
    dataIndex: 'address_unit',
    key: 'address_unit',
  },
  {
    title: 'Comp',
    dataIndex: 'address_complement',
    key: 'address_complement',
  },
  {
    title: 'Tipo',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Proprietário',
    key: 'owner',
    render: (text, record) =>{
      if(record.account_id){
        return record.account_name;
      }else if(record.account_profile_id){
        return record.account_profile_name;
      }
    }
  },
  {
    title: 'Data planejada',
    dataIndex: 'planned_date',
    key: 'planned_date',
    render: planned_date => (planned_date ? moment(planned_date).format("DD/MM/YYYY") : ''),
  },
  {
    title: 'Data da execução',
    dataIndex: 'executed_date',
    key: 'executed_date',
    render: executed_date => (executed_date ? moment(executed_date).format("DD/MM/YYYY") : ''),
  },
  {
    title: 'Executado por',
    dataIndex: 'executed_account_name',
    key: 'executed_account_name',
  },
];

const labelCol = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 10,
};

let ContentForm = () => {
  const store = React.useContext(storeContext);

  const handleChange = (field, event) => {
    store.currentItem[field] = event.target.value;
  }

  const handleSelect = (field, value) => {
    store.currentItem[field] = value;
  }

  const handleDateChange = (field, event) => {
    store.currentItem[field] = event;
  }
  
  return useObserver(() => (
    <Form layout="vertical">
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="ID">
            <Input value={store.currentItem.id}  disabled={true}/>
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label="Deploy ID">
            <CustomAutoComplete
              searchValue={'id'}
              searchKey={'name'}
              path={'/deploy'}
              placeholder={translate('default.select.name')}
              onSelect={handleSelect.bind(this, 'equipment_deploy_id')}
              value={store.currentItem.equipment_deploy_id}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="Client SN">
            <Input value={store.currentItem.equipment_client_sn}  onChange={handleChange.bind(this, 'equipment_client_sn')}/>
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label="Tipo">
            <Select value={store.currentItem.type} onChange={handleSelect.bind(this, 'type')}>
              <Option value="activate">Activate</Option>
              <Option value="deactivate">Deactivate</Option>
              <Option value="replace">Replace</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="status">
            <Select value={store.currentItem.status} onChange={handleSelect.bind(this, 'status')}>
              <Option value="open">Open</Option>
              <Option value="success">Success</Option>
              <Option value="fail">Fail</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label="Tipo de problema">
            <CustomAutoComplete
              searchValue={'id'}
              searchKey={'name'}
              path={'/service_order_problem'}
              placeholder={translate('default.select.name')}
              onSelect={handleSelect.bind(this, 'service_order_problem_id')}
              value={store.currentItem.service_order_problem_id} 
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="Detalhe">
            <Input value={store.currentItem.detail}  onChange={handleChange.bind(this, 'detail')}/>
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label="Perfil da conta">
            <CustomAutoComplete
              searchValue={'id'}
              searchKey={'name'}
              path={'/account_profile'}
              placeholder={translate('default.select.name')}
              onSelect={handleSelect.bind(this, 'account_profile_id')}
              value={store.currentItem.account_profile_id} 
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="Conta">
            <CustomAutoComplete
              searchValue={'id'}
              searchKey={'name'}
              path={'/account'}
              placeholder={translate('default.select.name')}
              onSelect={handleSelect.bind(this, 'account_id')}
              value={store.currentItem.account_id} 
            />
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label="Instalação antiga">
            <Input value={store.currentItem.old_product_installation_id} disabled={true} addonAfter={
              <Tooltip placement="topLeft" title={translate('default.copy')}>
                <Icon type="copy" onClick={() => copyToClipboard(store.currentItem.old_product_installation_id)} />
              </Tooltip>
            }/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="Instalação nova">
            <Input value={store.currentItem.new_product_installation_id}  disabled={true} addonAfter={
              <Tooltip placement="topLeft" title={translate('default.copy')}>
                <Icon type="copy" onClick={() => copyToClipboard(store.currentItem.new_product_installation_id)} />
              </Tooltip>
            }/>
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label="Data planejada">
            <DatePicker value={moment(store.currentItem.planned_date)} onChange={handleDateChange.bind(this, 'planned_date')} format='DD/MM/YYYY' placeholder="Selecione a data"/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol}>
          <Form.Item label="Executado por">
            <Input value={store.currentItem.executed_account_id}  disabled={true}/>
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label="Data Execução">
            <DatePicker value={moment(store.currentItem.executed_date)} onChange={handleDateChange.bind(this, 'executed_date')} format='DD/MM/YYYY' disabled={true}/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  ));
}

const ServiceOrder = () => {
  const store = React.useContext(storeContext);

  ContentForm = Form.create({})(ContentForm);

  store.editForm = ContentForm;
  store.columns = columns;
  store.url = '/service_order';

  return (
    <div>
      <RegistryForm />
    </div>
  )
}

export default ServiceOrder;