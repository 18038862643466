import axios from 'axios';
import config from '../config';

export const apiUrl = (options= {}) => axios.create(options);

const api = axios.create({
    baseURL: config.URL
});

export default api;
