import React, { useState, useEffect, useCallback } from 'react'
import { storeContext } from '../../context';
import { Layout, Button, Table, Col, Row, Form, Input, Select } from 'antd';
import translate from '../../i18n/translate';
import api from '../../helpers/api';
import { getError } from '../../helpers/verifyStatusErrorResponse';
import AlertNotAllow from '../AlertNotAllow';
import Confirm from '../Confirm/Confirm';

const { Content } = Layout;
const labelCol = {
  xs: 24,
  sm: 24,
  md: 10,
  lg: 10,
  xl: 8,
};

export default function AsAction() {
  const store = React.useContext(storeContext);
  const [data, setData] = useState([]);
  const [state, setState] = useState({ as_analysis_id: null });
  const [permission, setPermission] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [analysis, setAnalysis] = useState([]);

  const columns = [
    {
      title: translate('default.name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: translate('asAnalysis.name'),
      dataIndex: 'analysisName',
      key: 'analysisName'
    },
    {
      title: '',
      align: 'right',
      key: 'action',
      width: 200,
      render: (text, record, index) => (
        <>
          <Button icon="edit" type="default" onClick={() => setState({ ...record, index })} htmlType="button" style={{ marginRight: 10 }} />
          <Button icon="delete" type="danger" onClick={() => Confirm({ func: () => remove(index) })} htmlType="button" />
        </>
      ),
    }
  ];

  async function remove(index) {
    try {
      setLoading(true);
      await api.delete(`/as_action/${data[index].id}`);
      const temp = [...data];
      temp.splice(index, 1);
      setData(temp);
    } catch (e) {
      setPermission(getError(e, store));
    }
    setLoading(false);
  }

  async function onAdd() {
    const model = {
      ...state,
      as_type_id: store.currentItem.asTypeId
    }
    const result = await api.post('/as_action', model);
    state.id = result.data.result.id;
    const temp = [...data, { ...state, analysisName: analysis.find(v => v.id === state.as_analysis_id).name }];
    setData(temp);
    setState({ as_analysis_id: null });
  }

  async function onUpdate() {
    const index = state.index;
    delete state.index;
    const model = {
      ...state,
      as_type_id: store.currentItem.asTypeId
    }
    await api.put(`/as_action/${state.id}`, model);
    data[index] = state;
    data[index].analysisName = analysis.find(v => v.id === state.as_analysis_id).name;
    setData(data);
    setState({ as_analysis_id: null });
  }

  async function onSubmit(event) {
    event.preventDefault();
    try {
      setLoadingButton(true);
      setLoading(true);
      if (state.id) {
        await onUpdate();
      } else {
        await onAdd();
      }
      store.success = { message: translate('asAction.save') }
    } catch (e) {
      setPermission(getError(e, store));
    }
    setLoadingButton(false);
    setLoading(false);
  }

  function isDisabled() {
    return !state || !state.name;
  }

  const initialize = useCallback(async () => {
    setLoading(true);
    try {
      const [data, analysis] = await Promise.all([
        api.get(`/as_action/as_type/${store.currentItem.asTypeId}`),
        api.get(`/as_analysis/as_type/${store.currentItem.asTypeId}`)
      ]);
      if (analysis.data && analysis.data.result) {
        setAnalysis(analysis.data.result);
      }
      if (data.data && data.data.result) {
        return setData(data.data.result);
      }
      setData([]);
    } catch (e) {
      setPermission(getError(e, store));
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [store.currentItem.asTypeId]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <Content className="padding-20">
      {permission ?
        <>
          <Form layout="vertical" onSubmit={onSubmit}>
            <Row gutter={16}>
              <Col {...labelCol}>
                <Form.Item label={translate('default.name')} required>
                  <Input value={state.name}
                    onChange={(e) => {
                      const name = e.target.value;
                      setState(prevState => ({ ...prevState, name }));
                    }} />
                </Form.Item>
              </Col>
              <Col {...labelCol}>
                <Form.Item label={translate('asAnalysis.name')} required>
                  <Select defaultValue={null}
                    value={state.as_analysis_id} style={{ width: '100%' }}
                    onChange={(as_analysis_id) => setState(prevState => ({ ...prevState, as_analysis_id }))} >
                    <Select.Option key={-1} value={null}>{translate('default.select.name')}</Select.Option>
                    {analysis.map(x => <Select.Option key={x.id} value={x.id}>{x.name}</Select.Option>)}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col>
                <Form.Item>
                  <Button loading={loadingButton} type="primary" disabled={isDisabled()} htmlType="submit" >
                    {state.id ? translate('default.save') : translate('default.add')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Col>
            <Table
              size="small"
              rowKey={record => record.key}
              columns={columns}
              dataSource={data}
              pagination={false}
              loading={loading}
            />
          </Col>
        </>
        : (
          <AlertNotAllow />
        )}
    </Content>
  )
}
