import React, { useState, useCallback, useEffect } from 'react'
import { Button, Card, Form, Row, Select, Col } from 'antd'
import api from '../../helpers/api';
import translate from '../../i18n/translate';
import localforage from 'localforage';

const { Option } = Select;
const labelCol = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 14,
    xl: 8,
};

export default function AccountProfileSelectProfile() {
    const [loading, setLoading] = useState(true);
    const [loadingButton, setLoadingButton] = useState(false);
    const [clients, setClients] = useState([]);
    const [selected, setSelected] = useState(null);

    const initialize = useCallback(async () => {
        setLoading(true);
        try {
            const { data } = await api.get(`account_profile/account`);
            if (data && data.result) {
                setClients(data.result);
            }
        } catch (e) {
            logout();
        } finally {
            setLoading(false);
        }
    }, []);

    async function onSubmit(event) {
        event.preventDefault();
        try {
            setLoadingButton(true);
            await api.post(`account/account_profile_selected`, { account_profile_id: selected });
            await localforage.setItem('hasAccountProfileId', true);
            window.location.reload(true);
        } catch (error) {
            logout();
        }
    }

    async function logout() {
        await localforage.removeItem('token');
        await localforage.removeItem('hasAccountProfileId');
        window.location.reload(true);
    };

    useEffect(() => {
        initialize();
    }, [initialize]);

    return (
        <Row >
            <Col md={{span: 6, offset: 9}} style={{ marginTop: 200 }}>
                <Card title={translate('accountProfile.selectProfile')}
                    loading={loading}
                    hoverable 
                    style={{textAlign: 'center'}}>
                    <Form layout="vertical" onSubmit={onSubmit}>
                        <Row gutter={16}>
                            <Col>
                                <Form.Item>
                                    <Select style={{ width: '100%' }} defaultValue={null}
                                        value={selected}
                                        onChange={(e) => setSelected(e)}>
                                        <Option key={-1} value={null}>{translate('default.select.name')}</Option>
                                        {clients.map(v => <Option key={v.id} value={v.id}>{v.name}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col {...labelCol}>
                                <Form.Item>
                                    <Button
                                        loading={loadingButton}
                                        type="primary"
                                        disabled={!selected}
                                        htmlType="submit">{translate('default.select.select')}
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col {...labelCol}>
                                <Form.Item>
                                    <Button onClick={() => logout()}>Logout</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Col>
        </Row>
    )
}
