import React from 'react';

export default function JSONFormatColor({ value = '' }) {

    function stringifyFormat() {
        let json = JSON.stringify(value, undefined, 4);
        json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        json = json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE]['+\-']?\d+)?)/g,
            function (match) {
                var style = 'darkorange';
                if (/^"/.test(match)) {
                    if (/:$/.test(match)) {
                        style = 'red';
                    } else {
                        style = 'green';
                    }
                } else if (/true|false/.test(match)) {
                    style = 'blue';
                } else if (/null/.test(match)) {
                    style = 'magenta';
                }
                return '<span style="color: ' + style + '">' + match + '</span>';
            });
        return <pre dangerouslySetInnerHTML={{ __html: json || '' }}></pre>;
    }

    return stringifyFormat();
}