
import React, { useEffect } from 'react';
import { useObserver } from "mobx-react";
import { storeContext } from '../../context';
import CustomAutoComplete from '../CustomAutoComplete';
import CustomMultiSelect from '../CustomMultiSelect';

import {
  Form,
  Input,
  Col,
  Row
} from 'antd';

import RegistryForm from '../RegistryForm';
import ActiveOnlyFilter from '../ActiveOnlyFilter';
import DeviceTypeTags from './DeviceTypeTags';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Serial Template',
    dataIndex: 'serial_template',
    key: 'serial_template',
  },
  {
    title: 'Board Type',
    dataIndex: 'board_type_name',
    key: 'board_type_name',
  },
  {
    title: 'Firmware Type',
    dataIndex: 'firmware_type_name',
    key: 'firmware_type_bname',
  },
  {
    title: 'Produced',
    dataIndex: 'produced',
    key: 'produced',
  },
];

const labelCol = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 10,
};

let ContentForm = () => {
  const store = React.useContext(storeContext);
  const handleChange = (field, event) => {
    store.currentItem[field] = event.target.value
  }

  console.log(JSON.stringify(store.currentItem.device_options));



  const handleComboSelect = (field, value) => {
    console.log(field, value);
    store.currentItem[field] = value
  }

  const handleMultiSelect = (field, value) => {
    console.log(field, value);
    store.currentItem[field] = value
  }

  return useObserver(() => (
    <Form layout="vertical">
      <Row gutter={16}>
        <Col {...labelCol} >
          <Form.Item label="Id">
            <Input value={store.currentItem.id} disabled={true} />
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label="Name">
            <Input value={store.currentItem.name} onChange={handleChange.bind(this, 'name')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol} >
          <Form.Item label="Description">
            <Input value={store.currentItem.description} onChange={handleChange.bind(this, 'description')} />
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label="Serial Template">
            <Input value={store.currentItem.serial_template} onChange={handleChange.bind(this, 'serial_template')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol} >
          <Form.Item label="Board Type">
            <CustomAutoComplete
              searchValue={'id'}
              searchKey={'name'}
              path={'/board_type'}
              placeholder={'Select the Board Type'}
              onSelect={handleComboSelect.bind(this, 'board_type_id')}
              value={store.currentItem.board_type_id} />
          </Form.Item>
        </Col>
        <Col {...labelCol}>
          <Form.Item label="Firmware Type">
            <CustomAutoComplete
              searchValue={'id'}
              searchKey={'name'}
              path={'/firmware_type'}
              placeholder={'Select the FW Type'}
              onSelect={handleComboSelect.bind(this, 'firmware_type_id')}
              value={store.currentItem.firmware_type_id} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col {...labelCol} >
          <Form.Item label="Device Options">
            <CustomMultiSelect
              searchValue={'id'}
              searchKey={'name'}
              path={'/device_options'}
              placeholder={'Select the Device Options'}
              onSelect={handleMultiSelect.bind(this, 'device_options')}
              value={store.currentItem.device_options} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  ));
}

const DeviceTypeList = () => {
  const store = React.useContext(storeContext);

  ContentForm = Form.create({})(ContentForm);

  store.editForm = undefined;
  store.columns = columns;
  store.url = '/device_type';
  store.filterDefault = { active: 1 };

  ContentForm.tab = 'Info';
  DeviceTypeTags.tab = "Tags";

  store.tabs = [ContentForm, DeviceTypeTags];

  useEffect(() => {
    store.customFilters = ActiveOnlyFilter;
    return () => {
      store.filterDefault = {};
      store.customFilters = null;
    }
  }, [store.customFilters, store.filterDefault]);

  return (
    <div>
      <RegistryForm />
    </div>
  )
}

export default DeviceTypeList;
