import React, { useEffect, useCallback, useState } from 'react'
import { Select } from 'antd';
import api from '../../helpers/api';
import translate, { translateFormat } from '../../i18n/translate';
import Confirm from '../Confirm/Confirm';
import localforage from 'localforage';
const { Option } = Select;

export default function CustomSelectMenu() {
    const [clients, setClients] = useState([]);
    const [selected, setSelected] = useState(null);

    const initialize = useCallback(async () => {
        try {
            const { data } = await api.get(`account_profile/switch_profile_account`);
            if (data && data.result) {
                setClients(data.result);
                setSelected(data.result.find(v => v.isLogged).id);
            }
        } catch (e) {
            console.log('Error loading profile');
        }
    }, []);

    async function onChangeProfile(account_profile_id) {
        try {
            await api.post(`account/account_profile_selected`, { account_profile_id });
            await localforage.setItem('hasAccountProfileId', true);
            window.location.reload(true);
        } catch (error) {
            console.log('Error change profile');
        }
    }

    function onSelect(value) {
        const logged = clients.find(v => v.isLogged);
        if (value && logged.id !== value) {
            Confirm({
                func: () => onChangeProfile(value),
                okCancel: () => setSelected(logged),
                content: translateFormat('accountProfile.switchProfileContent', logged.name, clients.find(v => v.id === value).name)
            });
        }
    }

    useEffect(() => {
        initialize();
    }, [initialize]);

    return !!clients.length && (
        <>
            <Select defaultValue={selected} style={{ width: 200, marginLeft: 30 }} className="custom-select"
                value={selected}
                onChange={onSelect}>
                <Option key={-1} value={null}>{translate('accountProfile.switchProfile')}</Option>
                {clients.map(v => <Option key={v.id} value={v.id}>{v.name}</Option>)}
            </Select>
        </>
    )
}
