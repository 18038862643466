import React, { Component } from 'react';
import { Form, Icon, Input, Button, Row, Col, Modal, Alert } from  'antd';
import axios from 'axios';
import configuration from '../config'
import localforage from 'localforage'

class NormalLoginForm extends Component {
  state = {
    email: '',
    password: '',
    errorMsg: '',
    loading: false,
    recoverLoading: false,
    visible: false,
    showError: false
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
      }
    });
    return this.login();
  };

  async login() {
    this.setState({ showError: false });
    await this.setState({ loading: true });
    const url = `${configuration.URL}/account/login`;
    const login = {
      email: this.state.email,
      password: this.state.password
    };

    try {
      const { data } = await axios.post(url, login)
      console.log(1, data);

      if (!data.token) throw data.message;
      const token = data.token;
      console.log(token);
      
      await localforage.setItem('token', token);
      global.token = token;
      
      if(data.hasAccountProfileId) {
        await localforage.setItem('hasAccountProfileId', true);
      }

      window.location.reload(true);
    } catch (error) {
      this.setState({ showError: true });
    } finally {
      this.setState({ loading: false });
    }
  }

  handleEmailChange = (event) => {
    this.setState({email: event.target.value});
  }

  handlePasswordChange = (event) => {
    this.setState({password: event.target.value});
  }

  handlePasswordRecoveryChange = (event) => {
    this.setState({recoverEmail: event.target.value});
  }

  onClick = () => {
    console.log('reset');
    this.setState({ visible: true });
  }

  handleOk = async () => {
    console.log('Modal OK');

    this.setState({ visible: false })
    const url = `${configuration.URL}/account/request_password_reset`;
    const data = {
      email: this.state.recoverEmail,
    };

    await axios.post(url, data).then(r => r.data);
  }

  handleCancel = () => {
    this.setState({ visible: false });
  }

  render() {
    return (
      <div>
        <Modal
          title="Password Reset Form"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          loading={this.recoverLoading}
        >
          <Input
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="email"
            placeholder="Email"
            value={this.state.recoverEmail}
            onChange={this.handlePasswordRecoveryChange}
          />
        </Modal>
        <Row>
          <Col span={9}></Col>
          <Col span={6} style={{ textAlign: 'center' }}>
            <img src="http://enablers.com.br/upload/logo_enablers2.png" style={{ width: 330, margin: 50 }} alt="Enablers" />
          </Col>
          <Col span={9}></Col>
        </Row>
        <Row>
          <Col span={9}></Col>
          <Col span={6}>
            {this.state.showError && (<Alert
              afterClose={() => this.setState({ alert: false })}
              description="Username and/or password is invalid"
              closable
              type="error"
            />)}
          </Col>
        </Row>
        <Row>
          <Col span={9}></Col>
          <Col span={6}>
            <Form onSubmit={this.handleSubmit} className="login-form">
              <Form.Item>
              <Input
                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Username"
                value={this.state.email}
                onChange={this.handleEmailChange}
              />
              </Form.Item>
              <Form.Item>
              <Input
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="password"
                placeholder="Password"
                value={this.state.password}
                onChange={this.handlePasswordChange}
              />
              </Form.Item>
              <Form.Item
                style={{
                  flexDirection: 'row',
                  flex: 1,
                }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={this.state.loading}
                  style={{ flex: 1, display: 'inline-block' }}
                  >
                  Log in
                </Button>
                  <div style={{ flex: 1, display: 'inline-block' }}></div>
                <Button
                  type="default"
                  className="login-form-button"
                  disabled={this.state.loading}
                  onClick={this.onClick}
                  style={{ marginLeft: 5, display: 'inline-block' }}>
                  Reset your password
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col span={9}></Col>
        </Row>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(NormalLoginForm);

export default WrappedNormalLoginForm;
