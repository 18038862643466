
import React from 'react';

import { Layout, Button, notification } from 'antd';
import { withRouter } from 'react-router';
import ListData from './ListData';
import EditData from './EditData';
import axios from 'axios';
import configuration from '../config'

import { useObserver } from 'mobx-react';

import { storeContext } from '../context';
import { observable } from 'mobx';
import translate from '../i18n/translate';
import Confirm from './Confirm/Confirm';
import { deepEqual } from '../helpers/checkObject';
import { isMessageError } from '../helpers/verifyStatusErrorResponse';
import { Bus } from '../helpers/utils';

const { Header, Footer, Content } = Layout;


const RegistryForm = ({ eventSave, eventDelete, history }) => {
  const store = React.useContext(storeContext);
  store.time = new Date()
  let objectObservable = {};
  if (store.currentItem) {
    objectObservable = observable({
      get isChanged() {
        const newValue = { ...store.currentItem };
        const oldVelue = { ...store.currentItemBkp }
        let flag = false;
        Object.keys(oldVelue).forEach(key => {
          if (oldVelue[key] !== newValue[key]) {
            flag = true;
            return;
          }
        })
        return flag;
      },
    })
  }

  const updateItem = async () => {
    console.log('saving');

    if (store.disableButtonSave) return;

    if (deepEqual(store.currentItem, store.currentItemBkp)) {
      return Confirm({ content: translate('information.noChangeSave'), okCancel: false });
    }

    store.saveLoading = true;
    const data = store.currentItem;
    console.log('Data --->', data);

    try {
      let insertUpdateData;
      if (store.currentItem.id) {
        const url = `${configuration.URL}${store.url}/${store.currentItem.id}`;
        console.log('id', store.currentItem.id);

        const options = {
          method: 'PUT',
          headers: { token: global.token },
          data,
          url,
        };
        insertUpdateData = await axios(options).then(r => r.data);
      } else {
        const url = `${configuration.URL}${store.url}`;

        const options = {
          method: 'POST',
          headers: { token: global.token },
          data,
          url,
        };
        insertUpdateData = await axios(options).then(r => r.data);
        console.log('Data --->', insertUpdateData)
        store.currentItem.id = insertUpdateData.result.id;
        if (store.data) {
          store.data.push(store.currentItem);
        }
      }

      store.currentItemBkp = { ...store.currentItem };

      store.saveLoading = false;
      if (insertUpdateData && insertUpdateData.message) {
        store.success = { message: insertUpdateData.message };
      }
      if (eventSave) {
        Bus.emit(eventSave, store.currentItem);
      }
    } catch (error) {
      store.saveLoading = false;
      store.error = { message: isMessageError(error) ? error.response.data.message : `unable to save, ${error.message}` };
    }
  }

  function renderAlert(type) {
    console.log(store[type].message);
    console.log(store[type]);
    notification[type]({ message: store[type].message });
    store[type] = undefined;
  }

  function showItemId() {
    const Comp = store.editForm;
    const tabs = store.tabs;
    if (!Comp && tabs && tabs.length && store.currentItem) {
      return <h3> ID: {store.currentItem.id} </h3>
    }
    return false;
  }

  return useObserver(() => (
    <div>
      {store.error && renderAlert('error')}
      {store.success && renderAlert('success')}
      <Layout>
        <Header style={{ background: '#fff', padding: 0 }}>
          {!store.hideNewButton &&
            <Button
              type="primary"
              onClick={() => { store.currentItem = { active: true } }}
              disabled={store.currentItem}>{translate('default.new')}
            </Button>
          }
        </Header>
        <Content style={{ minHeight: 600, padding: 10 }}>
          {showItemId()}
          {store.currentItem === undefined &&
            <ListData />
          }
          {(store.currentItem && Object.keys(store.currentItem).length) &&
            <EditData eventDelete={eventDelete} />
          }
          {(store.currentItem && !Object.keys(store.currentItem).length) &&
            <EditData eventDelete={eventDelete} />
          }
        </Content>
        <Footer style={{ background: '#fff', textAlign: 'end', padding: 0 }}>
          <Button
            style={{ margin: 10 }}
            loading={store.saveLoading}
            type="primary"
            onClick={() => { updateItem() }}
            disabled={store.currentItem === undefined || store.disableButtonSave}>{translate('default.save')}</Button>
          <Button
            type="default"
            onClick={() => {
              store.currentItem = undefined;
              // store.currentItemBkp = undefined;
              history.push(`${store.url}`);
            }}
            disabled={store.currentItem === undefined}
          >{objectObservable.isChanged ? translate('default.cancel') : translate('default.back')}</Button>
        </Footer>
      </Layout>
    </div>
  ));
}

export default withRouter(RegistryForm);
