import React, { useState, useEffect, useCallback } from 'react'
import JSONFormatColor from '../JSONFormatColor';
import { storeContext } from '../../context';
import { Layout, Collapse, Button, Table, Col, Input, Row, Form, Checkbox, Select, Divider, Switch } from 'antd';
import translate from '../../i18n/translate';
import api from '../../helpers/api';
import { Bus } from '../../helpers/utils';
import { getError } from '../../helpers/verifyStatusErrorResponse';

const { Content } = Layout;
const { Panel } = Collapse;
const { Option } = Select;
const labelCol = {
    xs: 24,
    sm: 24,
    md: 8,
    lg: 8,
    xl: 6,
};

const types = [
    'string',
    'date',
    'number',
];

const INIT_VALUE = { required: false, type: null };
export default function AsForm() {
    const [data, setData] = useState([]);
    const [asTypes, setAsTypes] = useState([]);
    const [asLevel, setAsLevel] = useState([]);
    const [state, setState] = useState(INIT_VALUE);
    const [typeLevel, setTypeLevel] = useState({ as_form_level: null, as_form_type: null });
    const [loading, setLoading] = useState(false);
    const store = React.useContext(storeContext);
    const columns = [
        {
            title: "Key",
            dataIndex: 'key',
            key: 'key'
        },
        {
            title: 'Label',
            dataIndex: 'label',
            key: 'label'
        },
        {
            title: "Type",
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: "Required",
            dataIndex: 'required',
            key: 'required',
            render: (required) => <Checkbox checked={required} disabled />
        },
        {
            title: '',
            align: 'right',
            key: 'action',
            width: 200,
            render: (text, record, index) => (
                <>
                    <Button icon="delete" type="danger" onClick={() => remove(index)} htmlType="button" />
                </>
            ),
        }
    ];

    async function saveForm(as_form) {
        await api.put(`/as_type/as_form/${store.currentItem.asTypeId}`, as_form);
    }

    async function saveFormTypeLevel() {
        await api.put(`/as_type/as_form/type_level/${store.currentItem.asTypeId}`, {
            as_form_type: typeLevel.as_form_type,
            as_form_level: typeLevel.as_form_level,
            as_form_create_device: typeLevel.as_form_create_device,
        });
        Bus.emit("addOrUpdateMenuAsType");
    }

    function remove(index) {
        const temp = [...data];
        temp.splice(index, 1);
        setData(temp);
        saveForm(temp);
    }

    function onAdd(event) {
        event.preventDefault();
        if (data.some(v => v.key.toUpperCase() === state.key.toUpperCase())) {
            store.error = { message: translate('asType.keyAlreadyAdded') }
            return;
        }
        const temp = [...data, state];
        setData(temp);
        setState(INIT_VALUE);
        saveForm(temp);
    }

    function isDisabled() {
        return !state || !state.label || !state.key || !state.type;
    }

    const initialize = useCallback(async () => {
        setLoading(true);
        try {
            const [data, types, level] = await Promise.all([
                api.get(`/as_type/as_form/${store.currentItem.asTypeId}`),
                api.get(`/as_type/application/${store.currentItem.id}`),
                api.get(`/as_level/application/${store.currentItem.id}`),
            ]);

            if (types.data && types.data.result) {
                setAsTypes(types.data.result.filter(v => v.id !== store.currentItem.asTypeId));
            }
            if (level.data && level.data.result) {
                setAsLevel(level.data.result);
            }
            if (data.data && data.data.result) {
                setTypeLevel(data.data.result);
                return setData(data.data.result.as_form || []);
            }
        } catch (e) {
            getError(e, store);
        } finally {
            setLoading(false);
        }
        // eslint-disable-next-line
    }, [store.currentItem.id]);

    useEffect(() => {
        initialize();
    }, [initialize]);

    return (
        <Content className="padding-20">
            <Form layout="vertical" onSubmit={onAdd}>
                <Row gutter={16}>
                    <Col {...labelCol}>
                        <Form.Item label={translate('asType.name')} required>
                            <Select defaultValue={null}
                                value={typeLevel.as_form_type} style={{ width: '100%' }}
                                onChange={(as_form_type) => setTypeLevel(prevState => ({ ...prevState, as_form_type }))} >
                                <Select.Option key={-1} value={null}>{translate('default.select.name')}</Select.Option>
                                {asTypes.map(x => <Select.Option key={x.id} value={x.id}>{x.name}</Select.Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col {...labelCol}>
                        <Form.Item label={translate('asLevel.name')} required>
                            <Select defaultValue={null}
                                value={typeLevel.as_form_level} style={{ width: '100%' }}
                                onChange={(as_form_level) => setTypeLevel(prevState => ({ ...prevState, as_form_level }))} >
                                <Select.Option key={-1} value={null}>{translate('default.select.name')}</Select.Option>
                                {asLevel.map(x => <Select.Option key={x.id} value={x.id}>{x.name}</Select.Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col {...labelCol} >
                        <Form.Item label={translate("asType.createDevice")}>
                            <Switch onChange={(as_form_create_device) => setTypeLevel(prevState => ({ ...prevState, as_form_create_device }))} checked={typeLevel.as_form_create_device} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col>
                        <Form.Item>
                            <Button type="primary"
                                onClick={() => saveFormTypeLevel(data)}
                                disabled={!typeLevel.as_form_level || !typeLevel.as_form_type}
                                htmlType="button">{translate('default.save')}</Button>
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <Row gutter={16}>
                    <Col {...labelCol}>
                        <Form.Item label="Key" required>
                            <Input value={state.key}
                                onChange={(e) => {
                                    const key = e.target.value;
                                    setState(prevState => ({ ...prevState, key }));
                                }} />
                        </Form.Item>
                    </Col>
                    <Col {...labelCol}>
                        <Form.Item label="Label" required>
                            <Input value={state.label}
                                onChange={(e) => {
                                    const label = e.target.value;
                                    setState(prevState => ({ ...prevState, label }));
                                }} />
                        </Form.Item>
                    </Col>
                    <Col {...labelCol}>
                        <Form.Item label="Type" required>
                            <Select defaultValue={null}
                                value={state.type} style={{ width: '100%' }}
                                onChange={(type) => setState(prevState => ({ ...prevState, type }))} >
                                <Option key={-1} value={null}>{translate('default.select.type')}</Option>
                                {types.map(x => <Option key={x}>{x}</Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col {...labelCol}>
                        <Form.Item label="Required">
                            <Checkbox
                                checked={state.required}
                                onChange={(e) => {
                                    const required = e.target.checked;
                                    setState(prevState => ({ ...prevState, required }));
                                }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col>
                        <Form.Item>
                            <Button type="primary" disabled={isDisabled()} htmlType="submit" >{translate('default.add')}</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Col>
                <Table
                    size="small"
                    rowKey={record => record.key}
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    loading={loading}
                />
            </Col>
            <Col style={{ marginTop: 20 }}>
                <Collapse>
                    <Panel header="JSON" key="1">
                        <JSONFormatColor value={data} />
                    </Panel>
                </Collapse>
            </Col>
        </Content>
    )
}
