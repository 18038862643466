import React from 'react'
import { storeContext } from '../../context';
import { Layout } from 'antd';
import CustomTabs from '../CustomTabs';
const { Content } = Layout;

export default function ProductionTab() {
  const store = React.useContext(storeContext);

  function component() {
    const tabs = store.productionTabs;
    console.log(tabs);
    if (tabs && tabs.length) {
      return <CustomTabs>
        {tabs.map((Tab, index) => <Tab hideReload={Tab.hideReload} key={index} tab={Tab.tab} disabled={Tab.disabled} {...Tab.params} />)}
      </CustomTabs>
    }
  }

  return (
    <Content className="padding-20"  >
      {component()}
    </Content>
  )
}